body.ltr.offcanvs-position-right {
    .offcanvas-menu {

        width: 100%;
        left: 100%;
        transition: none;
        background-color: map-get($colors, mobile-menu-bg);
        $position: #{24px - 4px}; // PM - Adjustment for extra spacing of rotated lines.

        .top-bar {
            @include flex-box(normal, null, space-between, center);
            position: relative;
            padding: 12px 20px;

        }
        .logo {
            width: 48px;
            height: 48px;

        }
        .close-offcanvas {
            $position: #{24px - 4px}; // PM - Adjustment for extra spacing of rotated lines.
            //top: $position; // PM - Adjustment for extra spacing of rotated lines.
            position: static;
            margin-bottom: 3px;
            border-radius: 0;
            background-color: transparent;
            text-align: right;
            .line {
                position: absolute;
                width: 24px;
                height: 2px;
                border-radius: 1px;
                background-color: map-get($colors, mobile-menu-btn-close );

                &.top-to-bottom {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                &.bottom-to-top {
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }

    }
    .body-wrapper {
        position: static;
    }

    //dev
    /*
    .offcanvas-menu {
        left: 0;
        .offcanvas-inner{
            .fixed-cntr{
                display: flex!important;
                flex-wrap: wrap;
            }
        }
    }
    */

    &.offcanvas-active {
        .offcanvas-menu {
            left: 0;

        }

        .offcanvas-overlay {
            position: fixed;
            left: 0;
        }
    }
}
//destroy original styling
.site .offcanvas-menu .offcanvas-inner .sp-module ul > li.menu-parent > a > .menu-toggler:after, .site .offcanvas-menu .offcanvas-inner .sp-module ul > li.menu-parent > .menu-separator > .menu-toggler:after{
    content: '';
}
.offcanvas-active{
    .offcanvas-menu{
        .offcanvas-inner{
            .fixed-cntr{
                @include flex-box('normal', null, null, null, wrap);
            }
        }
    }
}

.offcanvas-menu{

    .cls-1{
        opacity: 1;
    }


    .offcanvas-inner{
        padding: 30px 24px 30px 30px;
        .fixed-cntr{
            padding: 36px 18px 24px 18px;
            justify-content: center;
            bottom: 0;
            display: none;
            left: 0;
            z-index: 2;
            position: fixed;
            width: 100%;
            background: map-get($colors, mobile-menu-bg);
            .btn{
                @include flex-box('normal', null, space-between, null, wrap);

                width: 100%;
                max-width: 280px;
                padding: 0 28px;

            }
            .language-switcher{
                width: 100%;
                .moduletable{
                    width: auto;

                }
                svg{
                    path{
                        fill: map-get($colors, icon-light);

                    }
                }
                .chzn-single {
                    width: auto;
                }
            }

        }
        .sp-module .sp-module-content {
            //space below for static contact button
            padding-bottom: 160px;


            .menu{
                li{
                    margin-top: 16px;
                    margin-bottom: 24px;
                    /*
                    &.menu-parent-open{
                        .menu-toggler{
                            svg{
                                transform: rotate(180deg);

                            }
                        }
                    }
                    */
                    .menu-toggler{
                        display: none;
                        /*
                        width: 15px;
                        height: 9px;
                        padding: 0;
                        -webkit-transform: none;
                        transform: none;
                        svg{
                            transition: all 0.5s;
                            -webkit-transform: none;
                            transform: none;
                            position: absolute;
                            top: -50%;
                            height: 100%;
                            width: 100%;
                            path{
                                fill: map-get($colors, icon-light);
                            }
                        }*/
                    }
                    .menu-link{
                        font-size: 30px;
                        line-height: 40px;
                        color: map-get($colors, menu-text-on-dark );
                        >img{
                            display: none;
                        }
                        .menu-img{
                            display: none;
                        }
                    }
                    .menu-child {
                        .menu-link {
                            font-size: 20px;
                            color: map-get($colors, block-text-on-dark );
                            line-height: 32px;
                        }
                    }
                }
                .menu-child{
                    display: block;
                    margin: 0;
                    padding-bottom: 8px;
                    border-bottom: 1px solid map-get($colors, off-canvas-border);
                    li{
                        margin: 2px 0;
                    }

                }
            }
        }
    }
}