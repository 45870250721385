$adjustment: 0px;
$buttonHeight: 39px;
$buttonWidth: 65px;

/*
#sp-page-builder{
    .sp-pagebuilder-column,
    .sp-pagebuilder-addon{
        position: static;
    }

}
*/
.sppb-col-md-4, .sppb-col-md-12{
    min-height: 0!important;
}
.sppb-col-md-4{
    /*position: static;*/
    .sppb-column, .sppb-column-addons{
        /*position: static;*/
        z-index: 2;
    }
}

.sppb-videobutton{
    position: absolute;
    bottom:  (0 - $buttonHeight / 2) + $adjustment;

    left: 0;
    left: 50%;
    z-index: 21;
    .button-cntr{
        position: relative;
        left: -50%;
        display: block;
        z-index: 21;

        .btn-link{

            &:hover{
                svg{
                    path[fill="#{map-get($colors, btn-orange-link-on-light-bg)}"] {
                        fill: map-get($colors, btn-orange-link-on-light-bg-hover);
                    }
                    .st0{
                        fill: map-get($colors, btn-orange-link-on-light-bg-hover);
                    }
                }
            }
        }

        .btn {
            width: 100%;
            height: auto;
            @include flex-box('normal', column, center, center, wrap);

            justify-items: center;


            .txt{
                display: block;
                flex-shrink: 0;
                margin-bottom: 8px;
                text-align: center;
                min-height: 28px;
                color: rgba(255,255,255, 0.76);
            }
            .btn-image{

                display: block;
                flex-shrink: 0;
                svg{
                    width: 100%;
                    height: 100%;
                }

            }
        }
    }
}


$breakpoint: "mobile-s-up";
$adjustment: 100px;
$buttonHeight: 63px;
$buttonWidth: 105px;
@include on-breakpoint($breakpoint) {
    .sppb-videobutton{
        bottom:  (0 - $buttonHeight / 2) + $adjustment;
        .btn-image{
            width: $buttonWidth;
            height: $buttonHeight;
        }
        .btn{
            .txt{
                font-size: 17px;
                line-height: 28px;
            }
        }
    }
}
$breakpoint: "tablet-s-up";
$adjustment: 68px;
$buttonHeight: 84px;
$buttonWidth: 140px;
@include on-breakpoint($breakpoint) {
    .sppb-videobutton{
        bottom:  (0 - $buttonHeight / 2) + $adjustment;
        .btn-image{
            width: $buttonWidth;
            height: $buttonHeight;
        }
        .btn{
            .txt{
                font-size: 17px;
                line-height: 28px;
            }
        }
    }

}
$breakpoint: "desktop-s-up";
$adjustment: 0px;
$buttonHeight: get-vw(84px, 1440px);
$buttonWidth: get-vw(140px, 1440px);
@include on-breakpoint($breakpoint) {
    .sppb-videobutton{
        bottom:  (0 - $buttonHeight / 2);
        .btn-image{
            width: $buttonWidth;
            height: $buttonHeight;
        }
        .btn{
            .txt{
                font-size: 20px;
                line-height: 32px;
            }
        }
    }
}
