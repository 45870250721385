@function column-width-perc($amount) {
    @return $amount/12*100%;
}
@function amount-width-perc($divider) {
    @return 1/$divider*100%;
}

@function get-vw($target, $default, $exception: null) {
    $vw-context: ($default*.01) / 0.8;

    //$returnSize: $target + $vw-context; // ($vw-context * 1vw);
    @if $exception != null {
        $vw-context: ($default*.01);
    }
    $returnSize: (($target)/$vw-context) * 1vw;

    @return $returnSize;
}
