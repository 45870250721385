$site-widths: (desktop-l-up: 1920px,
desktop-m-up: 1600px,
desktop-s-up: 1160px,
tablet-l-up: 100%, //100%/12% *10%,
tablet-m-up: 100%,
tablet-s-up: 100%,
mobile-m-up: 100%,
mobile-s-up: 100%);

$site-breakpoints: (desktop-l-up: 2560px,
//desktop-m-up: 1920px, // PM - From data/Design
desktop-m-up: 1440px, // TK - From data/Design
desktop-s-up: 1024px, //PM - From data/Design
tablet-l-up: 1024px, // PM - From data
tablet-m-up: 768px, // PM - From data
tablet-s-up: 768px, // PM - From research // TK - from designs
mobile-m-up: 375px, // PM - From data
mobile-s-up: 0px // PM -  Mainly for 320px and up
);

$column-widths: (desktop-l-up: map-get($site-widths, desktop-l-up)/12,
desktop-m-up: map-get($site-widths, desktop-m-up)/12,
desktop-s-up: map-get($site-widths, desktop-s-up)/12,
tablet-l-up: map-get($site-widths, tablet-l-up)/12,
tablet-m-up: map-get($site-widths, tablet-m-up)/12,
tablet-s-up: map-get($site-widths, tablet-s-up)/12,
mobile-m-up: map-get($site-widths, mobile-m-up)/12,
mobile-s-up: map-get($site-widths, mobile-s-up)/12,
);

$column-width-perc: 100%/12;

$site-border-sizes: (desktop-l-up-TB: 80px,
desktop-l-up-LR: 70px,
desktop-m-up-TB: 80px,
desktop-m-up-LR: 70px,
desktop-s-up-TB: 80px,
desktop-s-up-LR: 70px,
tablet-l-up-TB: 80px,
tablet-l-up-LR: 70px,
tablet-m-up-TB: 80px,
tablet-m-up-LR: 70px,
tablet-s-up-TB: 60px,
tablet-s-up-LR: 46px,
mobile-m-up-TB: 56px,
mobile-m-up-LR: 30px,
mobile-s-up-TB: 56px,
mobile-s-up-LR: 30px);

$site-widths-design: (desktop-s-up: 1440px,
tablet-s-up: 768px,
mobile-s-up: 375px);

$column-gutter: (
desktop-s-up: (get-vw(40px, map-get($site-widths-design, desktop-s-up)))/2,
tablet-s-up: 16px/2,
mobile-s-up: 6px/2
);

$site-gutters: (
desktop-s-up: get-vw(272px - (15px/2), map-get($site-widths-design, desktop-s-up), true), // - map-get($column-gutter, desktop-s-up),
tablet-s-up: 60px, //- map-get($column-gutter, tablet-s-up),
mobile-s-up: 30px //- map-get($column-gutter, mobile-s-up),
);

$site-vert-grid: (
    desktop-s-up: 8px,
    tablet-s-up: 8px,
    mobile-s-up: 8px
);

$row-tb-padding: (
    desktop-s-up: get-vw(40px, map-get($site-widths-design, desktop-s-up)),
    tablet-s-up: 30px,
    mobile-s-up: 30px,
);

$btn-padding-LR: 18px;
$btn-height-mobile: 36px;
$btn-height-tablet: 44px;

$landing-btn-padding: (tablet-m-up: 74px,
tablet-s-up: 66px,
mobile-m-up: 66px,
mobile-s-up: 160px);

$column-padding: 15px;

$space-between-el: 20px;
$space-between-el-desktop-m-up: $space-between-el*1.5;
$space-between-el-tablet-m-up: $space-between-el;
$space-between-el-tablet-s-up: $space-between-el/2;
$space-between-el-mobile: 1.5vh;

$video-ratio: 720/1280;

$block-padding: (desktop-l-up: $column-padding * 4,
desktop-m-up: $column-padding * 3,
desktop-s-up: $column-padding * 2,
desktop-small-LR: $column-padding * 2 // PM - Legacy value
);

$block-content-height-ratio: (desktop-small: 430/1170);

$block-content-height: (desktop-small: (map-get($site-widths, desktop-s-up)) * (map-get($block-content-height-ratio, desktop-small)));

$notification-header-height: (
    desktop-s-up: get-vw(56px, map-get($site-widths-design, desktop-s-up)),
    tablet-s-up: 56px,
    mobile-s-up: 92px
);