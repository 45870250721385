.addon-tile-image-with-content {



    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        @include flex-box(normal, null, space-between, flex-start);
        flex-wrap: wrap;
        .tile {
            width: 100%;
            margin-bottom: map-get($site-vert-grid, $breakpoint)*4;
            background-color: map-get($base-colors, quaternary);
            box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1);
            overflow: hidden;
            border-radius:
            6px
            6px
            0
            0;
            .img-cntr {
                overflow: hidden;
                border-radius:
                0
                0
                map-get($site-vert-grid, $breakpoint)*4
                0;
              //  border-radius: 15px;
            }

            .img-cntr {
                .img {
                    width: 100%;
                }

            }
            .content {
                padding: map-get($site-vert-grid, $breakpoint)*2;
                .header {
                    height: auto;
                    margin-bottom: map-get($site-vert-grid, $breakpoint)*2;
                }
                .bottom-bar {
                    @include flex-box(normal, row, space-between, flex-end);
                    flex-wrap: wrap;
                    .logo {
                        height: map-get($site-vert-grid, $breakpoint)*6;
                    }
                    .sppb-button {
                        @include ie-only() {
                            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                @include flex-box(normal, column, flex-start);
                                height: get-vw(map-get($site-vert-grid, $breakpoint)*2.5, map-get($site-widths-design, $breakpoint));
                                a {
                                    height: 100%;
                                }
                             }
                        }
                    }
                }
            }
        }
    }


    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .tile {
            //width: get-vw(map-get($column-widths, $breakpoint)*5, map-get($site-widths-design, $breakpoint));
            width: calc(50% - #{get-vw(24px, map-get($site-widths-design, $breakpoint))});
            margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*3;
            box-shadow: 0 get-vw(16px, map-get($site-widths-design, $breakpoint)) get-vw(32px, map-get($site-widths-design, $breakpoint)) 0 rgba(0, 0, 0, 0.1);

            .content {
                padding: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*3;
                .header {
                    height: get-vw(map-get($site-vert-grid, $breakpoint)*12, map-get($site-widths-design, $breakpoint));

                }
                .bottom-bar {
                    @include flex-box(normal, column-reverse, flex-start, flex-start);
                    .logo {
                        height: get-vw(map-get($site-vert-grid, $breakpoint)*6, map-get($site-widths-design, $breakpoint));
                        margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint)*2, map-get($site-widths-design, $breakpoint));
                    }
                }
            }
            &:nth-child(2n+1) {
                margin-top: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*5;
               // margin-left: map-get($column-gutter, $breakpoint);
            }
        }
    }


    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {

        .tile {
            //width: get-vw(map-get($column-widths, $breakpoint)*5, map-get($site-widths-design, $breakpoint));
            width: calc(50% - #{map-get($column-gutter, $breakpoint)});
            margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*3;
            box-shadow: 0 get-vw(24px, map-get($site-widths-design, $breakpoint)) get-vw(48px, map-get($site-widths-design, $breakpoint)) 0 rgba(0, 0, 0, 0.1);
            border-radius:
                get-vw(8px,  map-get($site-widths-design, $breakpoint))
                get-vw(8px,  map-get($site-widths-design, $breakpoint))
                0
                0;
            .img-cntr {
                border-radius:
                0
                0
                get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*5
                0;
              //  border-radius: 15px;
            }
            .content {
                padding: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*4;
                .header {
                    height: get-vw(map-get($site-vert-grid, $breakpoint)*18, map-get($site-widths-design, $breakpoint));
                }
                .bottom-bar {
                    @include flex-box(normal, row, space-between, flex-end);
                    height: get-vw(map-get($site-vert-grid, $breakpoint)*8, map-get($site-widths-design, $breakpoint));

                    .logo {
                        height: 100%;
                        margin-bottom: 0;
                    }
                    .sppb-button {
                        @include ie-only() {
                            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                                @include flex-box(normal, column, flex-start);
                                height: get-vw(map-get($site-vert-grid, $breakpoint)*2.5, map-get($site-widths-design, $breakpoint));
                                a {
                                    height: 100%;
                                }
                             }
                        }
                    }
                }
            }
           &:nth-child(2n+1) {
                margin-top: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint))*5;
               // margin-left: map-get($column-gutter, $breakpoint);
            }
        }
    }
}