.sppb-section.random-facts {
    $gradient-color-top: map-get($base-colors, primary);
    $gradient-color-bottom: map-get($base-colors, quaternary);
    background: linear-gradient(to bottom,  $gradient-color-top 100px, $gradient-color-bottom 100px) !important;


            $breakpoint: "mobile-s-up";
            @include on-breakpoint($breakpoint) {
                $gradient-top-height: 45%;
                background: linear-gradient(to bottom,  $gradient-color-top $gradient-top-height, $gradient-color-bottom $gradient-top-height) !important;
                .sppb-row > *:not(:first-child):not(:last-child){
                    &:nth-child(2),
                    &:nth-child(3){
                        margin-bottom: map-get($site-vert-grid, $breakpoint)*8;
                    }
                    .sppb-addon.image {
                        margin: 0;

                    }s
                    .sp-pagebuilder-column,
                    .sppb-column{
                        height: auto;
                        background-color: map-get($base-colors, quaternary);
                        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08) !important;
                        border-radius: 8px;
                        padding:
                        map-get($site-vert-grid, $breakpoint)*5
                        map-get($site-vert-grid, $breakpoint)*4
                        map-get($site-vert-grid, $breakpoint)*6;
                        border-radius: get-vw(2px, map-get($site-widths-design, $breakpoint)) !important;
                    }
                }

            }

            $breakpoint: "tablet-s-up";
            @include between-breakpoints($breakpoint, "desktop-s-up") {
                $gradient-top-height: 45%;
                background: linear-gradient(to bottom,  $gradient-color-top $gradient-top-height, $gradient-color-bottom $gradient-top-height) !important;
                .sppb-row > *:not(:first-child):not(:last-child){

                    .sp-pagebuilder-column,
                    .sppb-column{
                        /*width: map-get($column-widths, $breakpoint)*6;*/
                        margin: 0 auto;

                    }
                }
                // PM - Pagebuilder fixes
                &.sp-pagebuilder-section {
                    .sppb-row > *:not(:first-child):not(:last-child){
                        &:nth-child(2),
                        &:nth-child(3){
                            margin-bottom: 0;
                        }
                    }
                }
            }
            $breakpoint: "desktop-s-up";
            @include on-breakpoint($breakpoint) {
                $gradient-top-height: get-vw(map-get($site-vert-grid, $breakpoint)*38, map-get($site-widths-design, $breakpoint));
                background: linear-gradient(to bottom,  $gradient-color-top $gradient-top-height, $gradient-color-bottom $gradient-top-height) !important;
                .sppb-row > *:not(:first-child):not(:last-child){
                    .sp-pagebuilder-column,
                    .sppb-column{
                        padding:
                        get-vw(map-get($site-vert-grid, $breakpoint)*5, map-get($site-widths-design, $breakpoint))
                        get-vw(map-get($site-vert-grid, $breakpoint)*4, map-get($site-widths-design, $breakpoint))
                        get-vw(map-get($site-vert-grid, $breakpoint)*6, map-get($site-widths-design, $breakpoint));
                        border-radius: get-vw(2px, map-get($site-widths-design, $breakpoint));
                    }
                    &:nth-child(2) {

                    }
                    &:nth-child(3) {
                        margin-top: get-vw(map-get($site-vert-grid, $breakpoint)*5, map-get($site-widths-design, $breakpoint));
                    }
                    &:nth-child(4) {
                        margin-top: get-vw(map-get($site-vert-grid, $breakpoint)*5*2, map-get($site-widths-design, $breakpoint));
                    }
                }
            }
}
