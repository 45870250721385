#sp-bottom {
    border-top: 1px solid map-get($colors, bottom-divider);
    padding: 0; // PM - Template reset
    line-height: 1; // PM  - Template reset
    * {
        text-align: left;
        color: map-get($colors, bottom-txt-light);
    }

    .sp-module {
        margin-bottom: 0;
    }


    #sp-bottom2-funk-e,
    #sp-bottom2-change,
    #sp-bottom2,
    #sp-bottom3-funk-e,
    #sp-bottom3-change,
    #sp-bottom3,
    #sp-bottom1-funk-e,
    #sp-bottom1-change,
    #sp-bottom1 {

        @include flex-box(normal, row, null, center);

        .menu {
            @include flex-box(normal, row, null, center);
            li {
                display: inline-block;
                margin-bottom: 0;
                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    $breakpoint: "mobile-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        * {
            font-size: 12px;
        }
        .row {
            margin-top: map-get($site-vert-grid, $breakpoint)*3;
            @include flex-box('normal', null, null, null, wrap);
            margin-bottom: 20px;
        }
        #sp-bottom2-funk-e,
        #sp-bottom2-change,
        #sp-bottom2,
        #sp-bottom4-funk-e,
        #sp-bottom4-change,
        #sp-bottom4,
        #sp-bottom1-funk-e,
        #sp-bottom1-change,
        #sp-bottom1 {
            width: 50%;
            min-width: 280px;
        }
        #sp-bottom3-funk-e,
        #sp-bottom3-change,
        #sp-bottom3 {
            @include flex-box(normal, row, null, center);
            margin-bottom: map-get($site-vert-grid, $breakpoint);
        }
        #sp-bottom2-funk-e,
        #sp-bottom2-change,
        #sp-bottom2,
        #sp-bottom3-funk-e,
        #sp-bottom3-change,
        #sp-bottom3 {
            margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint)*2, map-get($site-widths-design, $breakpoint));
        }

        #sp-bottom2-funk-e,
        #sp-bottom2-change,
        #sp-bottom2 {
            .menu {
                justify-content: flex-start;
                /*width: calc(100%*(4/12));*/
                [class^="item"] {
                    &:not(:last-child) {
                    padding-right: 16px; // PM - Prevent texts are placed against eachother on scaling to really small mobile size
                    }
                }
            }
        }
        #sp-bottom4-funk-e,
        #sp-bottom4-change,
        #sp-bottom4 {
            order: -1;
        }
        #sp-bottom4-funk-e,
        #sp-bottom4-change,
        #sp-bottom4,
        #sp-bottom1-funk-e,
        #sp-bottom1-change,
        #sp-bottom1 {
           flex-shrink: 1;
        }

    }

    @include on-breakpoint($breakpoint) {
        .sp-column{
            width: 100%;
        }

        #sp-bottom2-funk-e,
        #sp-bottom2-change,
        #sp-bottom2 {

            .menu {
                width: 100%;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    $breakpoint: "tablet-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        * {
            font-size: 13px;
        }
        #sp-bottom1-funk-e,
        #sp-bottom1-change,
        #sp-bottom1,
        #sp-bottom4-funk-e,
        #sp-bottom4-change,
        #sp-bottom4 {
            width: 50%;
            margin-bottom: map-get($site-vert-grid, $breakpoint)*4;
        }
    }


    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        * {
            font-size: get-vw(13px, map-get($site-widths-design, $breakpoint));
        }
        .row {
            height: get-vw(42px, map-get($site-widths-design, $breakpoint));
            margin-top: get-vw(30px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(120px, map-get($site-widths-design, $breakpoint));
        }
        #sp-bottom2-funk-e,
        #sp-bottom2-change,
        #sp-bottom2 {
            .menu {
                li {

                    margin-right: get-vw(32px, map-get($site-widths-design, $breakpoint));
                    line-height: get-vw(16px, map-get($site-widths-design, $breakpoint));
                }
                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }
        #sp-bottom3-funk-e,
        #sp-bottom3-change,
        #sp-bottom3{
            margin-top: 0;
            .sp-column{
                .sp-copyright{
                    display: block;

                }
            }
        }
        #sp-bottom4-funk-e,
        #sp-bottom4-change,
        #sp-bottom4 {
            order: none;
        }

        // PM - Small alignment tweaks

        #sp-bottom2-funk-e,
        #sp-bottom2-change,
        #sp-bottom2,
        #sp-bottom3-funk-e,
        #sp-bottom3-change,
        #sp-bottom3 {
            .sp-column {
                margin-top: 1px;
            }
        }
    }
}