.sppb-addon-animation-shape-left{
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        position: relative;

        .animation-cntr{
            position: relative;
            left: -(map-get($site-gutters, $breakpoint));
            width: calc(100% + #{map-get($site-gutters, $breakpoint)*2});
            height: get-vw(328px, map-get($site-widths-design, $breakpoint));
            border-top-left-radius: get-vw(map-get($site-vert-grid, $breakpoint)*11, map-get($site-widths-design, $breakpoint));
            background: map-get($colors, bg-grey-light);
            .lottie-animation {
                width: 100%;
                height: 100%;
            }
        }
    }

    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .animation-cntr{
            width: calc(100% + #{map-get($site-gutters, $breakpoint)});
            height: get-vw(400px, map-get($site-widths-design, $breakpoint));
            left: -(map-get($site-gutters, $breakpoint));
            border-top-left-radius: 0;
            border-bottom-right-radius: get-vw(80px, map-get($site-widths-design, $breakpoint));
        }
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        .animation-cntr{
            width: calc(100% + #{map-get($site-gutters, $breakpoint)}); // map-get($site-gutters, $breakpoint) + get-vw(map-get($column-widths, $breakpoint)*5, map-get($site-widths-design, $breakpoint)) - (map-get($column-gutter, $breakpoint));
            height: get-vw(720px, map-get($site-widths-design, $breakpoint));
            left: -(map-get($site-gutters, $breakpoint));
            border-top-right-radius: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint));
            border-bottom-right-radius: get-vw(map-get($site-vert-grid, $breakpoint)*20, map-get($site-widths-design, $breakpoint));
        }
    }
}