.sppb-section {
    .sppb-row {
        >* {
            order: 999;
        }
    }

    $breakpoint: "mobile-s-up";

    @include between-breakpoints($breakpoint, "tablet-s-up") {
        &.resp-hor-order- {
            &case-preview{
                .sppb-row {
                    > :nth-child(3) {
                        order: 0;

                    }
                }
            }
            &vacancies{
                .sppb-row {
                    > :nth-child(2) {
                        order: 1000;
                        margin-top: 56px;
                    }
                    > :nth-child(6) {
                        order: 1001;
                    }
                }
            }
            &product-block{
                .sppb-row {
                    > :nth-child(1) {
                        order: 0;
                    }
                    > :nth-child(3) {
                        order: 1;
                    }
                    .sppb-row {
                        .sppb-col-md-6:nth-child(2){
                            display: none;
                        }
                    }

                }
            }
            &sales-trigger {
                .sppb-row {
                    >* {
                        // margin-top: -50px;
                        position: relative;
                        // top: -100px;
                    }

                    > :nth-child(1),
                    > :nth-child(4) {
                        display: none;
                    }

                    > :nth-child(3) {

                        .sppb-addon-spacer.sales-trigger {
                            height: map-get($site-vert-grid, $breakpoint);
                        }
                    }

                    > :nth-child(3) {
                        $top-position-outside: map-get($site-vert-grid, $breakpoint)*3;
                        order: 0;
                        top: -$top-position-outside;
                        margin-bottom: -$top-position-outside;
                    }
                }
            }
            &animation-shape-left {
                .sppb-row {
                    > :nth-child(1) {
                        order: 0;
                    }
                    > :nth-child(4) {
                        order: 1;
                        .sppb-column-addons {
                            @include flex-box(normal, column);
                            > :nth-child(1) {
                                order: 3;
                            }
                            > :nth-child(2) {
                                order: 0;
                            }
                            > :nth-child(3) {
                                order: 1;
                            }
                        }
                    }
                }
            }
            &contact-office- {
                &img-left {
                    .sppb-row {
                        > :nth-child(1) {
                            order: 1;
                        }

                        > :nth-child(4) {
                            order: 3;
                            .sppb-column-addons {
                                @include flex-box(normal, column);

                                .addon-cntr:nth-child(1) {
                                    order: 999;
                                }

                            }
                        }
                    }
                }
                &img-right {
                    .sppb-row {
                        > :nth-child(2) {
                            .sppb-column-addons {
                                @include flex-box(normal, column);

                                .addon-cntr:nth-child(1) {
                                    order: 999;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    $breakpoint: "tablet-s-up";

    @include between-breakpoints($breakpoint, "desktop-s-up") {
        &.resp-hor-order- {
            /*&sales-trigger {
                .sppb-row {

                    >* {
                        // margin-top: -50px;
                        position: relative;
                        // top: -100px;
                    }

                    > :nth-child(1),
                    > :nth-child(4) {
                        display: none;
                    }
                    > :nth-child(3) {
                        .sppb-addon-spacer.sales-trigger {
                            height: map-get($site-vert-grid, $breakpoint);
                        }
                    }

                    > :nth-child(3) {
                        $top-position-outside: map-get($site-vert-grid, $breakpoint)*3;
                        order: 0;
                        top: -$top-position-outside;
                        margin-bottom: -$top-position-outside;
                    }
                }
            }*/
            /*
            &random-facts {
                .sppb-row {
                    > :nth-child(2),
                    > :nth-child(3),
                    > :nth-child(4) {
                        width: 100%;

                    }
                }
            }
            */
        }
    }
}