.mfp-container {
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        .mfp-content {
            @include flex-box(inline, row , center, center);

            .mfp-close {
                top: -12vw;
                right: 2vw;
                $size: 7.5vw;
                width:  $size;
                height:  $size;
                font-size: $size*2;
                line-height: $size;
            }

            .btn {
                @include flex-box(default, row , center, center);
                position: absolute;
                z-index: 100;
                display: none;
                .txt {
                    font-weight: 700;
                    width: auto;
                    font-size: 32px;
                    line-height: 3vw;
                    padding-top: 3px;
                    padding-left: 1px;
                }
                &.right {
                    right: 0;
                    margin-left: 1vw;
                }
                &.left {
                    left: 0;
                    margin-right: 1vw;
                }

                &.show{
                    display: block;
                }
            }
        }
        $max-img-height: 60vh;
        .popup-image-block {
            @include flex-box(inline, row , center, center);
            height: $max-img-height;
            width: 80vw;
        }
        .img-slider {
            @include flex-box(normal, row , center, center);
            width: 80vw;
            .btn {
                padding: 0;
                flex-shrink: 0;
            }
            .slides {
                width: 100%;
                flex-shrink: 0;
            }
            .img-infographic {
                max-height: $max-img-height !important;
                width: auto !important;
                padding: 0;
            }

            .modal-inner-block {
                @include flex-box(normal, row , center, center);
                 height: 80vh;

            }

            .img-slide {
                @include flex-box(normal, row , center, center);
                display: none;

                &.show {
                    @include flex-box('normal', null, null, null, wrap);
                }
                &:not(:first-child) {
                //    display: none;
                }
                .img-infographic-cont {
                    @include flex-box(normal, row , center, center);
                }
                .img-infographic-wrap{
                    @include flex-box(normal, row , center, center);
                    max-height: 100%;
                    @include ie-only() {
                        .img-infographic {
                            position: absolute; // PM - IE Fix
                            top: 0; // PM - IE Fix
                            left: 0; // PM - IE Fix
                            right: 0; // PM - IE Fix
                        }
                    }
                }
            }
        }
    }
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .mfp-content {


            .img-infographic-wrap,
            .slides {
                height: 100%;
                width: 100%;
            }
            .modal-inner-block {
                height: 80vh;
            }

            .popup-image-block,
            .img-slider {
                width: 80vw;
            }

            .img-infographic-cont {
                width: 100%;
                height: 100%;
            }

            .modal-inner-block,
            .img-slider,
            .slides,
            .img-slide {
                height: 100%;
            }
        }
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        .mfp-content {

            $max-img-height: 80vh !important;
            .popup-image-block {
                height: $max-img-height;
            }
            .img-infographic {
                max-height: $max-img-height;
            }
            .mfp-close {
                $size: 2vw;
                top: -3vw;
                right: 0;
                width:  $size;
                height:  $size;
                font-size: $size*2;
                line-height: $size;
            }
            .btn {
                padding-top: 0.2vw;
                .txt {
                    width: auto;
                    font-size: 3vw;
                    line-height: 3vw;

                }
                &.right {
                    margin-left: 1vw;
                    .txt {
                        padding-left: 0.3vw;
                        @include ie-only() {
                            padding-top: 0;
                            padding-bottom: 0.3vw;
                        }
                    }
                }
                &.left {
                    margin-right: 1vw;
                    .txt {
                        padding-right: 0.3w;
                        @include ie-only() {
                            padding-top: 0;
                            padding-bottom: 0.3vw;
                        }
                    }
                }
            }
        }
    }
}