#sp-footer {
    background-color: transparent;

    * {
        text-align: left;
    }

    .container-inner {
        padding: 30px 0;
    }
    #sp-footer3-change,
    #sp-footer3-funk-e,
    #sp-footer3 {
        .sp-column {
            vertical-align: top;
        }
    }

    #sp-logo-footer {
        .logo-image {

            .logo {
                circle,
                path {
                    fill: map-get($colors, footer-header );
                }
            }
        }
    }


    #sp-footer3-funk-e .sp-module,
    #sp-footer3-change .sp-module,
    #sp-footer3 .sp-module,
    .contact {
        vertical-align: top;
        display: inline-block;
    }

    .tagline {
        color: map-get($colors, footer-tagline);
    }

    ul.social-icons {
        margin: 0; // PM - Disable template css
        > li {
            margin: 0; // PM - Disable template css
        }
        .icon {
            max-width: none; // PM - Disable template css
        }
    }

    .sp-module-title {
        color: map-get($colors, footer-header);
        font-weight: bold;
    }
    div.contact,
    div.menu,
    ul.contact,
    ul.menu {
        margin: 0;
        div[class^=item],
        li[class^=item] {
            display: block;
            width: 100%;
            margin: 0;
        }
        p,
        a {
            color: map-get($colors, footer-txt);
        }

        a:hover {
            text-decoration: underline;
        }


    }
    div.menu,
    ul.menu {
        &#footer-menu-3 {
            border-top: 1px solid map-get($colors, divider);

            a {
                color: map-get($colors, footer-txt-light);
            }
        }
    }
    $breakpoint: "mobile-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        div.contact {

            a {
                //background: greenyellow;
                @include set-spacer(1.5, px); // PM - Weird values, but needs to be aligned with the column next to it.
            }
        }
        #sp-footer3-change,
        #sp-footer3-funk-e,
        #sp-footer3,
        #sp-footer2,
        #sp-footer2-change,
        #sp-footer2-funk-e,
        #sp-footer4-change,
        #sp-footer4-funk-e,
        #sp-footer4 {
            width: 50%;
        }
        #sp-logo-footer{
            width: 100%;
        }

        #footer-menu-3 {
            width: 100%;
        }
        #sp-logo-footer {
            .tagline {
                @include set-spacer(3, px);
            }
            .social-icons {
                @include set-spacer(3, px);
            }
        }

        #sp-footer3-change .contact,
        #sp-footer3-funk-e .contact,
        #sp-footer3 .contact {
            width: calc(100% + #{2*map-get($column-gutter,$breakpoint)});
            margin-top: 1*map-get($site-vert-grid, $breakpoint);
            margin-left: -2*map-get($column-gutter,$breakpoint);
            padding-left: 2*map-get($column-gutter,$breakpoint);
            border-top: 1px solid map-get($colors, divider);
            .sp-module-title {
                margin-top: 2.2*map-get($site-vert-grid, $breakpoint);
            }
        }

    }



    @include on-breakpoint($breakpoint) {

        #sp-logo-footer {
            .logo {
                @include set-spacer(2, px);
                width: 146px;
            }

            .tagline {
                width: 298px;
                font-size: 16px;
                line-height: 24px;
            }
            .social-icons {
                height: 20px;
                .item {
                    height: 100%;
                    margin: 0 20px 0 0;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .icon {
                    height: 100%;
                }
            }
        }

        #sp-footer3-change,
        #sp-footer3-funk-e,
        #sp-footer3,
        #sp-footer2,
        #sp-footer2-change,
        #sp-footer2-funk-e,
        #sp-footer4-change,
        #sp-footer4-funk-e,
        #sp-footer4 {
            margin-top: get-vw(20px, map-get($site-widths-design, $breakpoint));
        }

        #sp-footer3-funk-e .sp-module,
        #sp-footer3-change .sp-module,
        #sp-footer3 .sp-module {
            width: 100%;
        }

        .sp-module-title {
            font-size: 17px;
            line-height: 32px;
            @include set-spacer(1, px);
        }
        div.contact,
        div.menu,
        ul.contact,
        ul.menu {
            div[class^=item],
            li[class^=item] {
                @include set-spacer(2, px);
                p,
                a {
                    @include flex-box("normal", null, null, center);

                    font-size: 16px;
                    line-height: 24px;

                    span {
                        order: -1;
                    }

                    img {
                        width: auto;
                        height: 17px;
                        margin-left: 4px;
                        margin-bottom: 4px;
                    }
                }
            }

            &#footer-menu-3 {
                margin-top: 1*map-get($site-vert-grid, $breakpoint);
                padding-top: 2.7*map-get($site-vert-grid, $breakpoint); // PM - Weird values, but needs to be aligned with the column next to it.
                div[class^=item],
                li[class^=item]  {
                    @include set-spacer(1.5, px);

                    a {
                        font-size: 15px;
                        line-height: 24px;
                        color: map-get($colors, footer-txt-light);
                    }
                }
            }
        }
    }

    $breakpoint: "tablet-s-up";

    @include on-breakpoint($breakpoint) {
        .sp-module-title {
            margin-bottom: 5px;
            font-size: 20px;
            line-height: 32px;
        }
        div.menu,
        ul.menu {
            div[class^=item],
            li[class^=item] {
               // margin: 0;
            }
        }
        #sp-logo-footer{
            width: 100%;
        }
    }

    $breakpoint: "desktop-s-up";

    @include on-breakpoint($breakpoint) {
        #sp-logo-footer {
            .logo {
                @include set-spacer(4);
                width: get-vw(167px, map-get($site-widths-design, $breakpoint));
            }

            .tagline {
                width: get-vw(289px, map-get($site-widths-design, $breakpoint));
                font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));
            }
            .social-icons {
                position: absolute;
                margin: 0; // PM - Disable template css
                bottom: 0;
                height: get-vw(20px, map-get($site-widths-design, $breakpoint));
                .item {
                    margin: 0 get-vw(20px, map-get($site-widths-design, $breakpoint)) 0 0 ;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        #sp-footer3-change,
        #sp-footer3-funk-e,
        #sp-footer3,
        #sp-footer2,
        #sp-footer2-change,
        #sp-footer2-funk-e,
        #sp-footer4-change,
        #sp-footer4-funk-e,
        #sp-footer4 {
            margin-top: get-vw(20px, map-get($site-widths-design, $breakpoint));
        }

        #sp-footer3-funk-e .sp-module,
        #sp-footer3-change .sp-module,
        #sp-footer3 .sp-module {
            width: calc(100%*(2/5));
            margin-right: map-get($column-gutter, $breakpoint);
        }
        #sp-footer3-funk-e .contact,
        #sp-footer3-change .contact,

        #sp-footer3 .contact {
            width: calc(100%*(3/5) - #{map-get($column-gutter, $breakpoint)});
            //margin-left: map-get($column-gutter, $breakpoint);
        }

        .sp-module-title {
            margin-bottom: get-vw(5px, map-get($site-widths-design, $breakpoint));
            font-size: get-vw(20px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
        }
        div.contact,
        div.menu,
        ul.contact,
        ul.menu {
            div[class^=item],
            li[class^=item] {
                @include set-spacer(3);
                p,
                a {
                    font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));
                    line-height: get-vw(24px, map-get($site-widths-design, $breakpoint));

                    img {
                        //width: get-vw(19px, map-get($site-widths-design, $breakpoint));
                        width: auto;
                        height: get-vw(17px, map-get($site-widths-design, $breakpoint));
                        margin-left: get-vw(4px, map-get($site-widths-design, $breakpoint));
                    }
                }
            }

            &#footer-menu-3 {
                margin-top: 1*map-get($site-vert-grid, $breakpoint);
                padding-top: 2.5*map-get($site-vert-grid, $breakpoint);
                div[class^=item],
                li[class^=item]  {
                    @include set-spacer(1.5);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        font-size: get-vw(15px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(24px, map-get($site-widths-design, $breakpoint));
                    }
                }
            }
        }
    }
}