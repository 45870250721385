@charset "UTF-8";
/*@font-face {
	font-family: 'Century Gothic';
	src: url('/src/fonts/CenturyGothic.woff') format('woff'),
		url('/src/fonts/CenturyGothic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('/src/fonts/CenturyGothic-Bold.woff') format('woff'),
		url('/src/fonts/CenturyGothic-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}*/
/* @font-face {
    font-family: 'Gilroy-Regular';
    src: url('/src/fonts/gilroy/397815_0_0.eot'),
        url('/src/fonts/gilroy/397815_0_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_0_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_0_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_0_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_1_0.eot'),
        url('/src/fonts/gilroy/397815_1_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_1_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_1_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_1_0.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
}


@font-face {
    font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_2_0.eot'),
    url('/src/fonts/gilroy/397815_2_0.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/gilroy/397815_2_0.woff2') format('woff2'),
    url('/src/fonts/gilroy/397815_2_0.woff') format('woff'),
    url('/src/fonts/gilroy/397815_2_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-Light';
    src: url('/src/fonts/gilroy/397815_3_0.eot'),
        url('/src/fonts/gilroy/397815_3_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_3_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_3_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_3_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('/src/fonts/gilroy/397815_4_0.eot'),
        url('/src/fonts/gilroy/397815_4_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_4_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_4_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_4_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_5_0.eot'),
        url('/src/fonts/gilroy/397815_5_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_5_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_5_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_5_0.ttf') format('truetype');
    font-weight: bold;
	font-style: italic;
}


@font-face {font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_6_0.eot'),
        url('/src/fonts/gilroy/397815_6_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_6_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_6_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_6_0.ttf') format('truetype');
    font-weight: bold;
	font-style: normal;
} */
@font-face {
  font-display: auto;
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_3_0.eot");
  src: url("/src/fonts/gilroy/397815_3_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_3_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_3_0.woff") format("woff"), url("/src/fonts/gilroy/397815_3_0.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-display: auto;
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_0_0.eot");
  src: url("/src/fonts/gilroy/397815_0_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_0_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_0_0.woff") format("woff"), url("/src/fonts/gilroy/397815_0_0.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-display: auto;
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_1_0.eot");
  src: url("/src/fonts/gilroy/397815_1_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_1_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_1_0.woff") format("woff"), url("/src/fonts/gilroy/397815_1_0.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_2_0.eot");
  src: url("/src/fonts/gilroy/397815_2_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_2_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_2_0.woff") format("woff"), url("/src/fonts/gilroy/397815_2_0.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-display: auto;
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_6_0.eot");
  src: url("/src/fonts/gilroy/397815_6_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_6_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_6_0.woff") format("woff"), url("/src/fonts/gilroy/397815_6_0.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-display: auto;
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_5_0.eot");
  src: url("/src/fonts/gilroy/397815_5_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_5_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_5_0.woff") format("woff"), url("/src/fonts/gilroy/397815_5_0.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-display: auto;
  font-family: "Gilroy";
  src: url("/src/fonts/gilroy/397815_4_0.eot");
  src: url("/src/fonts/gilroy/397815_4_0.eot?#iefix") format("embedded-opentype"), url("/src/fonts/gilroy/397815_4_0.woff2") format("woff2"), url("/src/fonts/gilroy/397815_4_0.woff") format("woff"), url("/src/fonts/gilroy/397815_4_0.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

body:before {
  display: none;
  content: ""; }
  @media all and (min-width: 0px) {
    body:before {
      content: "mobile-s-up"; } }
  @media all and (min-width: 375px) {
    body:before {
      content: "mobile-m-up"; } }
  @media all and (min-width: 768px) {
    body:before {
      content: "tablet-s-up"; } }
  @media all and (min-width: 768px) {
    body:before {
      content: "tablet-m-up"; } }
  @media all and (min-width: 1024px) {
    body:before {
      content: "tablet-l-up"; } }
  @media all and (min-width: 1024px) {
    body:before {
      content: "desktop-s-up"; } }
  @media all and (min-width: 1440px) {
    body:before {
      content: "desktop-m-up"; } }
  @media all and (min-width: 2560px) {
    body:before {
      content: "desktop-l-up"; } }

body:after {
  display: none;
  content: ""; }
  @media all and (min-width: 0px) {
    body:after {
      content: "mobile-s-up"; } }
  @media all and (min-width: 376px) {
    body:after {
      content: "mobile-m-up"; } }
  @media all and (min-width: 769px) {
    body:after {
      content: "tablet-s-up"; } }
  @media all and (min-width: 769px) {
    body:after {
      content: "tablet-m-up"; } }
  @media all and (min-width: 1025px) {
    body:after {
      content: "tablet-l-up"; } }
  @media all and (min-width: 1025px) {
    body:after {
      content: "desktop-s-up"; } }
  @media all and (min-width: 1441px) {
    body:after {
      content: "desktop-m-up"; } }
  @media all and (min-width: 2561px) {
    body:after {
      content: "desktop-l-up"; } }

#sp-footer {
  background-color: transparent; }
  #sp-footer * {
    text-align: left; }
  #sp-footer .container-inner {
    padding: 30px 0; }
  #sp-footer #sp-footer3-change .sp-column,
  #sp-footer #sp-footer3-funk-e .sp-column,
  #sp-footer #sp-footer3 .sp-column {
    vertical-align: top; }
  #sp-footer #sp-logo-footer .logo-image .logo circle,
  #sp-footer #sp-logo-footer .logo-image .logo path {
    fill: #330072; }
  #sp-footer #sp-footer3-funk-e .sp-module,
  #sp-footer #sp-footer3-change .sp-module,
  #sp-footer #sp-footer3 .sp-module,
  #sp-footer .contact {
    vertical-align: top;
    display: inline-block; }
  #sp-footer .tagline {
    color: #330072; }
  #sp-footer ul.social-icons {
    margin: 0; }
    #sp-footer ul.social-icons > li {
      margin: 0; }
    #sp-footer ul.social-icons .icon {
      max-width: none; }
  #sp-footer .sp-module-title {
    color: #330072;
    font-weight: bold; }
  #sp-footer div.contact,
  #sp-footer div.menu,
  #sp-footer ul.contact,
  #sp-footer ul.menu {
    margin: 0; }
    #sp-footer div.contact div[class^=item],
    #sp-footer div.contact li[class^=item],
    #sp-footer div.menu div[class^=item],
    #sp-footer div.menu li[class^=item],
    #sp-footer ul.contact div[class^=item],
    #sp-footer ul.contact li[class^=item],
    #sp-footer ul.menu div[class^=item],
    #sp-footer ul.menu li[class^=item] {
      display: block;
      width: 100%;
      margin: 0; }
    #sp-footer div.contact p,
    #sp-footer div.contact a,
    #sp-footer div.menu p,
    #sp-footer div.menu a,
    #sp-footer ul.contact p,
    #sp-footer ul.contact a,
    #sp-footer ul.menu p,
    #sp-footer ul.menu a {
      color: #333333; }
    #sp-footer div.contact a:hover,
    #sp-footer div.menu a:hover,
    #sp-footer ul.contact a:hover,
    #sp-footer ul.menu a:hover {
      text-decoration: underline; }
  #sp-footer div.menu#footer-menu-3,
  #sp-footer ul.menu#footer-menu-3 {
    border-top: 1px solid #cccccc; }
    #sp-footer div.menu#footer-menu-3 a,
    #sp-footer ul.menu#footer-menu-3 a {
      color: #666666; }
  @media all and (min-width: 0px) and (max-width: 1023px) {
    #sp-footer div.contact a {
      margin-bottom: 12px; }
    #sp-footer #sp-footer3-change,
    #sp-footer #sp-footer3-funk-e,
    #sp-footer #sp-footer3,
    #sp-footer #sp-footer2,
    #sp-footer #sp-footer2-change,
    #sp-footer #sp-footer2-funk-e,
    #sp-footer #sp-footer4-change,
    #sp-footer #sp-footer4-funk-e,
    #sp-footer #sp-footer4 {
      width: 50%; }
    #sp-footer #sp-logo-footer {
      width: 100%; }
    #sp-footer #footer-menu-3 {
      width: 100%; }
    #sp-footer #sp-logo-footer .tagline {
      margin-bottom: 24px; }
    #sp-footer #sp-logo-footer .social-icons {
      margin-bottom: 24px; }
    #sp-footer #sp-footer3-change .contact,
    #sp-footer #sp-footer3-funk-e .contact,
    #sp-footer #sp-footer3 .contact {
      width: calc(100% + 6px);
      margin-top: 8px;
      margin-left: -6px;
      padding-left: 6px;
      border-top: 1px solid #cccccc; }
      #sp-footer #sp-footer3-change .contact .sp-module-title,
      #sp-footer #sp-footer3-funk-e .contact .sp-module-title,
      #sp-footer #sp-footer3 .contact .sp-module-title {
        margin-top: 17.6px; } }
  @media all and (min-width: 0px) {
    #sp-footer #sp-logo-footer .logo {
      margin-bottom: 16px;
      width: 146px; }
    #sp-footer #sp-logo-footer .tagline {
      width: 298px;
      font-size: 16px;
      line-height: 24px; }
    #sp-footer #sp-logo-footer .social-icons {
      height: 20px; }
      #sp-footer #sp-logo-footer .social-icons .item {
        height: 100%;
        margin: 0 20px 0 0; }
        #sp-footer #sp-logo-footer .social-icons .item:last-child {
          margin-right: 0; }
      #sp-footer #sp-logo-footer .social-icons .icon {
        height: 100%; }
    #sp-footer #sp-footer3-change,
    #sp-footer #sp-footer3-funk-e,
    #sp-footer #sp-footer3,
    #sp-footer #sp-footer2,
    #sp-footer #sp-footer2-change,
    #sp-footer #sp-footer2-funk-e,
    #sp-footer #sp-footer4-change,
    #sp-footer #sp-footer4-funk-e,
    #sp-footer #sp-footer4 {
      margin-top: 4.26667vw; }
    #sp-footer #sp-footer3-funk-e .sp-module,
    #sp-footer #sp-footer3-change .sp-module,
    #sp-footer #sp-footer3 .sp-module {
      width: 100%; }
    #sp-footer .sp-module-title {
      font-size: 17px;
      line-height: 32px;
      margin-bottom: 8px; }
    #sp-footer div.contact div[class^=item],
    #sp-footer div.contact li[class^=item],
    #sp-footer div.menu div[class^=item],
    #sp-footer div.menu li[class^=item],
    #sp-footer ul.contact div[class^=item],
    #sp-footer ul.contact li[class^=item],
    #sp-footer ul.menu div[class^=item],
    #sp-footer ul.menu li[class^=item] {
      margin-bottom: 16px; }
      #sp-footer div.contact div[class^=item] p,
      #sp-footer div.contact div[class^=item] a,
      #sp-footer div.contact li[class^=item] p,
      #sp-footer div.contact li[class^=item] a,
      #sp-footer div.menu div[class^=item] p,
      #sp-footer div.menu div[class^=item] a,
      #sp-footer div.menu li[class^=item] p,
      #sp-footer div.menu li[class^=item] a,
      #sp-footer ul.contact div[class^=item] p,
      #sp-footer ul.contact div[class^=item] a,
      #sp-footer ul.contact li[class^=item] p,
      #sp-footer ul.contact li[class^=item] a,
      #sp-footer ul.menu div[class^=item] p,
      #sp-footer ul.menu div[class^=item] a,
      #sp-footer ul.menu li[class^=item] p,
      #sp-footer ul.menu li[class^=item] a {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        font-size: 16px;
        line-height: 24px; }
        #sp-footer div.contact div[class^=item] p span,
        #sp-footer div.contact div[class^=item] a span,
        #sp-footer div.contact li[class^=item] p span,
        #sp-footer div.contact li[class^=item] a span,
        #sp-footer div.menu div[class^=item] p span,
        #sp-footer div.menu div[class^=item] a span,
        #sp-footer div.menu li[class^=item] p span,
        #sp-footer div.menu li[class^=item] a span,
        #sp-footer ul.contact div[class^=item] p span,
        #sp-footer ul.contact div[class^=item] a span,
        #sp-footer ul.contact li[class^=item] p span,
        #sp-footer ul.contact li[class^=item] a span,
        #sp-footer ul.menu div[class^=item] p span,
        #sp-footer ul.menu div[class^=item] a span,
        #sp-footer ul.menu li[class^=item] p span,
        #sp-footer ul.menu li[class^=item] a span {
          order: -1; }
        #sp-footer div.contact div[class^=item] p img,
        #sp-footer div.contact div[class^=item] a img,
        #sp-footer div.contact li[class^=item] p img,
        #sp-footer div.contact li[class^=item] a img,
        #sp-footer div.menu div[class^=item] p img,
        #sp-footer div.menu div[class^=item] a img,
        #sp-footer div.menu li[class^=item] p img,
        #sp-footer div.menu li[class^=item] a img,
        #sp-footer ul.contact div[class^=item] p img,
        #sp-footer ul.contact div[class^=item] a img,
        #sp-footer ul.contact li[class^=item] p img,
        #sp-footer ul.contact li[class^=item] a img,
        #sp-footer ul.menu div[class^=item] p img,
        #sp-footer ul.menu div[class^=item] a img,
        #sp-footer ul.menu li[class^=item] p img,
        #sp-footer ul.menu li[class^=item] a img {
          width: auto;
          height: 17px;
          margin-left: 4px;
          margin-bottom: 4px; }
    #sp-footer div.contact#footer-menu-3,
    #sp-footer div.menu#footer-menu-3,
    #sp-footer ul.contact#footer-menu-3,
    #sp-footer ul.menu#footer-menu-3 {
      margin-top: 8px;
      padding-top: 21.6px; }
      #sp-footer div.contact#footer-menu-3 div[class^=item],
      #sp-footer div.contact#footer-menu-3 li[class^=item],
      #sp-footer div.menu#footer-menu-3 div[class^=item],
      #sp-footer div.menu#footer-menu-3 li[class^=item],
      #sp-footer ul.contact#footer-menu-3 div[class^=item],
      #sp-footer ul.contact#footer-menu-3 li[class^=item],
      #sp-footer ul.menu#footer-menu-3 div[class^=item],
      #sp-footer ul.menu#footer-menu-3 li[class^=item] {
        margin-bottom: 12px; }
        #sp-footer div.contact#footer-menu-3 div[class^=item] a,
        #sp-footer div.contact#footer-menu-3 li[class^=item] a,
        #sp-footer div.menu#footer-menu-3 div[class^=item] a,
        #sp-footer div.menu#footer-menu-3 li[class^=item] a,
        #sp-footer ul.contact#footer-menu-3 div[class^=item] a,
        #sp-footer ul.contact#footer-menu-3 li[class^=item] a,
        #sp-footer ul.menu#footer-menu-3 div[class^=item] a,
        #sp-footer ul.menu#footer-menu-3 li[class^=item] a {
          font-size: 15px;
          line-height: 24px;
          color: #666666; } }
  @media all and (min-width: 768px) {
    #sp-footer .sp-module-title {
      margin-bottom: 5px;
      font-size: 20px;
      line-height: 32px; }
    #sp-footer #sp-logo-footer {
      width: 100%; } }
  @media all and (min-width: 1024px) {
    #sp-footer #sp-logo-footer .logo {
      margin-bottom: 1.77778vw;
      width: 9.27778vw; }
    #sp-footer #sp-logo-footer .tagline {
      width: 16.05556vw;
      font-size: 0.94444vw;
      line-height: 1.55556vw; }
    #sp-footer #sp-logo-footer .social-icons {
      position: absolute;
      margin: 0;
      bottom: 0;
      height: 1.11111vw; }
      #sp-footer #sp-logo-footer .social-icons .item {
        margin: 0 1.11111vw 0 0; }
        #sp-footer #sp-logo-footer .social-icons .item:last-child {
          margin-right: 0; }
    #sp-footer #sp-footer3-change,
    #sp-footer #sp-footer3-funk-e,
    #sp-footer #sp-footer3,
    #sp-footer #sp-footer2,
    #sp-footer #sp-footer2-change,
    #sp-footer #sp-footer2-funk-e,
    #sp-footer #sp-footer4-change,
    #sp-footer #sp-footer4-funk-e,
    #sp-footer #sp-footer4 {
      margin-top: 1.11111vw; }
    #sp-footer #sp-footer3-funk-e .sp-module,
    #sp-footer #sp-footer3-change .sp-module,
    #sp-footer #sp-footer3 .sp-module {
      width: calc(100%*(2/5));
      margin-right: 1.11111vw; }
    #sp-footer #sp-footer3-funk-e .contact,
    #sp-footer #sp-footer3-change .contact,
    #sp-footer #sp-footer3 .contact {
      width: calc(100%*(3/5) - 1.11111vw); }
    #sp-footer .sp-module-title {
      margin-bottom: 0.27778vw;
      font-size: 1.11111vw;
      line-height: 1.77778vw; }
    #sp-footer div.contact div[class^=item],
    #sp-footer div.contact li[class^=item],
    #sp-footer div.menu div[class^=item],
    #sp-footer div.menu li[class^=item],
    #sp-footer ul.contact div[class^=item],
    #sp-footer ul.contact li[class^=item],
    #sp-footer ul.menu div[class^=item],
    #sp-footer ul.menu li[class^=item] {
      margin-bottom: 1.33333vw; }
      #sp-footer div.contact div[class^=item] p,
      #sp-footer div.contact div[class^=item] a,
      #sp-footer div.contact li[class^=item] p,
      #sp-footer div.contact li[class^=item] a,
      #sp-footer div.menu div[class^=item] p,
      #sp-footer div.menu div[class^=item] a,
      #sp-footer div.menu li[class^=item] p,
      #sp-footer div.menu li[class^=item] a,
      #sp-footer ul.contact div[class^=item] p,
      #sp-footer ul.contact div[class^=item] a,
      #sp-footer ul.contact li[class^=item] p,
      #sp-footer ul.contact li[class^=item] a,
      #sp-footer ul.menu div[class^=item] p,
      #sp-footer ul.menu div[class^=item] a,
      #sp-footer ul.menu li[class^=item] p,
      #sp-footer ul.menu li[class^=item] a {
        font-size: 0.94444vw;
        line-height: 1.33333vw; }
        #sp-footer div.contact div[class^=item] p img,
        #sp-footer div.contact div[class^=item] a img,
        #sp-footer div.contact li[class^=item] p img,
        #sp-footer div.contact li[class^=item] a img,
        #sp-footer div.menu div[class^=item] p img,
        #sp-footer div.menu div[class^=item] a img,
        #sp-footer div.menu li[class^=item] p img,
        #sp-footer div.menu li[class^=item] a img,
        #sp-footer ul.contact div[class^=item] p img,
        #sp-footer ul.contact div[class^=item] a img,
        #sp-footer ul.contact li[class^=item] p img,
        #sp-footer ul.contact li[class^=item] a img,
        #sp-footer ul.menu div[class^=item] p img,
        #sp-footer ul.menu div[class^=item] a img,
        #sp-footer ul.menu li[class^=item] p img,
        #sp-footer ul.menu li[class^=item] a img {
          width: auto;
          height: 0.94444vw;
          margin-left: 0.22222vw; }
    #sp-footer div.contact#footer-menu-3,
    #sp-footer div.menu#footer-menu-3,
    #sp-footer ul.contact#footer-menu-3,
    #sp-footer ul.menu#footer-menu-3 {
      margin-top: 8px;
      padding-top: 20px; }
      #sp-footer div.contact#footer-menu-3 div[class^=item],
      #sp-footer div.contact#footer-menu-3 li[class^=item],
      #sp-footer div.menu#footer-menu-3 div[class^=item],
      #sp-footer div.menu#footer-menu-3 li[class^=item],
      #sp-footer ul.contact#footer-menu-3 div[class^=item],
      #sp-footer ul.contact#footer-menu-3 li[class^=item],
      #sp-footer ul.menu#footer-menu-3 div[class^=item],
      #sp-footer ul.menu#footer-menu-3 li[class^=item] {
        margin-bottom: 0.66667vw; }
        #sp-footer div.contact#footer-menu-3 div[class^=item]:last-child,
        #sp-footer div.contact#footer-menu-3 li[class^=item]:last-child,
        #sp-footer div.menu#footer-menu-3 div[class^=item]:last-child,
        #sp-footer div.menu#footer-menu-3 li[class^=item]:last-child,
        #sp-footer ul.contact#footer-menu-3 div[class^=item]:last-child,
        #sp-footer ul.contact#footer-menu-3 li[class^=item]:last-child,
        #sp-footer ul.menu#footer-menu-3 div[class^=item]:last-child,
        #sp-footer ul.menu#footer-menu-3 li[class^=item]:last-child {
          margin-bottom: 0; }
        #sp-footer div.contact#footer-menu-3 div[class^=item] a,
        #sp-footer div.contact#footer-menu-3 li[class^=item] a,
        #sp-footer div.menu#footer-menu-3 div[class^=item] a,
        #sp-footer div.menu#footer-menu-3 li[class^=item] a,
        #sp-footer ul.contact#footer-menu-3 div[class^=item] a,
        #sp-footer ul.contact#footer-menu-3 li[class^=item] a,
        #sp-footer ul.menu#footer-menu-3 div[class^=item] a,
        #sp-footer ul.menu#footer-menu-3 li[class^=item] a {
          font-size: 0.83333vw;
          line-height: 1.33333vw; } }

#sp-bottom {
  border-top: 1px solid #f5f5f5;
  padding: 0;
  line-height: 1; }
  #sp-bottom * {
    text-align: left;
    color: #666666; }
  #sp-bottom .sp-module {
    margin-bottom: 0; }
  #sp-bottom #sp-bottom2-funk-e,
  #sp-bottom #sp-bottom2-change,
  #sp-bottom #sp-bottom2,
  #sp-bottom #sp-bottom3-funk-e,
  #sp-bottom #sp-bottom3-change,
  #sp-bottom #sp-bottom3,
  #sp-bottom #sp-bottom1-funk-e,
  #sp-bottom #sp-bottom1-change,
  #sp-bottom #sp-bottom1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center; }
    #sp-bottom #sp-bottom2-funk-e .menu,
    #sp-bottom #sp-bottom2-change .menu,
    #sp-bottom #sp-bottom2 .menu,
    #sp-bottom #sp-bottom3-funk-e .menu,
    #sp-bottom #sp-bottom3-change .menu,
    #sp-bottom #sp-bottom3 .menu,
    #sp-bottom #sp-bottom1-funk-e .menu,
    #sp-bottom #sp-bottom1-change .menu,
    #sp-bottom #sp-bottom1 .menu {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center; }
      #sp-bottom #sp-bottom2-funk-e .menu li,
      #sp-bottom #sp-bottom2-change .menu li,
      #sp-bottom #sp-bottom2 .menu li,
      #sp-bottom #sp-bottom3-funk-e .menu li,
      #sp-bottom #sp-bottom3-change .menu li,
      #sp-bottom #sp-bottom3 .menu li,
      #sp-bottom #sp-bottom1-funk-e .menu li,
      #sp-bottom #sp-bottom1-change .menu li,
      #sp-bottom #sp-bottom1 .menu li {
        display: inline-block;
        margin-bottom: 0; }
        #sp-bottom #sp-bottom2-funk-e .menu li:last-child,
        #sp-bottom #sp-bottom2-change .menu li:last-child,
        #sp-bottom #sp-bottom2 .menu li:last-child,
        #sp-bottom #sp-bottom3-funk-e .menu li:last-child,
        #sp-bottom #sp-bottom3-change .menu li:last-child,
        #sp-bottom #sp-bottom3 .menu li:last-child,
        #sp-bottom #sp-bottom1-funk-e .menu li:last-child,
        #sp-bottom #sp-bottom1-change .menu li:last-child,
        #sp-bottom #sp-bottom1 .menu li:last-child {
          margin-right: 0; }
        #sp-bottom #sp-bottom2-funk-e .menu li:hover,
        #sp-bottom #sp-bottom2-change .menu li:hover,
        #sp-bottom #sp-bottom2 .menu li:hover,
        #sp-bottom #sp-bottom3-funk-e .menu li:hover,
        #sp-bottom #sp-bottom3-change .menu li:hover,
        #sp-bottom #sp-bottom3 .menu li:hover,
        #sp-bottom #sp-bottom1-funk-e .menu li:hover,
        #sp-bottom #sp-bottom1-change .menu li:hover,
        #sp-bottom #sp-bottom1 .menu li:hover {
          text-decoration: underline; }
  @media all and (min-width: 0px) and (max-width: 1023px) {
    #sp-bottom * {
      font-size: 12px; }
    #sp-bottom .row {
      margin-top: 24px;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      margin-bottom: 20px; }
    #sp-bottom #sp-bottom2-funk-e,
    #sp-bottom #sp-bottom2-change,
    #sp-bottom #sp-bottom2,
    #sp-bottom #sp-bottom4-funk-e,
    #sp-bottom #sp-bottom4-change,
    #sp-bottom #sp-bottom4,
    #sp-bottom #sp-bottom1-funk-e,
    #sp-bottom #sp-bottom1-change,
    #sp-bottom #sp-bottom1 {
      width: 50%;
      min-width: 280px; }
    #sp-bottom #sp-bottom3-funk-e,
    #sp-bottom #sp-bottom3-change,
    #sp-bottom #sp-bottom3 {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin-bottom: 8px; }
    #sp-bottom #sp-bottom2-funk-e,
    #sp-bottom #sp-bottom2-change,
    #sp-bottom #sp-bottom2,
    #sp-bottom #sp-bottom3-funk-e,
    #sp-bottom #sp-bottom3-change,
    #sp-bottom #sp-bottom3 {
      margin-bottom: 3.41333vw; }
    #sp-bottom #sp-bottom2-funk-e .menu,
    #sp-bottom #sp-bottom2-change .menu,
    #sp-bottom #sp-bottom2 .menu {
      justify-content: flex-start;
      /*width: calc(100%*(4/12));*/ }
      #sp-bottom #sp-bottom2-funk-e .menu [class^="item"]:not(:last-child),
      #sp-bottom #sp-bottom2-change .menu [class^="item"]:not(:last-child),
      #sp-bottom #sp-bottom2 .menu [class^="item"]:not(:last-child) {
        padding-right: 16px; }
    #sp-bottom #sp-bottom4-funk-e,
    #sp-bottom #sp-bottom4-change,
    #sp-bottom #sp-bottom4 {
      order: -1; }
    #sp-bottom #sp-bottom4-funk-e,
    #sp-bottom #sp-bottom4-change,
    #sp-bottom #sp-bottom4,
    #sp-bottom #sp-bottom1-funk-e,
    #sp-bottom #sp-bottom1-change,
    #sp-bottom #sp-bottom1 {
      flex-shrink: 1; } }
  @media all and (min-width: 0px) {
    #sp-bottom .sp-column {
      width: 100%; }
    #sp-bottom #sp-bottom2-funk-e .menu,
    #sp-bottom #sp-bottom2-change .menu,
    #sp-bottom #sp-bottom2 .menu {
      width: 100%; }
      #sp-bottom #sp-bottom2-funk-e .menu:last-child,
      #sp-bottom #sp-bottom2-change .menu:last-child,
      #sp-bottom #sp-bottom2 .menu:last-child {
        margin-right: 0; } }
  @media all and (min-width: 768px) and (max-width: 1023px) {
    #sp-bottom * {
      font-size: 13px; }
    #sp-bottom #sp-bottom1-funk-e,
    #sp-bottom #sp-bottom1-change,
    #sp-bottom #sp-bottom1,
    #sp-bottom #sp-bottom4-funk-e,
    #sp-bottom #sp-bottom4-change,
    #sp-bottom #sp-bottom4 {
      width: 50%;
      margin-bottom: 32px; } }
  @media all and (min-width: 1024px) {
    #sp-bottom * {
      font-size: 0.72222vw; }
    #sp-bottom .row {
      height: 2.33333vw;
      margin-top: 1.66667vw;
      margin-bottom: 6.66667vw; }
    #sp-bottom #sp-bottom2-funk-e .menu li,
    #sp-bottom #sp-bottom2-change .menu li,
    #sp-bottom #sp-bottom2 .menu li {
      margin-right: 1.77778vw;
      line-height: 0.88889vw; }
    #sp-bottom #sp-bottom2-funk-e .menu:last-child,
    #sp-bottom #sp-bottom2-change .menu:last-child,
    #sp-bottom #sp-bottom2 .menu:last-child {
      margin-right: 0;
      margin-bottom: 0; }
    #sp-bottom #sp-bottom3-funk-e,
    #sp-bottom #sp-bottom3-change,
    #sp-bottom #sp-bottom3 {
      margin-top: 0; }
      #sp-bottom #sp-bottom3-funk-e .sp-column .sp-copyright,
      #sp-bottom #sp-bottom3-change .sp-column .sp-copyright,
      #sp-bottom #sp-bottom3 .sp-column .sp-copyright {
        display: block; }
    #sp-bottom #sp-bottom4-funk-e,
    #sp-bottom #sp-bottom4-change,
    #sp-bottom #sp-bottom4 {
      order: none; }
    #sp-bottom #sp-bottom2-funk-e .sp-column,
    #sp-bottom #sp-bottom2-change .sp-column,
    #sp-bottom #sp-bottom2 .sp-column,
    #sp-bottom #sp-bottom3-funk-e .sp-column,
    #sp-bottom #sp-bottom3-change .sp-column,
    #sp-bottom #sp-bottom3 .sp-column {
      margin-top: 1px; } }

body.ltr.offcanvs-position-right {
  /*
    .offcanvas-menu {
        left: 0;
        .offcanvas-inner{
            .fixed-cntr{
                display: flex!important;
                flex-wrap: wrap;
            }
        }
    }
    */ }
  body.ltr.offcanvs-position-right .offcanvas-menu {
    width: 100%;
    left: 100%;
    transition: none;
    background-color: #330072; }
    body.ltr.offcanvs-position-right .offcanvas-menu .top-bar {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      position: relative;
      padding: 12px 20px; }
    body.ltr.offcanvs-position-right .offcanvas-menu .logo {
      width: 48px;
      height: 48px; }
    body.ltr.offcanvs-position-right .offcanvas-menu .close-offcanvas {
      position: static;
      margin-bottom: 3px;
      border-radius: 0;
      background-color: transparent;
      text-align: right; }
      body.ltr.offcanvs-position-right .offcanvas-menu .close-offcanvas .line {
        position: absolute;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        background-color: #FFFFFF; }
        body.ltr.offcanvs-position-right .offcanvas-menu .close-offcanvas .line.top-to-bottom {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
        body.ltr.offcanvs-position-right .offcanvas-menu .close-offcanvas .line.bottom-to-top {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }
  body.ltr.offcanvs-position-right .body-wrapper {
    position: static; }
  body.ltr.offcanvs-position-right.offcanvas-active .offcanvas-menu {
    left: 0; }
  body.ltr.offcanvs-position-right.offcanvas-active .offcanvas-overlay {
    position: fixed;
    left: 0; }

.site .offcanvas-menu .offcanvas-inner .sp-module ul > li.menu-parent > a > .menu-toggler:after, .site .offcanvas-menu .offcanvas-inner .sp-module ul > li.menu-parent > .menu-separator > .menu-toggler:after {
  content: ''; }

.offcanvas-active .offcanvas-menu .offcanvas-inner .fixed-cntr {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  flex-wrap: wrap; }

.offcanvas-menu .cls-1 {
  opacity: 1; }

.offcanvas-menu .offcanvas-inner {
  padding: 30px 24px 30px 30px; }
  .offcanvas-menu .offcanvas-inner .fixed-cntr {
    padding: 36px 18px 24px 18px;
    justify-content: center;
    bottom: 0;
    display: none;
    left: 0;
    z-index: 2;
    position: fixed;
    width: 100%;
    background: #330072; }
    .offcanvas-menu .offcanvas-inner .fixed-cntr .btn {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      max-width: 280px;
      padding: 0 28px; }
    .offcanvas-menu .offcanvas-inner .fixed-cntr .language-switcher {
      width: 100%; }
      .offcanvas-menu .offcanvas-inner .fixed-cntr .language-switcher .moduletable {
        width: auto; }
      .offcanvas-menu .offcanvas-inner .fixed-cntr .language-switcher svg path {
        fill: #FFFFFF; }
      .offcanvas-menu .offcanvas-inner .fixed-cntr .language-switcher .chzn-single {
        width: auto; }
  .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content {
    padding-bottom: 160px; }
    .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu li {
      margin-top: 16px;
      margin-bottom: 24px;
      /*
                    &.menu-parent-open{
                        .menu-toggler{
                            svg{
                                transform: rotate(180deg);

                            }
                        }
                    }
                    */ }
      .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu li .menu-toggler {
        display: none;
        /*
                        width: 15px;
                        height: 9px;
                        padding: 0;
                        -webkit-transform: none;
                        transform: none;
                        svg{
                            transition: all 0.5s;
                            -webkit-transform: none;
                            transform: none;
                            position: absolute;
                            top: -50%;
                            height: 100%;
                            width: 100%;
                            path{
                                fill: map-get($colors, icon-light);
                            }
                        }*/ }
      .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu li .menu-link {
        font-size: 30px;
        line-height: 40px;
        color: #FFFFFF; }
        .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu li .menu-link > img {
          display: none; }
        .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu li .menu-link .menu-img {
          display: none; }
      .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu li .menu-child .menu-link {
        font-size: 20px;
        color: #c1aed2;
        line-height: 32px; }
    .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu .menu-child {
      display: block;
      margin: 0;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16); }
      .offcanvas-menu .offcanvas-inner .sp-module .sp-module-content .menu .menu-child li {
        margin: 2px 0; }

@media all and (min-width: 0px) {
  .mfp-container .mfp-content {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center; }
    .mfp-container .mfp-content .mfp-close {
      top: -12vw;
      right: 2vw;
      width: 7.5vw;
      height: 7.5vw;
      font-size: 15vw;
      line-height: 7.5vw; }
    .mfp-container .mfp-content .btn {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      position: absolute;
      z-index: 100;
      display: none; }
      .mfp-container .mfp-content .btn .txt {
        font-weight: 700;
        width: auto;
        font-size: 32px;
        line-height: 3vw;
        padding-top: 3px;
        padding-left: 1px; }
      .mfp-container .mfp-content .btn.right {
        right: 0;
        margin-left: 1vw; }
      .mfp-container .mfp-content .btn.left {
        left: 0;
        margin-right: 1vw; }
      .mfp-container .mfp-content .btn.show {
        display: block; }
  .mfp-container .popup-image-block {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 60vh;
    width: 80vw; }
  .mfp-container .img-slider {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 80vw; }
    .mfp-container .img-slider .btn {
      padding: 0;
      flex-shrink: 0; }
    .mfp-container .img-slider .slides {
      width: 100%;
      flex-shrink: 0; }
    .mfp-container .img-slider .img-infographic {
      max-height: 60vh !important;
      width: auto !important;
      padding: 0; }
    .mfp-container .img-slider .modal-inner-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      height: 80vh; }
    .mfp-container .img-slider .img-slide {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      display: none; }
      .mfp-container .img-slider .img-slide.show {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap; }
      .mfp-container .img-slider .img-slide .img-infographic-cont {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center; }
      .mfp-container .img-slider .img-slide .img-infographic-wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        max-height: 100%; } }
      @media all and (min-width: 0px) and (-ms-high-contrast: none) {
        .mfp-container .img-slider .img-slide .img-infographic-wrap .img-infographic {
          position: absolute;
          top: 0;
          left: 0;
          right: 0; } }

@media all and (min-width: 768px) {
  .mfp-container .mfp-content .img-infographic-wrap,
  .mfp-container .mfp-content .slides {
    height: 100%;
    width: 100%; }
  .mfp-container .mfp-content .modal-inner-block {
    height: 80vh; }
  .mfp-container .mfp-content .popup-image-block,
  .mfp-container .mfp-content .img-slider {
    width: 80vw; }
  .mfp-container .mfp-content .img-infographic-cont {
    width: 100%;
    height: 100%; }
  .mfp-container .mfp-content .modal-inner-block,
  .mfp-container .mfp-content .img-slider,
  .mfp-container .mfp-content .slides,
  .mfp-container .mfp-content .img-slide {
    height: 100%; } }

@media all and (min-width: 1024px) {
  .mfp-container .mfp-content .popup-image-block {
    height: 80vh !important; }
  .mfp-container .mfp-content .img-infographic {
    max-height: 80vh !important; }
  .mfp-container .mfp-content .mfp-close {
    top: -3vw;
    right: 0;
    width: 2vw;
    height: 2vw;
    font-size: 4vw;
    line-height: 2vw; }
  .mfp-container .mfp-content .btn {
    padding-top: 0.2vw; }
    .mfp-container .mfp-content .btn .txt {
      width: auto;
      font-size: 3vw;
      line-height: 3vw; }
    .mfp-container .mfp-content .btn.right {
      margin-left: 1vw; }
      .mfp-container .mfp-content .btn.right .txt {
        padding-left: 0.3vw; } }
      @media all and (min-width: 1024px) and (-ms-high-contrast: none) {
        .mfp-container .mfp-content .btn.right .txt {
          padding-top: 0;
          padding-bottom: 0.3vw; } }

@media all and (min-width: 1024px) {
    .mfp-container .mfp-content .btn.left {
      margin-right: 1vw; }
      .mfp-container .mfp-content .btn.left .txt {
        padding-right: 0.3w; } }
      @media all and (min-width: 1024px) and (-ms-high-contrast: none) {
        .mfp-container .mfp-content .btn.left .txt {
          padding-top: 0;
          padding-bottom: 0.3vw; } }

@media all and (min-width: 0px) {
  .sppb-carousel-pro.full-width {
    left: -30px;
    width: 100vw; } }

@media all and (min-width: 768px) {
  .sppb-carousel-pro.full-width {
    left: -60px;
    width: 100vw; } }

@media all and (min-width: 1024px) {
  .sppb-carousel-pro.full-width {
    left: -18.36806vw;
    width: 100vw; } }

.sppb-section .sppb-row > * {
  order: 999; }

@media all and (min-width: 0px) and (max-width: 767px) {
  .sppb-section.resp-hor-order-case-preview .sppb-row > :nth-child(3) {
    order: 0; }
  .sppb-section.resp-hor-order-vacancies .sppb-row > :nth-child(2) {
    order: 1000;
    margin-top: 56px; }
  .sppb-section.resp-hor-order-vacancies .sppb-row > :nth-child(6) {
    order: 1001; }
  .sppb-section.resp-hor-order-product-block .sppb-row > :nth-child(1) {
    order: 0; }
  .sppb-section.resp-hor-order-product-block .sppb-row > :nth-child(3) {
    order: 1; }
  .sppb-section.resp-hor-order-product-block .sppb-row .sppb-row .sppb-col-md-6:nth-child(2) {
    display: none; }
  .sppb-section.resp-hor-order-sales-trigger .sppb-row > * {
    position: relative; }
  .sppb-section.resp-hor-order-sales-trigger .sppb-row > :nth-child(1),
  .sppb-section.resp-hor-order-sales-trigger .sppb-row > :nth-child(4) {
    display: none; }
  .sppb-section.resp-hor-order-sales-trigger .sppb-row > :nth-child(3) .sppb-addon-spacer.sales-trigger {
    height: 8px; }
  .sppb-section.resp-hor-order-sales-trigger .sppb-row > :nth-child(3) {
    order: 0;
    top: -24px;
    margin-bottom: -24px; }
  .sppb-section.resp-hor-order-animation-shape-left .sppb-row > :nth-child(1) {
    order: 0; }
  .sppb-section.resp-hor-order-animation-shape-left .sppb-row > :nth-child(4) {
    order: 1; }
    .sppb-section.resp-hor-order-animation-shape-left .sppb-row > :nth-child(4) .sppb-column-addons {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap; }
      .sppb-section.resp-hor-order-animation-shape-left .sppb-row > :nth-child(4) .sppb-column-addons > :nth-child(1) {
        order: 3; }
      .sppb-section.resp-hor-order-animation-shape-left .sppb-row > :nth-child(4) .sppb-column-addons > :nth-child(2) {
        order: 0; }
      .sppb-section.resp-hor-order-animation-shape-left .sppb-row > :nth-child(4) .sppb-column-addons > :nth-child(3) {
        order: 1; }
  .sppb-section.resp-hor-order-contact-office-img-left .sppb-row > :nth-child(1) {
    order: 1; }
  .sppb-section.resp-hor-order-contact-office-img-left .sppb-row > :nth-child(4) {
    order: 3; }
    .sppb-section.resp-hor-order-contact-office-img-left .sppb-row > :nth-child(4) .sppb-column-addons {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap; }
      .sppb-section.resp-hor-order-contact-office-img-left .sppb-row > :nth-child(4) .sppb-column-addons .addon-cntr:nth-child(1) {
        order: 999; }
  .sppb-section.resp-hor-order-contact-office-img-right .sppb-row > :nth-child(2) .sppb-column-addons {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap; }
    .sppb-section.resp-hor-order-contact-office-img-right .sppb-row > :nth-child(2) .sppb-column-addons .addon-cntr:nth-child(1) {
      order: 999; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .sppb-section.resp-hor-order- {
    /*&sales-trigger {
                .sppb-row {

                    >* {
                        // margin-top: -50px;
                        position: relative;
                        // top: -100px;
                    }

                    > :nth-child(1),
                    > :nth-child(4) {
                        display: none;
                    }
                    > :nth-child(3) {
                        .sppb-addon-spacer.sales-trigger {
                            height: map-get($site-vert-grid, $breakpoint);
                        }
                    }

                    > :nth-child(3) {
                        $top-position-outside: map-get($site-vert-grid, $breakpoint)*3;
                        order: 0;
                        top: -$top-position-outside;
                        margin-bottom: -$top-position-outside;
                    }
                }
            }*/
    /*
            &random-facts {
                .sppb-row {
                    > :nth-child(2),
                    > :nth-child(3),
                    > :nth-child(4) {
                        width: 100%;

                    }
                }
            }
            */ } }

@media all and (min-width: 0px) and (max-width: 767px) {
  .sppb-section.resp-columns-vacancies .sppb-row > div {
    margin-left: auto;
    margin-right: auto;
    width: 83.33333%; }
  .sppb-section.resp-columns-img-shaped-with-icon .sppb-row > div:nth-child(2) .sppb-section.resp-columns-img-shaped-with-icon .sppb-row > div:nth-child(4) {
    width: 100%; }
  .sppb-section.resp-columns-img-shaped-with-icon .sppb-row > div:nth-child(3) {
    display: none; }
  .sppb-section.resp-columns-img-bgshape-and-icon .sppb-row > div:nth-child(2) .sppb-section.resp-columns-img-bgshape-and-icon .sppb-row > div:nth-child(4) {
    width: 100%; }
  .sppb-section.resp-columns-img-bgshape-and-icon .sppb-row > div:nth-child(3) {
    display: none; } }

@media all and (min-width: 0px) and (max-width: 1023px) {
  .sppb-section.resp-columns-cta-img-txt-btn .sppb-row > div {
    width: 100%;
    max-width: 100%;
    flex: none; }
  .sppb-section.resp-columns-cta-img-txt-btn .sppb-row .sppb-addon-media {
    margin: 0 auto 0 auto; }
  .sppb-section.resp-columns-cta-img-txt-btn .sppb-row .sppb-button.right {
    text-align: center; }
  .sppb-section.resp-columns-cta-img-txt-btn .sppb-row .sppb-addon-text.block.center-vert {
    height: auto; }
    .sppb-section.resp-columns-cta-img-txt-btn .sppb-row .sppb-addon-text.block.center-vert .text {
      height: auto;
      display: block;
      text-align: center;
      margin-top: 18px;
      margin-bottom: 16px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .sppb-section.resp-columns-vacancies .sppb-row > div.sppb-col-md-6 {
    width: 41.66667%;
    max-width: 41.66667%; }
  .sppb-section.resp-columns-vacancies .sppb-row > div.sppb-col-md-4 {
    width: 50%;
    max-width: 50%; }
  .sppb-section.resp-columns-vacancies .sppb-row > div:nth-child(5) {
    display: none; }
  .sppb-section.resp-columns-usps-small .sppb-row .sppb-col-md-8 {
    width: 100%;
    flex: none;
    max-width: 100%; }
  .sppb-section.resp-columns-usps-small .sppb-row .sppb-col-md-2 {
    display: none; }
  .sppb-section.resp-columns-3-col-img .sppb-row .sppb-col-md-1 {
    display: none; }
  .sppb-section.resp-columns-3-col-img .sppb-row .sppb-col-md-5 {
    width: 50%;
    max-width: 50%;
    flex: none; }
  .sppb-section.resp-columns-cta-img-txt-btn .sppb-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .sppb-section.resp-columns-cta-img-txt-btn .sppb-row > div {
      width: 66.66667%;
      max-width: 66.66667%;
      flex: none; }
  .sppb-section.resp-columns-img-shaped-with-icon .sppb-row > div:nth-child(2) {
    width: 50%; }
  .sppb-section.resp-columns-img-shaped-with-icon .sppb-row > div:nth-child(3) {
    width: 8.33333%; }
  .sppb-section.resp-columns-img-shaped-with-icon .sppb-row > div:nth-child(4) {
    width: 41.66667%; }
  .sppb-section.resp-columns-img-bgshape-and-icon .sppb-row > div:nth-child(2) {
    width: 50%;
    max-width: 50%;
    flex: none; }
  .sppb-section.resp-columns-img-bgshape-and-icon .sppb-row > div:nth-child(3) {
    display: none; }
  .sppb-section.resp-columns-img-bgshape-and-icon .sppb-row > div:nth-child(4) {
    width: 50%;
    max-width: 50%;
    flex: none; }
  .sppb-section.resp-columns-product-presentation .sppb-row > div.sppb-col-md-1 {
    display: none; }
  .sppb-section.resp-columns-product-presentation .sppb-row > div.sppb-col-md-5 {
    width: 100%;
    max-width: 100%;
    flex: none;
    padding: none; }
  .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-1 {
    display: none; }
  .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-4 {
    width: 66%;
    max-width: none;
    flex: none;
    margin: 0 auto 30px auto; }
    .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-4 .text,
    .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-4 .sppb-text-center {
      text-align: center; }
      .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-4 .text p,
      .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-4 .sppb-text-center p {
        text-align: center !important; }
  .sppb-section.resp-columns-product-presentation-rows .sppb-row > div.sppb-col-md-7 {
    width: 100%;
    max-width: 100%;
    flex: none;
    padding: none; } }

@media all and (min-width: 1024px) {
  .resp-columns-cta-img-txt-btn .sppb-row .sppb-addon-media {
    margin-bottom: 0; }
  .resp-columns-cta-img-txt-btn .sppb-row .sppb-column-addons {
    height: 100%; }
    .resp-columns-cta-img-txt-btn .sppb-row .sppb-column-addons .addon-cntr {
      height: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      align-content: center; }
  .resp-columns-cta-img-txt-btn .sppb-row .sppb-button,
  .resp-columns-cta-img-txt-btn .sppb-row .image-cntr {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    align-content: center; } }

.sppb-addon-media {
  margin-bottom: 1.33333vw;
  text-align: center; }
  .sppb-addon-media .image {
    text-align: center; }
  .sppb-addon-media .img {
    display: inline; }
  .sppb-addon-media.full-width {
    margin-bottom: 0; }

.sppb-section.small {
  margin-bottom: 6.66667vw;
  background: none !important; }
  .sppb-section.small .sppb-container-inner {
    width: 64.44444vw;
    border-radius: 0;
    margin: 0 auto; }
  .sppb-section.small.bg-type-orange .sppb-container-inner {
    background: #FF6A13 !important; }

@media all and (min-width: 0px) {
  .sppb-section.small .sppb-container-inner {
    width: 64.44444vw;
    border-top-right-radius: 75px; }
    .sppb-section.small .sppb-container-inner .sppb-row {
      padding: 0 30px; } }

@media all and (min-width: 768px) {
  .sppb-section.small .sppb-container-inner {
    width: 64.44444vw;
    border-top-right-radius: 175px; }
    .sppb-section.small .sppb-container-inner .sppb-row {
      padding: 0 60px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .sppb-section.small .sppb-container-inner {
    width: 71.875vw; } }

@media all and (min-width: 1024px) {
  #sp-page-builder .sp-pagebuilder-section-inner > .sppb-section.small > .sppb-container-inner > .sppb-row,
  #sp-page-builder .page-content > .sppb-section.small > .sppb-container-inner > .sppb-row {
    padding: 0 3.33333vw; } }

.product-presentation .sppb-addon-block-header .header {
  text-align: left; }
  .product-presentation .sppb-addon-block-header .header.big-burple {
    text-align: center; }

.product-presentation .sppb-addon-text {
  text-align: left; }
  .product-presentation .sppb-addon-text .text {
    text-align: left; }

.product-presentation .sppb-col-md-5 .sp-pagebuilder-column,
.product-presentation .sppb-col-md-5 .sppb-column {
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px; }

@media all and (min-width: 0px) {
  .product-presentation .sppb-col-md-5 {
    margin-bottom: 56px; }
    .product-presentation .sppb-col-md-5 .sppb-addon-block-header .header {
      max-width: 60%; }
    .product-presentation .sppb-col-md-5 .sppb-addon-text {
      max-width: 80%; }
    .product-presentation .sppb-col-md-5 .sp-pagebuilder-column,
    .product-presentation .sppb-col-md-5 .sppb-column {
      border-top-right-radius: 29.01333vw !important;
      padding: 6.4vw; }
  .product-presentation .sppb-col-md-5:nth-child(7) {
    margin-bottom: 0vw; }
  .product-presentation .sppb-addon-media {
    position: relative; }
    .product-presentation .sppb-addon-media .image-cntr {
      position: relative;
      top: -13.22667vw;
      right: -6.4vw;
      margin-bottom: -13.22667vw;
      text-align: right;
      height: 100%; }
      .product-presentation .sppb-addon-media .image-cntr .img {
        width: 195px;
        height: 100%; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .product-presentation .sppb-row .sppb-column-addons {
    display: block; }
  .product-presentation .sppb-row .sppb-col-md-5 {
    position: relative; }
    .product-presentation .sppb-row .sppb-col-md-5 .sp-pagebuilder-column,
    .product-presentation .sppb-row .sppb-col-md-5 .sppb-column {
      border-radius: 4px;
      border-top-right-radius: 12.5vw !important;
      position: static;
      padding-left: 6.25vw;
      padding-right: 6.25vw;
      padding-top: 3.125vw;
      padding-bottom: 3.125vw; }
      .product-presentation .sppb-row .sppb-col-md-5 .sp-pagebuilder-column .sppb-column-addons,
      .product-presentation .sppb-row .sppb-col-md-5 .sppb-column .sppb-column-addons {
        position: static; }
    .product-presentation .sppb-row .sppb-col-md-5 .sppb-addon-button-wrap,
    .product-presentation .sppb-row .sppb-col-md-5 .sppb-addon-text,
    .product-presentation .sppb-row .sppb-col-md-5 .sppb-addon-block-header {
      width: 35.41667vw; }
  .product-presentation .sppb-row .sppb-addon-media .image-cntr {
    margin-bottom: 0;
    top: 0;
    margin-top: -10.41667vw;
    position: relative;
    right: -0.83333vw;
    margin-left: 0;
    width: 29.58333vw;
    /*padding-top: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(30px, map-get($site-widths-design, $breakpoint));*/ }
    .product-presentation .sppb-row .sppb-addon-media .image-cntr img {
      height: auto;
      /*min-height: 100%;*/
      width: 100%; }
  .product-presentation .sppb-row .sppb-addon-media {
    position: static;
    width: 0; } }

@media all and (min-width: 768px) {
  .product-presentation .sppb-col-md-5 {
    margin-bottom: 56px; }
    .product-presentation .sppb-col-md-5 .sppb-addon-block-header .header {
      max-width: 60%; }
    .product-presentation .sppb-col-md-5 .sppb-addon-text {
      max-width: 80%; }
    .product-presentation .sppb-col-md-5 .sp-pagebuilder-column,
    .product-presentation .sppb-col-md-5 .sppb-column {
      padding: 3.125vw; }
  .product-presentation .sppb-col-md-5:nth-child(6),
  .product-presentation .sppb-col-md-5:nth-child(7) {
    margin-bottom: 0; }
  .product-presentation .sppb-addon-media .image-cntr .img {
    width: 384px; } }

@media all and (min-width: 1024px) {
  .product-presentation .sppb-col-md-5 {
    margin-bottom: 0vw; }
    .product-presentation .sppb-col-md-5 .sppb-addon-block-header .header {
      max-width: 100%; }
    .product-presentation .sppb-col-md-5 .sppb-addon-text {
      max-width: 100%; }
    .product-presentation .sppb-col-md-5 .sp-pagebuilder-column,
    .product-presentation .sppb-col-md-5 .sppb-column {
      border-top-right-radius: 8.88889vw !important;
      padding: 3.33333vw; }
  .product-presentation .sppb-addon-media {
    position: relative; }
    .product-presentation .sppb-addon-media .image-cntr {
      position: relative;
      top: auto;
      right: -3.33333vw;
      margin-top: -6.66667vw;
      margin-bottom: 0;
      height: 100%; }
      .product-presentation .sppb-addon-media .image-cntr .img {
        position: static;
        width: 100%;
        height: 100%; } }

.sppb-section.random-facts {
  background: linear-gradient(to bottom, #330072 100px, #FFFFFF 100px) !important; }
  @media all and (min-width: 0px) {
    .sppb-section.random-facts {
      background: linear-gradient(to bottom, #330072 45%, #FFFFFF 45%) !important; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child):nth-child(2), .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child):nth-child(3) {
        margin-bottom: 64px; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) .sppb-addon.image {
        margin: 0; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) s
.sp-pagebuilder-column,
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) .sppb-column {
        height: auto;
        background-color: #FFFFFF;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08) !important;
        border-radius: 8px;
        padding: 40px 32px 48px;
        border-radius: 0.42667vw !important; } }
  @media all and (min-width: 768px) and (max-width: 1023px) {
    .sppb-section.random-facts {
      background: linear-gradient(to bottom, #330072 45%, #FFFFFF 45%) !important; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) .sp-pagebuilder-column,
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) .sppb-column {
        /*width: map-get($column-widths, $breakpoint)*6;*/
        margin: 0 auto; }
      .sppb-section.random-facts.sp-pagebuilder-section .sppb-row > *:not(:first-child):not(:last-child):nth-child(2), .sppb-section.random-facts.sp-pagebuilder-section .sppb-row > *:not(:first-child):not(:last-child):nth-child(3) {
        margin-bottom: 0; } }
  @media all and (min-width: 1024px) {
    .sppb-section.random-facts {
      background: linear-gradient(to bottom, #330072 16.88889vw, #FFFFFF 16.88889vw) !important; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) .sp-pagebuilder-column,
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child) .sppb-column {
        padding: 2.22222vw 1.77778vw 2.66667vw;
        border-radius: 0.11111vw; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child):nth-child(3) {
        margin-top: 2.22222vw; }
      .sppb-section.random-facts .sppb-row > *:not(:first-child):not(:last-child):nth-child(4) {
        margin-top: 4.44444vw; } }

@media all and (min-width: 0px) {
  .sppb-section.cases-overview {
    background: linear-gradient(to bottom, #f5f5f5 34vw, #FFFFFF 34vw) !important; } }

@media all and (min-width: 768px) {
  .sppb-section.cases-overview {
    background: linear-gradient(to bottom, #f5f5f5 12%, #FFFFFF 12%) !important; } }

@media all and (min-width: 1024px) {
  .sppb-section.cases-overview {
    background: linear-gradient(to bottom, #f5f5f5 11.11111vw, #FFFFFF 11.11111vw) !important; } }

.sppb-section.bg-type-burple .sppb-addon-big-text .header {
  color: #FFFFFF; }

.sppb-section.bg-type-orange .sppb-addon-big-text .header {
  color: #FFFFFF; }

.sppb-section.bg-type-white .sppb-addon-big-text .header {
  color: #330072; }

.sppb-section.bg-type-image .sppb-addon-big-text .header {
  color: #FFFFFF; }

.sppb-section .text p {
  text-align: inherit;
  color: inherit; }

.sppb-section .sppb-addon-block-name .text {
  color: #FF6A13; }

.sppb-section.bg-type-image.overlay .sppb-addon-block-name .text, .sppb-section.bg-type-grey .sppb-addon-block-name .text, .sppb-section.bg-type-burple .sppb-addon-block-name .text {
  color: #FF6A13; }

.sppb-section.bg-type-image.overlay .sppb-headertext a,
.sppb-section.bg-type-image.overlay .sppb-headertext .link,
.sppb-section.bg-type-image.overlay .sppb-headertext .txt, .sppb-section.bg-type-grey .sppb-headertext a,
.sppb-section.bg-type-grey .sppb-headertext .link,
.sppb-section.bg-type-grey .sppb-headertext .txt, .sppb-section.bg-type-burple .sppb-headertext a,
.sppb-section.bg-type-burple .sppb-headertext .link,
.sppb-section.bg-type-burple .sppb-headertext .txt {
  color: #c1aed2; }

.sppb-section.bg-type-image.overlay .sppb-addon-text .text, .sppb-section.bg-type-grey .sppb-addon-text .text, .sppb-section.bg-type-burple .sppb-addon-text .text {
  color: #c1aed2; }

.sppb-section.bg-type-image.overlay .sppb-addon-block-header .header, .sppb-section.bg-type-grey .sppb-addon-block-header .header, .sppb-section.bg-type-burple .sppb-addon-block-header .header {
  color: #FFFFFF; }

.sppb-section.bg-type-orange .sppb-addon-block-name .text {
  color: #FFFFFF; }

.sppb-section.bg-type-orange .sppb-addon-block-header .header {
  color: #FFFFFF; }

.sppb-section.bg-type-orange .sppb-addon-text .text {
  color: #FFFFFF; }

.sppb-section.bg-type-grey .sppb-addon-block-name .text, .sppb-section.bg-type-white .sppb-addon-block-name .text {
  color: #FF6A13; }

.sppb-section.bg-type-grey .sppb-addon-block-header .header, .sppb-section.bg-type-white .sppb-addon-block-header .header {
  color: #330072; }

.sppb-section.bg-type-grey .sppb-addon-text .text, .sppb-section.bg-type-white .sppb-addon-text .text {
  color: #222d39; }

.sppb-section.bg-type-image .sppb-addon-block-name .text {
  color: #FFFFFF; }

.sppb-section.bg-type-image .sppb-addon-block-header .header {
  color: #FFFFFF; }

.sppb-section.bg-type-image .sppb-addon-text .text {
  color: #c1aed2; }

@media all and (min-width: 768px) {
  .sppb-section.facts-text-only.bg-type-burple .sppb-addon-text .text {
    margin-bottom: 0; } }

@media all and (min-width: 0px) {
  .sppb-section.portfolio-wide-image .sppb-addon.sppb-addon-portfolio {
    margin-bottom: -34.13333vw; }
    .sppb-section.portfolio-wide-image .sppb-addon.sppb-addon-portfolio .addon-cntr {
      position: relative;
      z-index: 5; }
      .sppb-section.portfolio-wide-image .sppb-addon.sppb-addon-portfolio .addon-cntr .sppb-addon-media {
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08) !important; } }

@media all and (min-width: 768px) {
  .sppb-section.portfolio-wide-image .sppb-addon.sppb-addon-portfolio {
    margin-bottom: -15.83333vw; } }

@media all and (min-width: 1024px) {
  .sppb-section.portfolio-wide-image .sppb-addon.sppb-addon-portfolio {
    margin-bottom: -7.55556vw; } }

#sp-menu {
  width: 100%;
  height: auto; }
  #sp-menu .cls-1 {
    opacity: 1; }
  #sp-menu .sp-column {
    width: 100%; }
  #sp-menu .sp-megamenu-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    justify-content: start;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    justify-content: start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center; }
  #sp-menu .sp-module {
    display: inline-block; }
    #sp-menu .sp-module .menu {
      vertical-align: top;
      margin: 0; }
      #sp-menu .sp-module .menu li {
        height: 14px;
        padding: 0;
        margin: 0;
        vertical-align: text-top;
        line-height: 1; }
        #sp-menu .sp-module .menu li.active a {
          text-decoration: underline; }
      #sp-menu .sp-module .menu li a {
        font-size: 12px;
        color: #222222;
        line-height: 1; }
  #sp-menu .sp-dropdown {
    background-color: white; }

.sppb-addon-image-block .bg-container {
  background: #f5f5f5;
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  align-content: center; }
  .sppb-addon-image-block .bg-container .img {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    height: auto; }

@media all and (min-width: 0px) {
  .sppb-addon-image-block .bg-container {
    height: 46.93333vw; } }

@media all and (min-width: 768px) {
  .sppb-addon-image-block {
    text-align: center; }
    .sppb-addon-image-block .bg-container {
      height: 376px;
      height: 46.93333vw; } }

@media all and (min-width: 1024px) {
  .sppb-addon-image-block .bg-container {
    height: 20.88889vw; } }

@media all and (min-width: 0px) {
  .sppb-addon-client-logos .logos {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    flex-wrap: wrap; }
    .sppb-addon-client-logos .logos .content.img {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      height: 40px;
      margin-bottom: 32px; }
      .sppb-addon-client-logos .logos .content.img .img {
        display: block;
        height: 100%;
        width: 100%; }
    .sppb-addon-client-logos .logos.items5 .content.img {
      width: 40%; }
      .sppb-addon-client-logos .logos.items5 .content.img:not(:nth-child(2n + 2)) {
        margin-right: 20%; }
      .sppb-addon-client-logos .logos.items5 .content.img:nth-child(2n + 2), .sppb-addon-client-logos .logos.items5 .content.img:last-child {
        margin-right: 0; }
    .sppb-addon-client-logos .logos.items6 .content.img {
      width: 40%; }
      .sppb-addon-client-logos .logos.items6 .content.img:not(:nth-child(2n + 2)) {
        margin-right: 20%; }
      .sppb-addon-client-logos .logos.items6 .content.img:nth-child(2n + 2), .sppb-addon-client-logos .logos.items6 .content.img:last-child {
        margin-right: 0; } }

@media all and (min-width: 768px) {
  .sppb-addon-client-logos .logos .content.img {
    max-width: 25%;
    width: 25%;
    margin-bottom: 24px; }
  .sppb-addon-client-logos .logos.items5 .content.img {
    width: 20%; }
    .sppb-addon-client-logos .logos.items5 .content.img:not(:nth-child(4n + 4)) {
      margin-right: 6.66667%; }
    .sppb-addon-client-logos .logos.items5 .content.img:nth-child(4n + 4), .sppb-addon-client-logos .logos.items5 .content.img:last-child {
      margin-right: 0; }
  .sppb-addon-client-logos .logos.items6 .content.img {
    width: 20%; }
    .sppb-addon-client-logos .logos.items6 .content.img:not(:nth-child(4n + 4)) {
      margin-right: 6.66667%; }
    .sppb-addon-client-logos .logos.items6 .content.img:nth-child(4n + 4), .sppb-addon-client-logos .logos.items6 .content.img:last-child {
      margin-right: 0; } }

@media all and (min-width: 1024px) {
  .sppb-addon-client-logos .logos .content.img {
    height: 2.66667vw;
    margin-bottom: 1.77778vw;
    max-width: 25%; }
  .sppb-addon-client-logos .logos.items5 .content.img {
    width: 16%; }
    .sppb-addon-client-logos .logos.items5 .content.img:not(:nth-child(5n + 5)) {
      margin-right: 5%; }
    .sppb-addon-client-logos .logos.items5 .content.img:nth-child(5n + 5), .sppb-addon-client-logos .logos.items5 .content.img:last-child {
      margin-right: 0; }
  .sppb-addon-client-logos .logos.items6 .content.img {
    width: 13.33333%; }
    .sppb-addon-client-logos .logos.items6 .content.img:not(:nth-child(6n + 6)) {
      margin-right: 4%; }
    .sppb-addon-client-logos .logos.items6 .content.img:nth-child(6n + 6), .sppb-addon-client-logos .logos.items6 .content.img:last-child {
      margin-right: 0; } }

/*
#sp-page-builder{
    .sp-pagebuilder-column,
    .sp-pagebuilder-addon{
        position: static;
    }

}
*/
.sppb-col-md-4, .sppb-col-md-12 {
  min-height: 0 !important; }

.sppb-col-md-4 {
  /*position: static;*/ }
  .sppb-col-md-4 .sppb-column, .sppb-col-md-4 .sppb-column-addons {
    /*position: static;*/
    z-index: 2; }

.sppb-videobutton {
  position: absolute;
  bottom: -19.5px;
  left: 0;
  left: 50%;
  z-index: 21; }
  .sppb-videobutton .button-cntr {
    position: relative;
    left: -50%;
    display: block;
    z-index: 21; }
    .sppb-videobutton .button-cntr .btn-link:hover svg path[fill="#FF6A13"] {
      fill: #ff8842; }
    .sppb-videobutton .button-cntr .btn-link:hover svg .st0 {
      fill: #ff8842; }
    .sppb-videobutton .button-cntr .btn {
      width: 100%;
      height: auto;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      justify-items: center; }
      .sppb-videobutton .button-cntr .btn .txt {
        display: block;
        flex-shrink: 0;
        margin-bottom: 8px;
        text-align: center;
        min-height: 28px;
        color: rgba(255, 255, 255, 0.76); }
      .sppb-videobutton .button-cntr .btn .btn-image {
        display: block;
        flex-shrink: 0; }
        .sppb-videobutton .button-cntr .btn .btn-image svg {
          width: 100%;
          height: 100%; }

@media all and (min-width: 0px) {
  .sppb-videobutton {
    bottom: 68.5px; }
    .sppb-videobutton .btn-image {
      width: 105px;
      height: 63px; }
    .sppb-videobutton .btn .txt {
      font-size: 17px;
      line-height: 28px; } }

@media all and (min-width: 768px) {
  .sppb-videobutton {
    bottom: 26px; }
    .sppb-videobutton .btn-image {
      width: 140px;
      height: 84px; }
    .sppb-videobutton .btn .txt {
      font-size: 17px;
      line-height: 28px; } }

@media all and (min-width: 1024px) {
  .sppb-videobutton {
    bottom: -2.33333vw; }
    .sppb-videobutton .btn-image {
      width: 7.77778vw;
      height: 4.66667vw; }
    .sppb-videobutton .btn .txt {
      font-size: 20px;
      line-height: 32px; } }

@media all and (min-width: 0px) {
  .sppb-addon-form-builder .form-builder-label {
    font-size: 13px;
    color: #666666; }
  .sppb-addon-form-builder .form-image-cntr {
    max-width: 50%;
    margin: 0 auto; }
    .sppb-addon-form-builder .form-image-cntr.right {
      display: none; }
    .sppb-addon-form-builder .form-image-cntr svg {
      width: 100%;
      height: auto;
      display: block; }
  .sppb-addon-form-builder .sppb-form-group {
    margin-bottom: 16px; }
    .sppb-addon-form-builder .sppb-form-group .sppb-form-control:-ms-input-placeholder {
      color: #cccccc !important;
      font-size: 15px !important;
      line-height: 52px !important; }
    .sppb-addon-form-builder .sppb-form-group .sppb-form-control {
      border: none;
      color: #333333;
      font-size: 15px;
      border-radius: 4px;
      height: 52px;
      line-height: 52px;
      padding: 0 20px;
      background: #f5f5f5; }
      .sppb-addon-form-builder .sppb-form-group .sppb-form-control:focus {
        background: #f5f5f5; }
      .sppb-addon-form-builder .sppb-form-group .sppb-form-control::placeholder {
        color: #cccccc;
        font-size: 15px;
        line-height: 52px; }
      .sppb-addon-form-builder .sppb-form-group .sppb-form-control.input-textarea {
        height: auto;
        line-height: 23px; }
      .sppb-addon-form-builder .sppb-form-group .sppb-form-control.input-textarea {
        height: auto;
        padding-top: 23px;
        line-height: 23px; }
        .sppb-addon-form-builder .sppb-form-group .sppb-form-control.input-textarea::placeholder {
          line-height: 23px; }
    .sppb-addon-form-builder .sppb-form-group .form-builder-checkbox-item label::before, .sppb-addon-form-builder .sppb-form-group .form-builder-radio-item label::before, .sppb-addon-form-builder .sppb-form-group .sppb-addon-form-builder .sppb-form-check-label::before {
      border-color: #cccccc;
      box-shadow: inset 0px 0px 0px 2px #fff;
      height: 21px;
      width: 21px;
      top: 0;
      left: 0; }
    .sppb-addon-form-builder .sppb-form-group .form-builder-checkbox-item input:checked + label::before, .sppb-addon-form-builder .sppb-form-group .form-builder-radio-item input:checked + label::before, .sppb-addon-form-builder .sppb-form-group .sppb-addon-form-builder .sppb-form-check-input:checked + label::before {
      background: #330072; }
    .sppb-addon-form-builder .sppb-form-group .form-builder-checkbox-item label::after, .sppb-addon-form-builder .sppb-form-group .sppb-addon-form-builder .sppb-form-check-label::after {
      /*color: map-get($colors, checkbox-mark);
                top: 0;
                left: 0;*/
      content: ''; }
    .sppb-addon-form-builder .sppb-form-group .form-builder-checkbox-content .form-builder-label,
    .sppb-addon-form-builder .sppb-form-group .form-builder-radio-content .form-builder-label {
      height: 23px;
      line-height: 23px;
      display: block;
      margin-left: 30px; }
  .sppb-addon-form-builder .sppb-form-builder-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .sppb-addon-form-builder .sppb-form-builder-btn.sppb-text-right {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
    .sppb-addon-form-builder .sppb-form-builder-btn.sppb-text-left {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center; } }

@media all and (min-width: 1024px) {
  .sppb-addon-form-builder .form-image-cntr {
    width: 22.11111vw;
    height: 16vw;
    /* width: 398px;
            height: 288px;*/
    position: absolute;
    left: -7.77778vw;
    top: 1.33333vw;
    z-index: 1; }
    .sppb-addon-form-builder .form-image-cntr.right {
      left: auto;
      display: block;
      right: -7.77778vw;
      top: 10.66667vw; }
    .sppb-addon-form-builder .form-image-cntr .img,
    .sppb-addon-form-builder .form-image-cntr svg {
      width: 100%; }
  .sppb-addon-form-builder.image-cntr {
    z-index: 1;
    position: relative;
    background: none; }
  .sppb-addon-form-builder .form-builder-label {
    font-size: 0.72222vw; }
  .sppb-addon-form-builder .sppb-form-group .sppb-form-control {
    font-size: 0.83333vw;
    border-radius: 0.22222vw;
    height: 2.88889vw;
    line-height: 2.88889vw;
    padding-left: 1.11111vw;
    padding-right: 1.11111vw; }
    .sppb-addon-form-builder .sppb-form-group .sppb-form-control::placeholder {
      font-size: 0.83333vw;
      line-height: 2.88889vw; }
    .sppb-addon-form-builder .sppb-form-group .sppb-form-control.input-textarea {
      height: auto;
      padding-top: 0.72222vw;
      line-height: 1.27778vw; }
      .sppb-addon-form-builder .sppb-form-group .sppb-form-control.input-textarea::placeholder {
        line-height: 1.27778vw; }
  .sppb-addon-form-builder .sppb-form-group .form-builder-checkbox-item label::before, .sppb-addon-form-builder .sppb-form-group .form-builder-radio-item label::before, .sppb-addon-form-builder .sppb-form-group .sppb-addon-form-builder .sppb-form-check-label::before {
    height: 1.16667vw;
    width: 1.16667vw; }
  .sppb-addon-form-builder .sppb-form-group .form-builder-checkbox-content .form-builder-label,
  .sppb-addon-form-builder .sppb-form-group .form-builder-radio-content .form-builder-label {
    height: 1.27778vw;
    line-height: 1.27778vw;
    display: block;
    margin-left: 1.66667vw; }
  .sppb-addon-form-builder .sppb-form-builder-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-left: 1.11111vw; }
    .sppb-addon-form-builder .sppb-form-builder-btn.sppb-text-right {
      -webkit-box-pack: flex-end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end; }
    .sppb-addon-form-builder .sppb-form-builder-btn.sppb-text-left {
      -webkit-box-pack: flex-start;
      -webkit-justify-content: flex-start;
      justify-content: flex-start; } }

@media all and (min-width: 0px) and (max-width: 767px) {
  #sp-main-body .sppb-addon-content .sppb-addon-form-builder-form .sppb-form-group {
    padding: 0; } }

@media all and (min-width: 0px) {
  .sppb-addon-contact-form-builder {
    background: #FFFFFF;
    position: relative;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08);
    padding: 5.44vw; }
    .sppb-addon-contact-form-builder .sppb-addon-form-builder-form > div {
      max-width: 100%; }
    .sppb-addon-contact-form-builder .grecaptcha-badge {
      margin-bottom: 16px;
      max-width: 100%;
      overflow: hidden;
      box-shadow: none !important;
      /*margin-left: map-get($column-gutter, $breakpoint );*/ }
    .sppb-addon-contact-form-builder .form-builder-radio-item {
      display: block;
      margin-bottom: 8px; }
    .sppb-addon-contact-form-builder .heading {
      font-size: 28px;
      color: #330072;
      text-align: center;
      margin-bottom: 40px; } }

@media all and (min-width: 0px) {
  .sppb-addon-contact-form-builder {
    background: #FFFFFF;
    position: relative;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08);
    padding: 5.44vw; }
    .sppb-addon-contact-form-builder .sppb-addon-form-builder-form > div {
      max-width: 100%; }
    .sppb-addon-contact-form-builder .grecaptcha-badge {
      margin-bottom: 16px;
      max-width: 100%;
      overflow: hidden;
      box-shadow: none !important;
      /*margin-left: map-get($column-gutter, $breakpoint );*/ }
    .sppb-addon-contact-form-builder .form-builder-radio-item {
      display: block;
      margin-bottom: 8px; }
    .sppb-addon-contact-form-builder .heading {
      font-size: 28px;
      color: #330072;
      text-align: center;
      margin-bottom: 40px; } }

@media all and (min-width: 1024px) {
  .sppb-addon-contact-form-builder {
    border-radius: 0.22222vw;
    padding: 4.44444vw;
    /*padding-left: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));
        padding-right: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));
        padding-top: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));
        padding-bottom: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));*/ }
    .sppb-addon-contact-form-builder .grecaptcha-badge {
      margin-bottom: 16px;
      margin-left: 1.11111vw; }
    .sppb-addon-contact-form-builder .heading {
      font-size: 2.33333vw;
      margin-bottom: 2.22222vw; }
    .sppb-addon-contact-form-builder .sppb-form-builder-btn {
      margin-top: 0.88889vw; }
    .sppb-addon-contact-form-builder .grecaptcha-badge {
      margin-bottom: 16px;
      margin-left: 1.11111vw; } }

@media all and (min-width: 0px) and (max-width: 1023px) {
  .sppb-addon-contact-form-builder .form-builder-radio-item {
    margin-bottom: 16px; } }

@media all and (min-width: 0px) {
  .sppb-section.bg-type-burple.half-white {
    background: linear-gradient(to top, #FFFFFF 80px, #330072 1px) !important; } }

@media all and (min-width: 1024px) {
  .sppb-section.bg-type-burple.half-white {
    background: linear-gradient(to top, #FFFFFF 224px, #330072 1px) !important; } }

@media all and (min-width: 0px) {
  .sppb-col-md-1 .sppb-addon-contact-form-builder .heading,
  .sppb-col-md-2 .sppb-addon-contact-form-builder .heading,
  .sppb-col-md-3 .sppb-addon-contact-form-builder .heading,
  .sppb-col-md-4 .sppb-addon-contact-form-builder .heading,
  .sppb-col-md-5 .sppb-addon-contact-form-builder .heading,
  .sppb-col-md-6 .sppb-addon-contact-form-builder .heading {
    font-size: 28px; } }

@media all and (min-width: 1024px) {
  .sppb-col-md-1 .sppb-addon-contact-form-builder,
  .sppb-col-md-2 .sppb-addon-contact-form-builder,
  .sppb-col-md-3 .sppb-addon-contact-form-builder,
  .sppb-col-md-4 .sppb-addon-contact-form-builder,
  .sppb-col-md-5 .sppb-addon-contact-form-builder,
  .sppb-col-md-6 .sppb-addon-contact-form-builder {
    padding: 2.22222vw; }
    .sppb-col-md-1 .sppb-addon-contact-form-builder .sppb-form-builder-btn,
    .sppb-col-md-2 .sppb-addon-contact-form-builder .sppb-form-builder-btn,
    .sppb-col-md-3 .sppb-addon-contact-form-builder .sppb-form-builder-btn,
    .sppb-col-md-4 .sppb-addon-contact-form-builder .sppb-form-builder-btn,
    .sppb-col-md-5 .sppb-addon-contact-form-builder .sppb-form-builder-btn,
    .sppb-col-md-6 .sppb-addon-contact-form-builder .sppb-form-builder-btn {
      padding: 0 1.11111vw; }
    .sppb-col-md-1 .sppb-addon-contact-form-builder .heading,
    .sppb-col-md-2 .sppb-addon-contact-form-builder .heading,
    .sppb-col-md-3 .sppb-addon-contact-form-builder .heading,
    .sppb-col-md-4 .sppb-addon-contact-form-builder .heading,
    .sppb-col-md-5 .sppb-addon-contact-form-builder .heading,
    .sppb-col-md-6 .sppb-addon-contact-form-builder .heading {
      font-size: 1.66667vw;
      line-height: 2.22222vw; } }

/* fix for captcha */
@media all and (min-width: 0px) and (max-width: 767px) {
  #sp-main-body .sppb-section .sppb-form-builder-recaptcha {
    padding: 0;
    margin: 0; } }

@media (max-width: 410px) {
  #sp-main-body .sppb-section .sppb-form-builder-recaptcha {
    padding: 0;
    transform: scale(0.8);
    transform-origin: 0 0; } }

@media (max-width: 330px) {
  #sp-main-body .sppb-section .sppb-form-builder-recaptcha {
    padding: 0;
    transform: scale(0.76);
    transform-origin: 0 0; } }

.sppb-addon-quote {
  text-align: left; }
  .sppb-addon-quote.case-preview {
    margin-bottom: 40px; }
    .sppb-addon-quote.case-preview .sppb-addon-quote-cntr p {
      font-size: inherit;
      line-height: inherit; }
  .sppb-addon-quote .sppb-addon-title,
  .sppb-addon-quote .sppb-addon-quote-cntr {
    color: #330072;
    display: block;
    text-align: left;
    quotes: "“" "”" "‘" "’"; }
    .sppb-addon-quote .sppb-addon-title:before,
    .sppb-addon-quote .sppb-addon-quote-cntr:before {
      content: open-quote; }
    .sppb-addon-quote .sppb-addon-title:after,
    .sppb-addon-quote .sppb-addon-quote-cntr:after {
      content: close-quote; }
    .sppb-addon-quote .sppb-addon-title p:first-child,
    .sppb-addon-quote .sppb-addon-quote-cntr p:first-child {
      display: inline; }
    .sppb-addon-quote .sppb-addon-title p:last-child,
    .sppb-addon-quote .sppb-addon-quote-cntr p:last-child {
      display: inline; }
  .sppb-addon-quote .author {
    color: #222d39;
    text-align: left;
    font-weight: bold; }
  .sppb-addon-quote .title {
    color: #222d39;
    text-align: left; }
  @media all and (min-width: 0px) {
    .sppb-addon-quote .sppb-addon-quote-cntr {
      margin-bottom: 24px;
      font-size: 21px;
      line-height: 28px; }
    .sppb-addon-quote .text,
    .sppb-addon-quote .author,
    .sppb-addon-quote .title {
      margin-right: 40px; }
    .sppb-addon-quote .author {
      font-size: 17px;
      line-height: 24px; }
    .sppb-addon-quote .title {
      font-size: 17px;
      line-height: 24px; } }
  @media all and (min-width: 768px) {
    .sppb-addon-quote.case-preview .sppb-addon-quote-cntr {
      font-size: 2.1875vw;
      line-height: 2.5vw; }
    .sppb-addon-quote .sppb-addon-quote-cntr {
      margin-bottom: 24px;
      font-size: 2.1875vw;
      line-height: 2.91667vw; }
    .sppb-addon-quote .text,
    .sppb-addon-quote .author,
    .sppb-addon-quote .title {
      margin-right: 0; }
    .sppb-addon-quote .author {
      font-size: 17px;
      line-height: 24px; }
    .sppb-addon-quote .title {
      font-size: 17px;
      line-height: 24px; } }
  @media all and (min-width: 1024px) {
    .sppb-addon-quote.case-preview {
      margin-bottom: 2.22222vw; }
      .sppb-addon-quote.case-preview .sppb-addon-quote-cntr {
        font-size: 1.66667vw;
        line-height: 2.22222vw; }
    .sppb-addon-quote .sppb-addon-quote-cntr {
      margin-bottom: 1.33333vw;
      font-size: 1.66667vw;
      line-height: 2.22222vw; }
    .sppb-addon-quote .author {
      font-size: 1.11111vw;
      line-height: 1.77778vw; }
    .sppb-addon-quote .title {
      font-size: 1.11111vw;
      line-height: 1.77778vw; } }

.sppb-addon-block-header .quote {
  display: block;
  text-align: left;
  quotes: "“" "”" "‘" "’"; }
  .sppb-addon-block-header .quote:before {
    content: open-quote; }
  .sppb-addon-block-header .quote:after {
    content: close-quote; }
  .sppb-addon-block-header .quote p:first-child {
    display: inline; }
  .sppb-addon-block-header .quote p:last-child {
    display: inline; }

@media all and (min-width: 0px) {
  .repeatable-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%; }
    .repeatable-btn .sppb-button {
      margin-left: 3px;
      margin-right: 3px;
      margin-bottom: 16px; } }

@media all and (min-width: 768px) {
  .repeatable-btn .sppb-button {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 0; }
    .repeatable-btn .sppb-button:first-child {
      margin-left: 0; }
    .repeatable-btn .sppb-button:last-child {
      margin-right: 0; } }

@media all and (min-width: 1024px) {
  .repeatable-btn.align-right {
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .repeatable-btn.align-left {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .repeatable-btn.align-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
  .repeatable-btn .sppb-button {
    margin-left: 1.11111vw;
    margin-right: 1.11111vw; } }

.sppb-addon-products {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  flex-wrap: wrap; }
  .sppb-addon-products .product-cntr {
    align-self: center;
    background: #FFFFFF;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    border-radius: 8px;
    box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.08); }
    .sppb-addon-products .product-cntr:first-child {
      align-self: flex-start; }
    .sppb-addon-products .product-cntr:last-child {
      align-self: flex-end; }
    .sppb-addon-products .product-cntr .img-cntr {
      position: relative; }
      .sppb-addon-products .product-cntr .img-cntr .img {
        position: static;
        height: auto;
        width: 100%;
        left: 0; }
    .sppb-addon-products .product-cntr .content-cntr {
      box-sizing: content-box; }
      .sppb-addon-products .product-cntr .content-cntr .heading {
        text-align: left; }
      .sppb-addon-products .product-cntr .content-cntr .txt {
        text-align: left; }
        .sppb-addon-products .product-cntr .content-cntr .txt p {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit; }
  @media all and (min-width: 0px) {
    .sppb-addon-products .product-cntr {
      margin-bottom: 24px;
      display: block;
      width: 100%; }
      .sppb-addon-products .product-cntr .img-cntr {
        text-align: center;
        width: 100%; }
        .sppb-addon-products .product-cntr .img-cntr .img {
          position: static;
          height: auto;
          display: inline-block;
          width: 39.25333vw; }
      .sppb-addon-products .product-cntr .txt {
        font-size: 16px;
        line-height: 27px; }
    .sppb-addon-products .content-cntr {
      padding: 40px; } }
  @media all and (min-width: 768px) {
    .sppb-addon-products .product-cntr {
      margin-bottom: 24px;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      width: 58.33333vw;
      position: relative; }
      .sppb-addon-products .product-cntr .img-cntr {
        width: 19.16667vw;
        height: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0; }
        .sppb-addon-products .product-cntr .img-cntr .img {
          width: 100%;
          height: auto;
          position: absolute;
          right: 0;
          bottom: 0; }
      .sppb-addon-products .product-cntr .txt {
        font-size: 17px;
        line-height: 28px; }
    .sppb-addon-products .content-cntr {
      padding-left: 4.16667vw;
      padding-top: 4.16667vw;
      padding-right: 4.16667vw;
      padding-bottom: 3.33333vw;
      max-width: 33.33333vw; } }
  @media all and (min-width: 1024px) {
    .sppb-addon-products .product-cntr {
      margin-bottom: 1.33333vw;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap;
      width: 31.11111vw; }
      .sppb-addon-products .product-cntr .img-cntr {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10.22222vw;
        height: 9.33333vw; }
        .sppb-addon-products .product-cntr .img-cntr .img {
          width: 100%;
          position: absolute;
          right: 0;
          bottom: 0; }
      .sppb-addon-products .product-cntr .txt {
        font-size: 0.94444vw;
        line-height: 1.55556vw; }
    .sppb-addon-products .content-cntr {
      padding-left: 2.22222vw;
      padding-top: 2.22222vw;
      padding-right: 2.22222vw;
      padding-bottom: 1.77778vw;
      max-width: 17.77778vw; }
    .sppb-addon-products.spread {
      min-height: 31.66667vw;
      /*justify-content: space-between;*/ }
      .sppb-addon-products.spread .product-cntr {
        margin-top: 3.33333vw;
        margin-bottom: 3.33333vw; }
      .sppb-addon-products.spread .product-cntr:last-child {
        align-self: center; } }

@media all and (min-width: 0px) {
  .products-bg-shape {
    z-index: -1;
    position: absolute;
    width: 100%;
    min-height: 153.6vw;
    display: none;
    top: -11.94667vw;
    height: 100%;
    background: #f5f5f5;
    right: -30px;
    border-bottom-left-radius: 34.13333vw; } }

@media all and (min-width: 768px) {
  .products-bg-shape {
    display: none;
    min-height: 75vw;
    top: -5.83333vw;
    right: -60px;
    border-bottom-left-radius: 16.66667vw; } }

@media all and (min-width: 1024px) {
  .products-bg-shape {
    display: block;
    min-height: 37.77778vw;
    top: -3.11111vw;
    right: -18.36806vw;
    border-bottom-left-radius: 8.88889vw; } }

@media all and (min-width: 0px) {
  .sppb-addon-portfolio .sppb-tab .sppb-nav-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: none;
    margin-bottom: 8.53333vw; }
  .sppb-addon-portfolio .sppb-tab .tab-cntr {
    float: none;
    background: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 21.33333vw;
    height: 21.33333vw;
    margin-left: 1.06667vw;
    margin-right: 1.06667vw;
    border-radius: 1.70667vw;
    border-bottom-left-radius: 5.12vw; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img {
      opacity: 1; }
      .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img path {
        fill: #FFFFFF; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr.active {
      background: #FFFFFF;
      box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.1); }
      .sppb-addon-portfolio .sppb-tab .tab-cntr.active .icon.img {
        opacity: 1; }
        .sppb-addon-portfolio .sppb-tab .tab-cntr.active .icon.img path {
          fill: #330072; }
      .sppb-addon-portfolio .sppb-tab .tab-cntr.active .title {
        opacity: 1;
        color: #222d39; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img {
      display: inline-block;
      text-align: center;
      margin-bottom: 1.70667vw;
      opacity: 0.4;
      height: 6.82667vw;
      width: 6.82667vw; }
      .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img img,
      .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img svg {
        max-width: 100%;
        max-height: 100%; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .tablink {
      background: none;
      border: none;
      text-align: center;
      line-height: 1;
      text-decoration: none;
      font-weight: normal;
      padding: 0 !important;
      cursor: pointer; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .title {
      color: #FFFFFF;
      color: green;
      min-height: 30px;
      opacity: 0.3;
      font-size: 15px; }
  .sppb-addon-portfolio .sppb-tab-panel {
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    display: none; }
    .sppb-addon-portfolio .sppb-tab-panel.active {
      display: block; }
  .sppb-addon-portfolio .sppb-addon-media {
    background: #FFFFFF;
    margin-bottom: 8.53333vw;
    border-top-left-radius: 1.70667vw;
    border-top-right-radius: 1.70667vw; }
    .sppb-addon-portfolio .sppb-addon-media .img {
      border-top-left-radius: 1.70667vw;
      border-top-right-radius: 1.70667vw; }
    .sppb-addon-portfolio .sppb-addon-media .image-cntr {
      position: relative; }
      .sppb-addon-portfolio .sppb-addon-media .image-cntr .sppb-modal-selector {
        width: 100%; }
      .sppb-addon-portfolio .sppb-addon-media .image-cntr .img-link {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap; }
      .sppb-addon-portfolio .sppb-addon-media .image-cntr .st0 {
        fill: #330072; }
      .sppb-addon-portfolio .sppb-addon-media .image-cntr:hover .st0 {
        fill: #5c338f; }
    .sppb-addon-portfolio .sppb-addon-media .button.img {
      position: absolute;
      height: 8.96vw;
      width: 14.93333vw;
      left: 50%;
      top: 50%; }
      .sppb-addon-portfolio .sppb-addon-media .button.img img,
      .sppb-addon-portfolio .sppb-addon-media .button.img svg {
        position: relative;
        top: -50%;
        left: -50%;
        max-width: 100%;
        height: 100%;
        max-height: 100%; }
    .sppb-addon-portfolio .sppb-addon-media .content-cntr {
      padding-left: 6.4vw;
      padding-right: 6.4vw;
      padding-top: 5.12vw;
      padding-bottom: 5.12vw; }
    .sppb-addon-portfolio .sppb-addon-media .title {
      text-align: left;
      font-weight: bold;
      font-size: 23px;
      line-height: 32px;
      color: #330072; }
      .sppb-addon-portfolio .sppb-addon-media .title p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit; }
    .sppb-addon-portfolio .sppb-addon-media .client {
      text-align: left;
      font-size: 17px;
      line-height: 28px;
      color: #666666; }
  .sppb-addon-portfolio .addon-cntr {
    flex-shrink: 1;
    width: 100%;
    margin-bottom: 8.53333vw; } }

@media all and (min-width: 768px) {
  .sppb-addon-portfolio .sppb-tab .sppb-nav-tabs {
    margin-bottom: 4.16667vw;
    justify-content: center; }
  .sppb-addon-portfolio .sppb-tab .tab-cntr {
    width: 11.25vw;
    height: 11.25vw;
    border-radius: 0.83333vw;
    border-bottom-left-radius: 2.5vw; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img {
      margin-bottom: 0.83333vw;
      height: 3.125vw;
      width: 3.125vw; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .title {
      font-size: 1.45833vw;
      min-height: 3.33333vw; }
  .sppb-addon-portfolio .sppb-tab-panel {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    .sppb-addon-portfolio .sppb-tab-panel.active {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap; }
  .sppb-addon-portfolio .sppb-addon-media {
    margin-bottom: 4.16667vw;
    border-top-left-radius: 0.83333vw;
    border-top-right-radius: 0.83333vw; }
    .sppb-addon-portfolio .sppb-addon-media .img {
      border-top-left-radius: 0.83333vw;
      border-top-right-radius: 0.83333vw; }
    .sppb-addon-portfolio .sppb-addon-media .button.img {
      height: 4.375vw;
      width: 7.29167vw;
      left: 50%; }
    .sppb-addon-portfolio .sppb-addon-media .content-cntr {
      padding-left: 3.125vw;
      padding-right: 3.125vw;
      padding-top: 2.5vw;
      padding-bottom: 2.5vw; }
    .sppb-addon-portfolio .sppb-addon-media .title {
      font-size: 2.39583vw;
      line-height: 3.33333vw; }
    .sppb-addon-portfolio .sppb-addon-media .client {
      font-size: 1.77083vw;
      line-height: 2.91667vw; }
  .sppb-addon-portfolio .addon-cntr {
    width: 27.08333vw;
    margin-bottom: 0; }
  .sppb-addon-portfolio .addon-cntr:nth-child(3n+2) {
    margin-top: 4.16667vw;
    margin-bottom: -4.16667vw; }
  .sppb-addon-portfolio .addon-cntr:nth-child(3n+3) {
    margin-top: 8.33333vw;
    margin-bottom: -8.33333vw; } }

@media all and (min-width: 1024px) {
  .sppb-addon-portfolio .sppb-tab .sppb-nav-tabs {
    margin-bottom: 5.77778vw;
    justify-content: center; }
  .sppb-addon-portfolio .sppb-tab .tab-cntr {
    width: 8.88889vw;
    height: 8.88889vw;
    margin-left: 1.11111vw;
    margin-right: 1.11111vw;
    border-radius: 0.44444vw;
    border-bottom-left-radius: 1.33333vw; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .icon.img {
      margin-bottom: 0.44444vw;
      height: 2.88889vw;
      width: 2.88889vw; }
    .sppb-addon-portfolio .sppb-tab .tab-cntr .title {
      font-size: 1.11111vw;
      min-height: 2vw; }
  .sppb-addon-portfolio .sppb-tab-panel {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    display: none; }
    .sppb-addon-portfolio .sppb-tab-panel.active {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      /* Safari 6.1+ */
      flex-wrap: wrap; }
  .sppb-addon-portfolio .sppb-addon-media {
    margin-bottom: 2.22222vw;
    border-top-left-radius: 0.44444vw;
    border-top-right-radius: 0.44444vw; }
    .sppb-addon-portfolio .sppb-addon-media .img {
      border-top-left-radius: 0.44444vw;
      border-top-right-radius: 0.44444vw; }
    .sppb-addon-portfolio .sppb-addon-media .button.img {
      height: 2.33333vw;
      width: 3.88889vw;
      left: 50%; }
    .sppb-addon-portfolio .sppb-addon-media .content-cntr {
      padding-left: 1.66667vw;
      padding-right: 1.66667vw;
      padding-top: 1.33333vw;
      padding-bottom: 1.33333vw; }
    .sppb-addon-portfolio .sppb-addon-media .title {
      font-size: 1.27778vw;
      line-height: 1.77778vw; }
    .sppb-addon-portfolio .sppb-addon-media .client {
      font-size: 0.94444vw;
      line-height: 1.55556vw; }
  .sppb-addon-portfolio .addon-cntr {
    width: 20vw;
    margin-bottom: 0; }
  .sppb-addon-portfolio .addon-cntr:nth-child(3n+2) {
    margin-top: 2.22222vw;
    margin-bottom: -2.22222vw; }
  .sppb-addon-portfolio .addon-cntr:nth-child(3n+3) {
    margin-top: 4.44444vw;
    margin-bottom: -4.44444vw; } }

.sppb-section.bg-type-grey .sppb-tab .tab-cntr .icon.img path {
  fill: #330072; }

.sppb-section.bg-type-grey .sppb-tab .tab-cntr .title {
  color: #330072; }

@media all and (min-width: 0px) {
  .sppb-addon-team-members .animation-cntr {
    display: none; }
  .sppb-addon-team-members .loadmore-cntr {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .sppb-addon-team-members .loadmore-cntr .btn {
      text-align: center; }
      .sppb-addon-team-members .loadmore-cntr .btn .txt {
        text-align: center; }
    .sppb-addon-team-members .loadmore-cntr .clicked {
      display: none; }
  .sppb-addon-team-members .notloaded {
    display: none; }
  .sppb-addon-team-members .addon-cntr.filler {
    display: none; }
  .sppb-addon-team-members .sppb-addon-members {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 1.70667vw;
    border-bottom-right-radius: 5.12vw;
    overflow: hidden;
    margin-bottom: 6.82667vw; }
    .sppb-addon-team-members .sppb-addon-members .img-cntr {
      height: auto;
      width: 100%;
      position: relative;
      text-align: center;
      z-index: 9; }
      .sppb-addon-team-members .sppb-addon-members .img-cntr .img {
        height: auto;
        width: 100%; }
    .sppb-addon-team-members .sppb-addon-members .content-cntr {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 11;
      padding-left: 5.12vw;
      padding-right: 5.12vw;
      padding-bottom: 3.41333vw; }
      .sppb-addon-team-members .sppb-addon-members .content-cntr .txt {
        color: #FFFFFF;
        text-align: left; }
        .sppb-addon-team-members .sppb-addon-members .content-cntr .txt.title {
          font-size: 3.62667vw;
          line-height: 5.97333vw; }
        .sppb-addon-team-members .sppb-addon-members .content-cntr .txt.name {
          font-size: 6.4vw;
          line-height: 8.53333vw; } }

@media all and (min-width: 768px) {
  .sppb-addon-team-members .notloaded {
    display: block; }
  .sppb-addon-team-members .loadmore-cntr {
    display: none; }
  .sppb-addon-team-members .sppb-team-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .sppb-addon-team-members .addon-cntr.filler {
    display: block; }
  .sppb-addon-team-members .sppb-addon-members {
    position: relative;
    width: 19.375vw;
    height: auto;
    border-radius: 0.83333vw;
    border-bottom-right-radius: 2.5vw;
    overflow: hidden;
    margin-bottom: 3.33333vw; }
    .sppb-addon-team-members .sppb-addon-members .img-cntr {
      height: auto;
      width: 19.375vw;
      position: relative;
      text-align: center;
      z-index: 9; }
      .sppb-addon-team-members .sppb-addon-members .img-cntr .img {
        height: auto;
        width: 100%; }
    .sppb-addon-team-members .sppb-addon-members .content-cntr {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 11;
      padding-left: 1.875vw;
      padding-right: 1.875vw;
      padding-bottom: 1.45833vw; }
      .sppb-addon-team-members .sppb-addon-members .content-cntr .txt {
        color: #FFFFFF;
        text-align: left; }
        .sppb-addon-team-members .sppb-addon-members .content-cntr .txt.title {
          font-size: 1.66667vw;
          line-height: 2.70833vw; }
        .sppb-addon-team-members .sppb-addon-members .content-cntr .txt.name {
          font-size: 2.29167vw;
          line-height: 3.33333vw; }
  .sppb-addon-team-members .addon-cntr:nth-child(4n+2) {
    margin-top: 4.16667vw;
    margin-bottom: -4.16667vw; }
  .sppb-addon-team-members .addon-cntr:nth-child(4n+3) {
    margin-top: 8.33333vw;
    margin-bottom: -8.33333vw; }
  .sppb-addon-team-members .addon-cntr:nth-child(4n+4) {
    margin-top: 12.5vw;
    margin-bottom: -12.5vw; } }

@media all and (min-width: 1024px) {
  .sppb-addon-team-members .sppb-addon-members {
    width: 14.44444vw;
    height: auto;
    border-radius: 0.44444vw;
    border-bottom-right-radius: 1.33333vw;
    margin-bottom: 1.77778vw; }
    .sppb-addon-team-members .sppb-addon-members .img-cntr {
      height: auto;
      width: 14.44444vw; }
      .sppb-addon-team-members .sppb-addon-members .img-cntr .img {
        height: auto;
        width: 100%; }
    .sppb-addon-team-members .sppb-addon-members .content-cntr {
      padding-left: 1.33333vw;
      padding-right: 1.33333vw;
      padding-bottom: 0.88889vw; }
      .sppb-addon-team-members .sppb-addon-members .content-cntr .txt.title {
        font-size: 0.94444vw;
        line-height: 1.55556vw; }
      .sppb-addon-team-members .sppb-addon-members .content-cntr .txt.name {
        font-size: 1.66667vw;
        line-height: 2.22222vw; }
  .sppb-addon-team-members .addon-cntr:nth-child(4n+2) {
    margin-top: 2.22222vw;
    margin-bottom: -2.22222vw; }
  .sppb-addon-team-members .addon-cntr:nth-child(4n+3) {
    margin-top: 4.44444vw;
    margin-bottom: -4.44444vw; }
  .sppb-addon-team-members .addon-cntr:nth-child(4n+4) {
    margin-top: 6.66667vw;
    margin-bottom: -6.66667vw; } }

@media all and (min-width: 0px) {
  .sppb-addon-process .process-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start; }
    .sppb-addon-process .process-row .process-list {
      position: static;
      padding-left: 0; }
    .sppb-addon-process .process-row .process-content {
      padding-right: 0; }
  .sppb-addon-process .content,
  .sppb-addon-process .heading {
    text-align: left; }
  .sppb-addon-process .sub-heading {
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #330072;
    margin-bottom: 40px; }
  .sppb-addon-process .sub-title {
    font-size: 11px;
    line-height: 27px;
    font-weight: bold;
    text-transform: uppercase;
    color: #FF6A13;
    margin-bottom: 16px; }
  .sppb-addon-process .heading.process {
    font-size: 24px;
    line-height: 32px;
    color: #330072; }
  .sppb-addon-process .process-cntr {
    margin-bottom: 160px; }
    .sppb-addon-process .process-cntr .img-cntr {
      position: relative;
      height: auto;
      min-height: 100px;
      width: 100%;
      min-width: 100%;
      margin-bottom: 48px;
      padding: 15.36vw 0; }
      .sppb-addon-process .process-cntr .img-cntr .bg-shape-cntr {
        position: absolute;
        right: 0;
        top: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: flex;
        flex-direction: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 100%;
        min-width: 100%; }
        .sppb-addon-process .process-cntr .img-cntr .bg-shape-cntr .bg-shape {
          width: 83%;
          border-radius: 8px;
          border-bottom-left-radius: 40px;
          background: #f5f5f5; }
      .sppb-addon-process .process-cntr .img-cntr .icon-cntr {
        position: absolute;
        height: 140px;
        right: 40px;
        z-index: 22;
        top: 20px;
        width: 120px; }
        .sppb-addon-process .process-cntr .img-cntr .icon-cntr img,
        .sppb-addon-process .process-cntr .img-cntr .icon-cntr svg {
          height: auto;
          width: 100%; }
      .sppb-addon-process .process-cntr .img-cntr .img {
        position: relative;
        max-width: 100%;
        width: 83%;
        height: auto;
        top: 0;
        left: 0; }
    .sppb-addon-process .process-cntr .content {
      font-size: 17px;
      line-height: 24px; }
  .sppb-addon-process .checkbox-cntr {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    cursor: pointer;
    align-content: center;
    margin-bottom: 32px; }
    .sppb-addon-process .checkbox-cntr .checkbox {
      transition: all 0.5s;
      height: 20px;
      width: 20px;
      border: 0.42667vw solid #cccccc;
      border-radius: 2px;
      padding: 0.21333vw;
      margin-right: 8px;
      margin-bottom: 1px; }
      .sppb-addon-process .checkbox-cntr .checkbox .checkmark {
        transition: all 0.5s;
        height: 100%;
        max-height: 100%;
        width: 100%;
        display: none; }
        .sppb-addon-process .checkbox-cntr .checkbox .checkmark svg {
          vertical-align: top; }
    .sppb-addon-process .checkbox-cntr.checked .checkbox {
      border-color: #ff8842;
      background: #ff8842; }
      .sppb-addon-process .checkbox-cntr.checked .checkbox .checkmark {
        display: block; }
    .sppb-addon-process .checkbox-cntr .label {
      text-align: left;
      font-size: 17px;
      line-height: 24px; } }

@media all and (min-width: 0px) and (max-width: 1023px) {
  .sppb-addon-process .process-row {
    display: block; }
  .sppb-addon-process .process-list {
    padding: 0;
    width: 100%;
    max-width: 100%; }
    .sppb-addon-process .process-list .checkbox-cntr {
      display: none; }
  .sppb-addon-process .process-spacer {
    display: none; }
  .sppb-addon-process .process-content {
    width: 100%;
    max-width: 100%;
    padding: 0; }
  .sppb-addon-process .process-cntr {
    margin-bottom: 40px;
    display: block; }
    .sppb-addon-process .process-cntr:nth-child(even) {
      flex-direction: row; }
      .sppb-addon-process .process-cntr:nth-child(even) .content-cntr {
        padding-left: 0;
        padding-right: 0; }
      .sppb-addon-process .process-cntr:nth-child(even) .img-cntr {
        padding-left: 0;
        padding-right: 20px; }
        .sppb-addon-process .process-cntr:nth-child(even) .img-cntr .bg-shape-cntr .bg-shape {
          right: 20px;
          left: auto;
          border-radius: 8px;
          border-bottom-right-radius: 80px; }
        .sppb-addon-process .process-cntr:nth-child(even) .img-cntr .icon-cntr {
          right: auto;
          left: 0; }
        .sppb-addon-process .process-cntr:nth-child(even) .img-cntr .img {
          right: -20px;
          left: auto;
          margin-left: auto; }
    .sppb-addon-process .process-cntr .content-cntr {
      width: 100%;
      padding-right: 0;
      min-width: 0; }
    .sppb-addon-process .process-cntr .img-cntr {
      width: 100%;
      min-width: 0;
      padding-left: 0;
      min-height: 100px;
      margin-bottom: 48px; }
      .sppb-addon-process .process-cntr .img-cntr .bg-shape-cntr .bg-shape {
        min-height: 100px;
        height: 100%;
        width: 83%;
        left: 20px;
        border-radius: 8px;
        border-bottom-left-radius: 80px; }
      .sppb-addon-process .process-cntr .img-cntr .icon-cntr {
        height: 29.86667vw;
        right: 0;
        top: 10px;
        width: 17.06667vw; }
      .sppb-addon-process .process-cntr .img-cntr .img {
        left: 0; }
  .sppb-addon-process .checkbox-cntr {
    margin-bottom: 32px; }
    .sppb-addon-process .checkbox-cntr .checkbox {
      height: 20px;
      width: 20px;
      margin-right: 8px;
      margin-bottom: 1px; }
    .sppb-addon-process .checkbox-cntr .label {
      font-size: 23px;
      line-height: 32px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .sppb-addon-process .process-cntr {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    flex-direction: row-reverse;
    align-content: flex-start;
    margin-top: 80px;
    margin-bottom: 40px;
    justify-items: center; }
    .sppb-addon-process .process-cntr:nth-child(even) .img-cntr .bg-shape {
      border-radius: 8px;
      border-bottom-right-radius: 80px; }
    .sppb-addon-process .process-cntr:nth-child(even) .content-cntr {
      padding-right: 0px;
      padding-left: 40px; }
    .sppb-addon-process .process-cntr .content-cntr {
      width: 50%;
      padding-right: 20px;
      min-width: 0; }
      .sppb-addon-process .process-cntr .content-cntr .content {
        padding-right: 20px; }
    .sppb-addon-process .process-cntr .img-cntr {
      width: 50%;
      min-width: 0;
      padding-left: 20px;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 29.79167vw;
      margin-bottom: 48px; }
      .sppb-addon-process .process-cntr .img-cntr .bg-shape-cntr .bg-shape {
        height: 29.79167vw;
        width: 83%;
        left: 20px;
        border-radius: 8px;
        border-bottom-left-radius: 80px; }
      .sppb-addon-process .process-cntr .img-cntr .icon-cntr {
        height: 14.58333vw;
        right: 0;
        top: 10px;
        width: 8.33333vw; }
      .sppb-addon-process .process-cntr .img-cntr .img {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 87%;
        height: auto; }
  .sppb-addon-process .content {
    font-size: 20px;
    line-height: 32px; }
  .sppb-addon-process .checkbox-cntr .checkbox {
    padding: 0.10417vw; }
  .sppb-addon-process .checkbox-cntr .label {
    font-size: 23px;
    line-height: 32px; } }

@media all and (min-width: 768px) {
  .sppb-addon-process .sub-heading {
    font-size: 54px;
    line-height: 60px;
    margin-bottom: 40px; }
  .sppb-addon-process .sub-title {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 16px; }
  .sppb-addon-process .heading.process {
    font-size: 30px;
    line-height: 40px; }
  .sppb-addon-process .img-cntr .bg-shape-cntr .bg-shape {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 8px;
    border-bottom-left-radius: 40px;
    background: #f5f5f5; } }

@media all and (min-width: 1024px) {
  .sppb-addon-process .process-cntr {
    margin-bottom: 8.88889vw; }
    .sppb-addon-process .process-cntr:last-child {
      margin-bottom: 0; }
  .sppb-addon-process .process-row {
    position: relative; }
    .sppb-addon-process .process-row .process-list {
      position: static; }
      .sppb-addon-process .process-row .process-list .process-list-cntr {
        padding-top: 4.44444vw;
        max-width: 18.88889vw; }
        .sppb-addon-process .process-row .process-list .process-list-cntr.fixed {
          z-index: 20;
          position: fixed;
          top: 5.33333vw;
          /*left: map-get($site-gutters, $breakpoint);*/ }
        .sppb-addon-process .process-row .process-list .process-list-cntr.absolute {
          /*max-width: 100%;
                        width: 100%;*/
          position: absolute;
          top: auto;
          bottom: 20px;
          left: 0; }
    .sppb-addon-process .process-row .process-content {
      padding-right: 0; }
  .sppb-addon-process .sub-heading {
    font-size: 3vw;
    line-height: 3.33333vw;
    margin-bottom: 2.22222vw; }
  .sppb-addon-process .sub-title {
    font-size: 0.66667vw;
    line-height: 1.33333vw;
    margin-bottom: 0.88889vw; }
  .sppb-addon-process .heading.process {
    font-size: 1.66667vw;
    line-height: 2.22222vw; }
  .sppb-addon-process .process-cntr {
    margin-bottom: 8.88889vw; }
    .sppb-addon-process .process-cntr .img-cntr {
      padding: 0;
      min-height: 28.88889vw;
      margin-bottom: 2.66667vw; }
      .sppb-addon-process .process-cntr .img-cntr .bg-shape-cntr .bg-shape {
        height: 28.88889vw;
        width: 25.55556vw;
        border-radius: 0.44444vw;
        border-bottom-left-radius: 8.88889vw; }
      .sppb-addon-process .process-cntr .img-cntr .icon-cntr {
        height: 7.77778vw;
        right: 2.22222vw;
        top: 1.11111vw;
        width: 6.66667vw; }
      .sppb-addon-process .process-cntr .img-cntr .img {
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        width: 24.77778vw;
        height: auto; }
    .sppb-addon-process .process-cntr .content {
      font-size: 1.11111vw;
      line-height: 1.77778vw; }
  .sppb-addon-process .checkbox-cntr {
    margin-bottom: 1.77778vw; }
    .sppb-addon-process .checkbox-cntr .checkbox {
      border: 0.11111vw solid #cccccc;
      padding: 0.05556vw;
      height: 1.11111vw;
      width: 1.11111vw;
      margin-right: 0.44444vw;
      margin-bottom: 0.05556vw; }
    .sppb-addon-process .checkbox-cntr .label {
      font-size: 1.27778vw;
      line-height: 1.77778vw; } }

.sppb-addon-image-shape-with-icon {
  position: relative;
  text-align: right; }
  @media all and (min-width: 0px) {
    .sppb-addon-image-shape-with-icon .image-cntr {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 83.33333%;
      margin-left: auto;
      margin-right: 0;
      margin-top: 40px; }
    .sppb-addon-image-shape-with-icon .icon {
      position: absolute;
      top: -3.8vw;
      left: 0;
      width: 25%;
      height: 25%; }
      .sppb-addon-image-shape-with-icon .icon .img,
      .sppb-addon-image-shape-with-icon .icon svg {
        width: 100%;
        height: 100%; }
    .sppb-addon-image-shape-with-icon .img-thumb-cntr {
      position: absolute;
      left: 0;
      bottom: 48px;
      width: 38.4vw;
      border-radius: 8px;
      overflow: hidden; }
      .sppb-addon-image-shape-with-icon .img-thumb-cntr .img-thumb {
        width: 100%; }
      .sppb-addon-image-shape-with-icon .img-thumb-cntr .st0 {
        fill: #FF6A13; }
      .sppb-addon-image-shape-with-icon .img-thumb-cntr:hover .st0 {
        fill: #ff8842; }
    .sppb-addon-image-shape-with-icon.bottom-left .image-cntr {
      border-bottom-left-radius: 13.65333vw;
      border-top-left-radius: 1.70667vw; } }
  @media all and (min-width: 768px) {
    .sppb-addon-image-shape-with-icon .image-cntr {
      width: 100%; }
    .sppb-addon-image-shape-with-icon .icon {
      top: -24px;
      left: -19%;
      width: 38%;
      height: 38%; }
    .sppb-addon-image-shape-with-icon.bottom-left .img-thumb-cntr {
      left: 0px; }
    .sppb-addon-image-shape-with-icon.bottom-left .image-cntr {
      border-bottom-left-radius: 64px;
      border-top-left-radius: 8px; } }
  @media all and (min-width: 1024px) {
    .sppb-addon-image-shape-with-icon .icon {
      top: -1.33333vw;
      left: -4.88889vw;
      width: 9.77778vw;
      height: 9.77778vw; }
    .sppb-addon-image-shape-with-icon.bottom-left .image-cntr {
      border-bottom-left-radius: 3.55556vw;
      border-top-left-radius: 0.44444vw; } }

@media all and (min-width: 0px) {
  .sppb-addon-3-col-image {
    margin-top: 60px; }
    .sppb-addon-3-col-image .image-cntr {
      position: relative;
      width: 100%; }
      .sppb-addon-3-col-image .image-cntr .img {
        width: 100%;
        border-radius: 0; }
      .sppb-addon-3-col-image .image-cntr.left .img {
        border-bottom-left-radius: 40px; }
      .sppb-addon-3-col-image .image-cntr.center, .sppb-addon-3-col-image .image-cntr.right {
        display: none; }
  .sppb-section.bg-type-burple.our-people {
    background: linear-gradient(to top, #FFFFFF 34.13333vw, #330072 34.13333vw) !important; } }

@media all and (min-width: 768px) {
  .sppb-addon-3-col-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 100px; }
    .sppb-addon-3-col-image .image-cntr {
      width: 27.08333vw;
      margin-bottom: 10vw; }
      .sppb-addon-3-col-image .image-cntr .img {
        border-radius: 0; }
      .sppb-addon-3-col-image .image-cntr.left .img {
        border-bottom-left-radius: 60px; }
      .sppb-addon-3-col-image .image-cntr.center {
        display: block;
        margin-left: 8px;
        margin-right: 8px; }
        .sppb-addon-3-col-image .image-cntr.center .img {
          border-bottom-right-radius: 60px;
          position: absolute;
          left: 0;
          top: 0; }
      .sppb-addon-3-col-image .image-cntr.right {
        display: block; }
        .sppb-addon-3-col-image .image-cntr.right .img {
          border-top-left-radius: 60px; }
  .sppb-section.bg-type-burple.our-people {
    background: linear-gradient(to top, #FFFFFF 13.95833vw, #330072 13.95833vw) !important; } }

@media all and (min-width: 1024px) {
  .sppb-addon-3-col-image {
    margin-top: 8vw; }
    .sppb-addon-3-col-image .image-cntr {
      width: 20vw;
      margin-bottom: 5.33333vw; }
      .sppb-addon-3-col-image .image-cntr .img {
        border-radius: 0; }
      .sppb-addon-3-col-image .image-cntr.left .img {
        border-bottom-left-radius: 3.33333vw; }
      .sppb-addon-3-col-image .image-cntr.center .img {
        position: absolute;
        left: 0;
        top: 0;
        border-bottom-right-radius: 3.33333vw; }
      .sppb-addon-3-col-image .image-cntr.right .img {
        border-top-left-radius: 3.33333vw; }
  .sppb-section.bg-type-burple.our-people {
    background: linear-gradient(to top, #FFFFFF 8.44444vw, #330072 8.44444vw) !important; } }

@media all and (min-width: 0px) {
  .sppb-addon-animation-shape-left {
    position: relative; }
    .sppb-addon-animation-shape-left .animation-cntr {
      position: relative;
      left: -30px;
      width: calc(100% + 60px);
      height: 69.97333vw;
      border-top-left-radius: 18.77333vw;
      background: #f5f5f5; }
      .sppb-addon-animation-shape-left .animation-cntr .lottie-animation {
        width: 100%;
        height: 100%; } }

@media all and (min-width: 768px) {
  .sppb-addon-animation-shape-left .animation-cntr {
    width: calc(100% + 60px);
    height: 41.66667vw;
    left: -60px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 8.33333vw; } }

@media all and (min-width: 1024px) {
  .sppb-addon-animation-shape-left .animation-cntr {
    width: calc(100% + 18.36806vw);
    height: 40vw;
    left: -18.36806vw;
    border-top-right-radius: 0.44444vw;
    border-bottom-right-radius: 8.88889vw; } }

@media all and (min-width: 0px) {
  .addon-tile-image-with-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    .addon-tile-image-with-content .tile {
      width: 100%;
      margin-bottom: 32px;
      background-color: #FFFFFF;
      box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;
      border-radius: 6px 6px 0 0; }
      .addon-tile-image-with-content .tile .img-cntr {
        overflow: hidden;
        border-radius: 0 0 32px 0; }
      .addon-tile-image-with-content .tile .img-cntr .img {
        width: 100%; }
      .addon-tile-image-with-content .tile .content {
        padding: 16px; }
        .addon-tile-image-with-content .tile .content .header {
          height: auto;
          margin-bottom: 16px; }
        .addon-tile-image-with-content .tile .content .bottom-bar {
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-box-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-flex-wrap: wrap;
          /* Safari 6.1+ */
          flex-wrap: wrap;
          -webkit-box-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: flex-end;
          -webkit-align-items: flex-end;
          align-items: flex-end;
          flex-wrap: wrap; }
          .addon-tile-image-with-content .tile .content .bottom-bar .logo {
            height: 48px; } }

@media all and (min-width: 0px) and (-ms-high-contrast: none) and (-ms-high-contrast: none) {
  .addon-tile-image-with-content .tile .content .bottom-bar .sppb-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    height: 4.26667vw; }
    .addon-tile-image-with-content .tile .content .bottom-bar .sppb-button a {
      height: 100%; } }

@media all and (min-width: 768px) {
  .addon-tile-image-with-content .tile {
    width: calc(50% - 2.5vw);
    margin-bottom: 2.5vw;
    box-shadow: 0 1.66667vw 3.33333vw 0 rgba(0, 0, 0, 0.1); }
    .addon-tile-image-with-content .tile .content {
      padding: 2.5vw; }
      .addon-tile-image-with-content .tile .content .header {
        height: 10vw; }
      .addon-tile-image-with-content .tile .content .bottom-bar {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: flex-start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-pack: flex-start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start; }
        .addon-tile-image-with-content .tile .content .bottom-bar .logo {
          height: 5vw;
          margin-bottom: 1.66667vw; }
    .addon-tile-image-with-content .tile:nth-child(2n+1) {
      margin-top: 4.16667vw; } }

@media all and (min-width: 1024px) {
  .addon-tile-image-with-content .tile {
    width: calc(50% - 1.11111vw);
    margin-bottom: 1.33333vw;
    box-shadow: 0 1.33333vw 2.66667vw 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.44444vw 0.44444vw 0 0; }
    .addon-tile-image-with-content .tile .img-cntr {
      border-radius: 0 0 2.22222vw 0; }
    .addon-tile-image-with-content .tile .content {
      padding: 1.77778vw; }
      .addon-tile-image-with-content .tile .content .header {
        height: 8vw; }
      .addon-tile-image-with-content .tile .content .bottom-bar {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        /* Safari 6.1+ */
        flex-wrap: wrap;
        -webkit-box-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: flex-end;
        -webkit-align-items: flex-end;
        align-items: flex-end;
        height: 3.55556vw; }
        .addon-tile-image-with-content .tile .content .bottom-bar .logo {
          height: 100%;
          margin-bottom: 0; } }

@media all and (min-width: 1024px) and (-ms-high-contrast: none) and (-ms-high-contrast: none) {
  .addon-tile-image-with-content .tile .content .bottom-bar .sppb-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    /* Safari 6.1+ */
    flex-wrap: wrap;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    height: 1.11111vw; }
    .addon-tile-image-with-content .tile .content .bottom-bar .sppb-button a {
      height: 100%; } }

@media all and (min-width: 1024px) {
    .addon-tile-image-with-content .tile:nth-child(2n+1) {
      margin-top: 2.22222vw; } }

@media all and (min-width: 0px) {
  .sppb-addon-google-maps .sppb-addon-gmap-canvas {
    height: 55.46667vw; } }

@media all and (min-width: 768px) {
  .sppb-addon-google-maps .sppb-addon-gmap-canvas {
    height: 27.08333vw; } }

@media all and (min-width: 1024px) {
  .sppb-addon-google-maps .sppb-addon-gmap-canvas {
    height: 23.33333vw; } }
