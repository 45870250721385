@mixin flex-justify-items($justify) {
    @if $justify=="space-around" {
        -webkit-box-pack: justify;
    }
    @else {
        -webkit-box-pack: $justify;
    }
    -webkit-justify-content: $justify;
    justify-content: $justify;
}

@mixin flex-align-items($align-items) {
    -webkit-box-align: $align-items;
    -webkit-align-items: $align-items;
    align-items: $align-items;
}

@mixin flex-direction ($direction) {
    @if $direction=="row" {
        -webkit-box-orient: horizontal;
    }
    @if $direction=="column" {
        -webkit-box-orient: vertical;
    }
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
}

@mixin flex-align-self ($align-self) {
    -webkit-box-align-self: $align-self;
    -webkit-align-self: $align-self;
    align-self: $align-self;
}

@mixin flex-grow ($grow) {
    -webkit-box-flex-grow: $grow;
    -webkit-flex-grow: $grow;
    flex-grow: $grow;
}

@mixin flex-shrink ($shrink) {
    -webkit-box-flex-shrink: $shrink;
    -webkit-flex-shrink: $shrink;
    flex-shrink: $shrink;
}
@mixin flex-wrap ($wrap) {
    -webkit-flex-wrap: $wrap; /* Safari 6.1+ */
    flex-wrap: $wrap;
}

@mixin flex-box($display, $direction: null, $justify: null, $align-items: null, $wrap: wrap)  {
    @if $display=="inline" {
        //  display: inline-box;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: inline-flex;
    }
    @else if $display=="none" {
        display: none;
    }
    @else {
        //display: box;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
    }
    @include flex-direction($direction);
    @include flex-justify-items($justify);
    @include flex-wrap($wrap);
    @include flex-justify-items($justify);
    @include flex-align-items($align-items);
}