body {
    &:before {
        display: none;
        content: "";
        $breakpoint: "mobile-s-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "mobile-m-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "tablet-s-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "tablet-m-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "tablet-l-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "desktop-s-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "desktop-m-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "desktop-l-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
    }
    &:after {
        display: none;
        content: "";
        $breakpoint: "mobile-s-up";
        @include on-breakpoint($breakpoint) {
            content: $breakpoint;
        }
        $breakpoint: "mobile-m-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
        $breakpoint: "tablet-s-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
        $breakpoint: "tablet-m-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
        $breakpoint: "tablet-l-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
        $breakpoint: "desktop-s-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
        $breakpoint: "desktop-m-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
        $breakpoint: "desktop-l-up";
        @include on-breakpoint($breakpoint, "img") {
            content: $breakpoint;
        }
    }
}
