.sppb-addon-products{
    @include flex-box('normal', column, null, null, wrap);


    .product-cntr{
        align-self: center;
        background: map-get($colors, light-bg);

        @include flex-box('normal', null, null, null, wrap);

        border-radius: 8px;
        box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.08);

        &:first-child{
            align-self: flex-start;
        }
        &:last-child{
            align-self: flex-end;
        }

        .img-cntr{
            position: relative;
            .img{
                position: static;
                height: auto;
                width: 100%;
                left: 0;
            }
        }

        .content-cntr{
            box-sizing: content-box;
            .heading{
                text-align: left;

            }
            .txt{
                text-align: left;
                p{
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                }
            }
        }
    }


    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {

        .product-cntr{
            @include set-spacer(3, px);
            display: block;

            width: 100%;
            .img-cntr{
                text-align: center;
                width: 100%;

                .img{
                    position: static;
                    height: auto;
                    display: inline-block;

                    width: get-vw(184px, map-get($site-widths-design, $breakpoint));
                }
            }

            .txt{
                font-size: 16px;
                line-height: 27px;

            }
        }
        .content-cntr{
            padding: 40px;
        }
    }
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {

        .product-cntr{
            @include set-spacer(3, px);
            @include flex-box('normal', null, null, null, wrap);
            width: get-vw(560px, map-get($site-widths-design, $breakpoint));
            position: relative;
            .img-cntr{
                width: get-vw(184px, map-get($site-widths-design, $breakpoint));
                height: auto;
                @include flex-box('normal', row, center, center, wrap);
                position: absolute;
                bottom: 0;
                right: 0;
                .img{
                    width: 100%;
                    height: auto;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .txt{
                font-size: 17px;
                line-height: 28px;
            }
        }
        .content-cntr{
            padding-left: get-vw(40px, map-get($site-widths-design, $breakpoint));
            padding-top: get-vw(40px, map-get($site-widths-design, $breakpoint));
            padding-right: get-vw(40px, map-get($site-widths-design, $breakpoint));
            padding-bottom: get-vw(32px, map-get($site-widths-design, $breakpoint));
            max-width: get-vw(320px, map-get($site-widths-design, $breakpoint));
        }



    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {


        .product-cntr{
            @include set-spacer(3);
            position: relative;
            .img-cntr{
                position: absolute;
                bottom: 0;
                right: 0;
                width: get-vw(184px, map-get($site-widths-design, $breakpoint));
                height: get-vw(168px, map-get($site-widths-design, $breakpoint));
                .img{
                    width: 100%;
                    //margin-top: get-vw(-16px, map-get($site-widths-design, $breakpoint));
                    position: absolute;
                    right: 0;
                    bottom: 0;

                }
            }
            @include flex-box('normal', null, null, null, wrap);
            width: get-vw(560px, map-get($site-widths-design, $breakpoint));
            .txt{
                font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));
            }
        }
        .content-cntr{
            padding-left: get-vw(40px, map-get($site-widths-design, $breakpoint));
            padding-top: get-vw(40px, map-get($site-widths-design, $breakpoint));
            padding-right: get-vw(40px, map-get($site-widths-design, $breakpoint));
            padding-bottom: get-vw(32px, map-get($site-widths-design, $breakpoint));
            max-width: get-vw(320px, map-get($site-widths-design, $breakpoint));
        }
        &.spread{
            min-height: get-vw(570px, map-get($site-widths-design, $breakpoint));
            /*justify-content: space-between;*/

            //fix for ie
            .product-cntr{
                margin-top: get-vw(60px, map-get($site-widths-design, $breakpoint));
                margin-bottom: get-vw(60px, map-get($site-widths-design, $breakpoint));
            }



            .product-cntr:last-child{
                align-self: center;
            }
        }
    }
}
$breakpoint: "mobile-s-up";
@include on-breakpoint($breakpoint) {
    .products-bg-shape{
        z-index: -1;
        position: absolute;
        width: 100%;
        min-height: get-vw(720px, map-get($site-widths-design, $breakpoint));
        display: none;
        top: get-vw(-56px, map-get($site-widths-design, $breakpoint));
        height: 100%;
        background: map-get($colors, bg-grey-light);
        right: -((map-get($site-gutters, $breakpoint)));
        border-bottom-left-radius: get-vw(160px, map-get($site-widths-design, $breakpoint));
    }
}
$breakpoint: "tablet-s-up";
@include on-breakpoint($breakpoint) {
    .products-bg-shape{
        display: none;
        min-height: get-vw(720px, map-get($site-widths-design, $breakpoint));
        top: get-vw(-56px, map-get($site-widths-design, $breakpoint));
        right: -((map-get($site-gutters, $breakpoint)));
        border-bottom-left-radius: get-vw(160px, map-get($site-widths-design, $breakpoint));
    }
}
$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {
    .products-bg-shape{
        display: block;
        min-height: get-vw(680px, map-get($site-widths-design, $breakpoint));
        top: get-vw(-56px, map-get($site-widths-design, $breakpoint));
        right: -((map-get($site-gutters, $breakpoint)));
        border-bottom-left-radius: get-vw(160px, map-get($site-widths-design, $breakpoint));
    }
}

