.sppb-section.cases-overview  {
    $gradient-color-top: map-get($base-colors, senary);
    $gradient-color-bottom: map-get($base-colors, quaternary);

    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        $gradient-top-height: 34vw;
        background: linear-gradient(to bottom,  $gradient-color-top $gradient-top-height, $gradient-color-bottom $gradient-top-height) !important;
    }
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        $gradient-top-height: 12%;
        background: linear-gradient(to bottom,  $gradient-color-top $gradient-top-height, $gradient-color-bottom $gradient-top-height) !important;
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        $gradient-top-height: get-vw(map-get($site-vert-grid, $breakpoint)*25, map-get($site-widths-design, $breakpoint));
        background: linear-gradient(to bottom,  $gradient-color-top $gradient-top-height, $gradient-color-bottom $gradient-top-height) !important;
    }
}