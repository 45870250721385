.sppb-addon-client-logos {
    @mixin create-logo-rows($classNr, $amount-on-a-row) {
        &.items#{$classNr} {
            $item-width: (80%*(1/$amount-on-a-row));
            $item-margin: (100% - ($item-width*$amount-on-a-row))/ ($amount-on-a-row - 1 );
            .content.img {
                width: $item-width;
                &:not(:nth-child(#{$amount-on-a-row}n + #{$amount-on-a-row})) {
                    margin-right: $item-margin;
                }
                &:nth-child(#{$amount-on-a-row}n + #{$amount-on-a-row}),
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        .logos {
            @include flex-box(normal, row, center, center);
            flex-wrap: wrap;
            .content.img {
                @include flex-box(normal, row, center, center);
                height: 40px;
                margin-bottom: map-get($site-vert-grid, $breakpoint )*4;

                .img {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }
            @include create-logo-rows(5,2);
            @include create-logo-rows(6,2);
        }
    }

    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .logos {
            .content.img {
                max-width: 25%;
                width: 25%;
                margin-bottom: map-get($site-vert-grid, $breakpoint )*3;
            }
            @include create-logo-rows(5,4);
            @include create-logo-rows(6,4);
        }
    }

    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        .logos {
            .content.img {
                height: get-vw(map-get($site-vert-grid, $breakpoint)*6, map-get($site-widths-design, $breakpoint));
                margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint)*4, map-get($site-widths-design, $breakpoint));
                max-width: 25%;
            }

            @include create-logo-rows(5,5);
            @include create-logo-rows(6,6);
        }
    }
}