.sppb-addon-image-block{

    .bg-container{
        .img{
            display: inline-block;
            max-width: 100%;
            width:100%;
            height: auto;
        }
        background: map-get($colors, bg-grey-light);
        width: 100%;

        text-align: center;
        @include flex-box('normal', null, center, center, wrap);

        align-content: center;


    }
}

$breakpoint: "mobile-s-up";
@include on-breakpoint($breakpoint) {
    .sppb-addon-image-block{
        .bg-container{
            height: get-vw(352px, 600px);
        }
    }
}

$breakpoint: "tablet-s-up";
@include on-breakpoint($breakpoint) {
    .sppb-addon-image-block{
        text-align: center;
        .bg-container{
            height: 376px;
            height: get-vw(352px, 600px);
        }
    }
}
$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {
    .sppb-addon-image-block{
        .bg-container{
            height: get-vw(376px, 1440px);

        }
    }
}

