.sppb-section.portfolio-wide-image {
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-addon{ 

            &.sppb-addon-portfolio {
                margin-bottom: -(get-vw(map-get($site-vert-grid , $breakpoint ), map-get($site-widths-design, $breakpoint ))*20);
                .addon-cntr {
                    position: relative;
                    z-index: 5;
                    .sppb-addon-media {
                        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08) !important;
                    }
                }

            }

        }
    }
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-addon{ 
            &.sppb-addon-portfolio {
                margin-bottom: -(get-vw(map-get($site-vert-grid , $breakpoint ), map-get($site-widths-design, $breakpoint ))*19);
            }
        }
    }

    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-addon{ 
            &.sppb-addon-portfolio {
                margin-bottom: -(get-vw(map-get($site-vert-grid , $breakpoint ), map-get($site-widths-design, $breakpoint ))*17);
            }
        }
    }
}