/*@font-face {
	font-family: 'Century Gothic';
	src: url('/src/fonts/CenturyGothic.woff') format('woff'),
		url('/src/fonts/CenturyGothic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('/src/fonts/CenturyGothic-Bold.woff') format('woff'),
		url('/src/fonts/CenturyGothic-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}*/

/* @font-face {
    font-family: 'Gilroy-Regular';
    src: url('/src/fonts/gilroy/397815_0_0.eot'),
        url('/src/fonts/gilroy/397815_0_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_0_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_0_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_0_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_1_0.eot'),
        url('/src/fonts/gilroy/397815_1_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_1_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_1_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_1_0.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
}


@font-face {
    font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_2_0.eot'),
    url('/src/fonts/gilroy/397815_2_0.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/gilroy/397815_2_0.woff2') format('woff2'),
    url('/src/fonts/gilroy/397815_2_0.woff') format('woff'),
    url('/src/fonts/gilroy/397815_2_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-Light';
    src: url('/src/fonts/gilroy/397815_3_0.eot'),
        url('/src/fonts/gilroy/397815_3_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_3_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_3_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_3_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('/src/fonts/gilroy/397815_4_0.eot'),
        url('/src/fonts/gilroy/397815_4_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_4_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_4_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_4_0.ttf') format('truetype');
    font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_5_0.eot'),
        url('/src/fonts/gilroy/397815_5_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_5_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_5_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_5_0.ttf') format('truetype');
    font-weight: bold;
	font-style: italic;
}


@font-face {font-family: 'Gilroy-Medium';
    src: url('/src/fonts/gilroy/397815_6_0.eot'),
        url('/src/fonts/gilroy/397815_6_0.eot?#iefix') format('embedded-opentype'),
        url('/src/fonts/gilroy/397815_6_0.woff2') format('woff2'),
        url('/src/fonts/gilroy/397815_6_0.woff') format('woff'),
        url('/src/fonts/gilroy/397815_6_0.ttf') format('truetype');
    font-weight: bold;
	font-style: normal;
} */

@font-face {
    font-display: auto;
  font-family: "Gilroy"; // Gilroy-Light
  src: url("/src/fonts/gilroy/397815_3_0.eot");
  src: url("/src/fonts/gilroy/397815_3_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_3_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_3_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_3_0.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}


@font-face {
    font-display: auto;
  font-family: "Gilroy"; // Gilroy-Regular
  src: url("/src/fonts/gilroy/397815_0_0.eot");
  src: url("/src/fonts/gilroy/397815_0_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_0_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_0_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_0_0.ttf") format("truetype");
  font-weight: 400;
	font-style: normal;
}

@font-face {
    font-display: auto;
  font-family: "Gilroy"; // Gilroy-RegularItalic
  src: url("/src/fonts/gilroy/397815_1_0.eot");
  src: url("/src/fonts/gilroy/397815_1_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_1_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_1_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_1_0.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
  font-family: "Gilroy"; // Gilroy-Medium
  src: url("/src/fonts/gilroy/397815_2_0.eot");
  src: url("/src/fonts/gilroy/397815_2_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_2_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_2_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_2_0.ttf") format("truetype");
  font-weight: 500;
	font-style: normal;
}

@font-face {
    font-display: auto;
  font-family: "Gilroy"; // Gilroy-Bold
  src: url("/src/fonts/gilroy/397815_6_0.eot");
  src: url("/src/fonts/gilroy/397815_6_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_6_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_6_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_6_0.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
    font-display: auto;
  font-family: "Gilroy"; // Gilroy-BoldItalic
  src: url("/src/fonts/gilroy/397815_5_0.eot");
  src: url("/src/fonts/gilroy/397815_5_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_5_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_5_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_5_0.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-display: auto;
  font-family: "Gilroy"; // Gilroy-ExtraBold
  src: url("/src/fonts/gilroy/397815_4_0.eot");
  src: url("/src/fonts/gilroy/397815_4_0.eot?#iefix") format("embedded-opentype"),
    url("/src/fonts/gilroy/397815_4_0.woff2") format("woff2"),
    url("/src/fonts/gilroy/397815_4_0.woff") format("woff"),
    url("/src/fonts/gilroy/397815_4_0.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}





$fonts: (
  default: "Gilroy"
);
