
$gradient-color-top: map-get($base-colors, primary);
$gradient-color-bottom: map-get($base-colors, quaternary);
$breakpoint: "mobile-s-up";

@include on-breakpoint($breakpoint) {
    $border-radius: 40px;
    .sppb-addon-3-col-image{
        margin-top: 60px;
        .image-cntr{
            position: relative;
            width: 100%;
            .img{
                width: 100%;
                border-radius: 0;

            }
            &.left{
                .img{
                    border-bottom-left-radius: $border-radius;
                }
            }
            &.center,
            &.right{
                display: none;

            }

        }

    }
    .sppb-section.bg-type-burple.our-people{

        $gradient-top-height: get-vw(160px, map-get($site-widths-design, $breakpoint));
        background: linear-gradient(to top, $gradient-color-bottom $gradient-top-height, $gradient-color-top $gradient-top-height) !important;
    }
}

$breakpoint: "tablet-s-up";
@include on-breakpoint($breakpoint) {
    $border-radius: 60px;
    .sppb-addon-3-col-image{
        @include flex-box('normal', null, space-between, null, wrap);


        margin-top: 100px;
        .image-cntr{
            width: get-vw(260px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint)*12, map-get($site-widths-design, $breakpoint));
            .img{
                border-radius: 0;

            }
            &.left{
                .img{
                    border-bottom-left-radius: $border-radius;
                }
            }
            &.center{
                display: block;
                margin-left: map-get($column-gutter, $breakpoint);
                margin-right: map-get($column-gutter, $breakpoint);
                .img{
                    border-bottom-right-radius: $border-radius;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            &.right{
                display: block;
                .img{
                    border-top-left-radius: $border-radius;
                }
            }
        }
    }
    .sppb-section.bg-type-burple.our-people{
        $gradient-top-height: get-vw(134px, map-get($site-widths-design, $breakpoint));
        background: linear-gradient(to top, $gradient-color-bottom $gradient-top-height, $gradient-color-top $gradient-top-height) !important;
    }
}
$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {
    $border-radius: get-vw(60px, map-get($site-widths-design, $breakpoint));
    .sppb-addon-3-col-image{
        margin-top: get-vw(144px, map-get($site-widths-design, $breakpoint));
        .image-cntr{
            width: get-vw(360px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(map-get($site-vert-grid, $breakpoint)*12, map-get($site-widths-design, $breakpoint));

            .img{
                border-radius: 0;

            }
            &.left{
                .img{
                    border-bottom-left-radius: $border-radius;
                }
            }
            &.center{

                .img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-bottom-right-radius: $border-radius;
                }
            }
            &.right{
                .img{
                    border-top-left-radius: $border-radius;
                }
            }
        }
    }
    .sppb-section.bg-type-burple.our-people{
        $gradient-top-height: get-vw(map-get($site-vert-grid, $breakpoint)*19, map-get($site-widths-design, $breakpoint));
        background: linear-gradient(to top, $gradient-color-bottom $gradient-top-height, $gradient-color-top $gradient-top-height) !important;
    }
}
