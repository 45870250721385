.sppb-addon-portfolio{
$breakpoint: "mobile-s-up";
@include on-breakpoint($breakpoint) {
        .sppb-tab{
            .sppb-nav-tabs{
                @include flex-box(normal);
                @include flex-justify-items(center);
                border: none;
                margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
            }
            .tab-cntr {
                float: none;
                background: none;
                @include flex-box('normal', null, null, null, wrap);
                align-content: center;
                align-items: center;
                justify-content: center;
                width: get-vw(100px, map-get($site-widths-design, $breakpoint));
                height: get-vw(100px, map-get($site-widths-design, $breakpoint));
                margin-left: get-vw(5px, map-get($site-widths-design, $breakpoint));
                margin-right: get-vw(5px, map-get($site-widths-design, $breakpoint));
                border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                border-bottom-left-radius: get-vw(24px, map-get($site-widths-design, $breakpoint));
                .icon.img{
                    opacity: 1;
                    path{
                        fill: map-get($colors, icon-light );
                    }
                }
                &.active{
                    background: map-get($colors, light-bg);
                    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.1);
                    .icon.img{
                        opacity: 1;
                        path{
                            fill: map-get($colors, icon-dark);
                        }
                    }
                    .title{
                        opacity: 1;
                        color: map-get($colors, block-text-on-light);
                    }
                }
                .icon.img{
                    display: inline-block;
                    text-align: center;
                    margin-bottom: get-vw(8px, map-get($site-widths-design, $breakpoint));
                    opacity: 0.4;
                    height: get-vw(32px, map-get($site-widths-design, $breakpoint));
                    width: get-vw(32px, map-get($site-widths-design, $breakpoint));
                    img,
                    svg{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .tablink{
                    background: none;
                    border: none;
                    text-align: center;
                    line-height: 1;
                    text-decoration: none;
                    font-weight: normal;
                    padding: 0!important;
                    cursor: pointer;
                }
                .title{
                    color: map-get($colors, block-name-on-dark);
                    color: green;
                    min-height: 30px;
                    opacity: 0.3;
                    font-size: 15px;
                }
            }
        }
        .sppb-tab-panel{
            flex-direction: row;
            @include flex-box('normal', null, space-between, null, wrap);
            display: none;

            &.active{
                display: block;
            }
        }
        .sppb-addon-media{
            background: map-get($colors, light-bg);
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
            border-top-left-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            border-top-right-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            .img{
                border-top-left-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                border-top-right-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            }
            .image-cntr{
                position: relative;
                .sppb-modal-selector {
                    width: 100%;
                }
                .img-link{
                    width: 100%;
                    @include flex-box('normal', null, null, null, wrap);
                }
                .st0{
                    fill: map-get($colors, btn-link-on-light-bg);
                }
                &:hover{
                    .st0{
                        fill: map-get($colors, btn-link-on-light-bg-hover);

                    }
                }
            }
            .button.img{
                position: absolute;
                height: get-vw(42px, map-get($site-widths-design, $breakpoint));
                width: get-vw(70px, map-get($site-widths-design, $breakpoint));
                left: 50%;
                top: 50%;
                img,
                svg{
                    position: relative;
                    top: -50%;
                    left: -50%;
                    max-width: 100%;
                    height: 100%;
                    max-height: 100%;
                }
            }
            .content-cntr{
                padding-left: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-top: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(24px, map-get($site-widths-design, $breakpoint));
            }

            .title{
                text-align: left;
                font-weight: bold;
                font-size: 23px;
                line-height: 32px;
                color: map-get($colors, header-dark-txt-on-light);
                p{
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                }
            }
            .client{
                text-align: left;
                font-size: 17px;
                line-height: 28px;
                color: map-get($colors, block-light-text-on-light);
            }
        }
        .addon-cntr{
            flex-shrink: 1;
            width: 100%;
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
        }

    }
$breakpoint: "tablet-s-up";
@include on-breakpoint($breakpoint) {
        .sppb-tab{
            .sppb-nav-tabs{
                margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
                justify-content: center;
            }
            .tab-cntr{
                width: get-vw(108px, map-get($site-widths-design, $breakpoint));
                height: get-vw(108px, map-get($site-widths-design, $breakpoint));

                border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                border-bottom-left-radius: get-vw(24px, map-get($site-widths-design, $breakpoint));
                .icon.img{
                    margin-bottom: get-vw(8px, map-get($site-widths-design, $breakpoint));
                    height: get-vw(30px, map-get($site-widths-design, $breakpoint));
                    width: get-vw(30px, map-get($site-widths-design, $breakpoint));
                }
                .title{
                    font-size: get-vw(14px, map-get($site-widths-design, $breakpoint));
                    min-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
                }
            }
        }
        .sppb-tab-panel{

            display: none;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            &.active{
                @include flex-box('normal', null, null, null, wrap);
            }
        }



        .sppb-addon-media{
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
            border-top-left-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            border-top-right-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            .img{
                border-top-left-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                border-top-right-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            }

            .button.img{
                height: get-vw(42px, map-get($site-widths-design, $breakpoint));
                width: get-vw(70px, map-get($site-widths-design, $breakpoint));
                left: 50%;
            }
            .content-cntr{
                padding-left: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-top: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(24px, map-get($site-widths-design, $breakpoint));
            }
            .title{
                font-size: get-vw(23px, map-get($site-widths-design, $breakpoint));;
                line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));;
            }
            .client{
                font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));;
                line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));;
            }
        }
        .addon-cntr{
            width: get-vw(260px, map-get($site-widths-design, $breakpoint));
            margin-bottom: 0;
        }

        .addon-cntr:nth-child(3n+2){
            margin-top: get-vw(40px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-40px, map-get($site-widths-design, $breakpoint));
        }
        .addon-cntr:nth-child(3n+3){
            margin-top: get-vw(80px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-80px, map-get($site-widths-design, $breakpoint));
        }

    }

$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {
        .sppb-tab{
            .sppb-nav-tabs{
                margin-bottom: get-vw(map-get($site-vert-grid , $breakpoint )*13, map-get($site-widths-design, $breakpoint));
                justify-content: center;
            }
            .tab-cntr{
                width: get-vw(160px, map-get($site-widths-design, $breakpoint));
                height: get-vw(160px, map-get($site-widths-design, $breakpoint));
                margin-left: get-vw(20px, map-get($site-widths-design, $breakpoint));
                margin-right: get-vw(20px, map-get($site-widths-design, $breakpoint));
                border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                border-bottom-left-radius: get-vw(24px, map-get($site-widths-design, $breakpoint));
                .icon.img{
                    margin-bottom: get-vw(8px, map-get($site-widths-design, $breakpoint));
                    height: get-vw(52px, map-get($site-widths-design, $breakpoint));
                    width: get-vw(52px, map-get($site-widths-design, $breakpoint));
                }
                .title{
                    font-size: get-vw(20px, map-get($site-widths-design, $breakpoint));
                    min-height: get-vw(36px, map-get($site-widths-design, $breakpoint));
                }
            }
        }
        .sppb-tab-panel{
            @include flex-box('normal', row, space-between, null, wrap);
            display: none;
            &.active{
                @include flex-box('normal', null, null, null, wrap);
            }
        }
        .sppb-addon-media{
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
            border-top-left-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            border-top-right-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            .img{
                border-top-left-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                border-top-right-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            }

            .button.img{
                height: get-vw(42px, map-get($site-widths-design, $breakpoint));
                width: get-vw(70px, map-get($site-widths-design, $breakpoint));
                left: 50%;
            }
            .content-cntr{
                padding-left: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-top: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(24px, map-get($site-widths-design, $breakpoint));
            }
            .title{
                font-size: get-vw(23px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));

            }
            .client{
                font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));
            }
        }
        .addon-cntr{
            width: get-vw(360px, map-get($site-widths-design, $breakpoint));
            margin-bottom: 0;
        }

        .addon-cntr:nth-child(3n+2){
            margin-top: get-vw(40px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-40px, map-get($site-widths-design, $breakpoint));
        }
        .addon-cntr:nth-child(3n+3){
            margin-top: get-vw(80px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-80px, map-get($site-widths-design, $breakpoint));
        }
    }
}

.sppb-section {
  &.bg-type-grey {
    .sppb-tab {
      .tab-cntr {
        .icon.img {
          path {
            fill: map-get($colors, icon-dark);
          }
        }
      .title {
        color: map-get($colors, icon-dark);
        }
      }
    }
  }
}