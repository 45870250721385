.product-presentation{
    .sppb-addon-block-header{
        .header{
            text-align: left;
            &.big-burple{
                text-align: center;
            }
        }
    }

    .sppb-addon-text{
        text-align: left;
        .text{
            text-align: left;
        }
    }

    .sppb-col-md-5{
        .sp-pagebuilder-column,
        .sppb-column{
            box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08)!important;
            border-radius: 4px;
        }
    }

    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        $column-padding: get-vw(30px, map-get($site-widths-design, $breakpoint));
        .sppb-col-md-5{
            @include set-spacer(7, px);

            .sppb-addon-block-header{
                .header{
                    max-width: 60%;
                }
            }
            .sppb-addon-text{
                max-width: 80%;
            }
            .sp-pagebuilder-column,
            .sppb-column{
                border-top-right-radius: get-vw(136px, map-get($site-widths-design, $breakpoint))!important;
                padding: $column-padding;
            }
        }
        .sppb-col-md-5:nth-child(7){
            @include set-spacer(0);
        }

        .sppb-addon-media {
            position: relative;
            // min-height: get-vw(60px, map-get($site-widths-design, $breakpoint));
            $image-top: -(get-vw(map-get($site-vert-grid, $breakpoint)*4, map-get($site-widths-design, $breakpoint)) + $column-padding );
            .image-cntr{
                position: relative;
                top: $image-top;
                right: -($column-padding);
                margin-bottom: $image-top;
                //margin-top: get-vw(-120px, map-get($site-widths-design, $breakpoint));
                text-align: right;
                height: 100%;
                .img{
                    // width: get-vw(195px, map-get($site-widths-design, $breakpoint));
                    width: 195px;
                    height: 100%;
                }
            }
        }
    }

    $breakpoint: "tablet-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {

        .sppb-row{

            .sppb-column-addons{
                display: block;

            }
            .sppb-col-md-5{
                position: relative;
                .sp-pagebuilder-column,
                .sppb-column{
                    border-radius: 4px;
                    //dev
                    border-top-right-radius: get-vw(120px, map-get($site-widths-design, $breakpoint))!important;
                    position: static;
                    padding-left: get-vw(60px, map-get($site-widths-design, $breakpoint));
                    padding-right: get-vw(60px, map-get($site-widths-design, $breakpoint));
                    padding-top: get-vw(30px, map-get($site-widths-design, $breakpoint));
                    padding-bottom: get-vw(30px, map-get($site-widths-design, $breakpoint));
                    .sppb-column-addons{
                        position: static;
                    }
                }
                .sppb-addon-button-wrap,
                .sppb-addon-text,
                .sppb-addon-block-header {
                    width: get-vw(340px, map-get($site-widths-design, $breakpoint));
                }
            }
            .sppb-addon-media .image-cntr{
                margin-bottom: 0;
                top: 0;
                margin-top: - get-vw(100px, map-get($site-widths-design, $breakpoint));
                position: relative;
                right: - get-vw(8px, map-get($site-widths-design, $breakpoint));;
                margin-left: 0;
                width: get-vw(284px, map-get($site-widths-design, $breakpoint));
                /*padding-top: get-vw(30px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(30px, map-get($site-widths-design, $breakpoint));*/
                img{
                    height: auto;
                    /*min-height: 100%;*/
                    width: 100%;
                }
            }
            .sppb-addon-media{
                position: static;
                width: 0;

            }

        }

    }

    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {

        .sppb-col-md-5{
            @include set-spacer(7, px);
            .sppb-addon-block-header{
                .header{
                    max-width: 60%;
                }
            }
            .sppb-addon-text{
                max-width: 80%;
            }
            .sp-pagebuilder-column,
            .sppb-column{

                padding: get-vw(30px, map-get($site-widths-design, $breakpoint));
            }
        }
        .sppb-col-md-5:nth-child(6),
        .sppb-col-md-5:nth-child(7){
            margin-bottom: 0;
        }

        .sppb-addon-media {
            // position: relative;
            // min-height: get-vw(60px, map-get($site-widths-design, $breakpoint));
            .image-cntr{

                .img {
                    width: 384px;
                }
            }
        }

    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        $column-padding: get-vw(60px, map-get($site-widths-design, $breakpoint));
        .sppb-col-md-5{
            @include set-spacer(0);
            .sppb-addon-block-header{
                .header{
                    max-width: 100%;
                }
            }
            .sppb-addon-text{
                max-width: 100%;
            }
            .sp-pagebuilder-column,
            .sppb-column{
                border-top-right-radius: get-vw(160px, map-get($site-widths-design, $breakpoint))!important;
                padding: $column-padding;
            }
        }
        .sppb-addon-media {
            position: relative;

            .image-cntr{
                position: relative;
                top: auto;
                right: -($column-padding);
                margin-top: get-vw(-120px, map-get($site-widths-design, $breakpoint));
                margin-bottom: 0;
               // width: get-vw(map-get($column-widths, $breakpoint)*4, map-get($site-widths-design, $breakpoint));
                height: 100%;
                .img{
                    position: static;
                    //width: get-vw(map-get($column-widths, $breakpoint)*4, map-get($site-widths-design, $breakpoint));
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}