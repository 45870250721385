$breakpoint: "mobile-s-up";
@include on-breakpoint($breakpoint) {
    .sppb-addon-process{
        .process-row{
            @include flex-box('normal', row, space-evenly, flex-start, wrap);

            .process-list{
                position: static;
                padding-left: 0;

            }
            .process-content{
                padding-right: 0;
            }

        }
        .content,
        .heading{
            text-align: left;
        }
        .sub-heading{
            font-size: 32px;
            font-weight: bold;
            line-height: 40px;
            color: map-get($colors, header-txt-on-light);
            margin-bottom: 40px;
        }
        .sub-title{
            font-size: 11px;
            line-height: 27px;
            font-weight: bold;
            text-transform: uppercase;
            color: map-get($colors, block-name-on-light);
            margin-bottom: 16px;

        }
        .heading.process{
            font-size: 24px;
            line-height: 32px;
            color: map-get($colors, header-txt-on-light);
        }
        .process-cntr{
            margin-bottom: 160px;
            .img-cntr{
                position: relative;
                height: auto;
                min-height: 100px;
                width: 100%;
                min-width: 100%;
                margin-bottom: 48px;
                padding: get-vw(72px, map-get($site-widths-design, $breakpoint)) 0;
                .bg-shape-cntr {
                    position: absolute;
                    right: 0;
                    top: 0;
                    @include flex-box('normal', flex, center, null, wrap);

                    width: 100%;
                    height: 100%;
                    min-height: 100%;
                    min-width: 100%;

                    .bg-shape{

                        width: 83%;
                        border-radius: 8px;
                        border-bottom-left-radius: 40px;
                        background: map-get($colors, bg-grey-light);
                    }
                }
                .icon-cntr{
                    position: absolute;
                    height: 140px;
                    right: 40px;
                    z-index: 22;
                    top: 20px;
                    width: 120px;
                    img,
                    svg{
                        height: auto;
                        width:100%;
                    }
                }
                .img{
                    position: relative;
                    max-width: 100%;


                    width: 83%;
                    height: auto;
                    top: 0;
                    left: 0;
                }
            }
            .content{
                font-size: 17px;
                line-height: 24px;
            }


        }

        .checkbox-cntr{
            @include flex-box('normal', row, flex-start, center, wrap);
            cursor: pointer;

            align-content: center;

            margin-bottom: 32px;
            .checkbox{
                transition: all 0.5s;
                height: 20px;
                width: 20px;
                border: get-vw(2px, map-get($site-widths-design, $breakpoint)) solid map-get($colors, formborder);
                border-radius: 2px;
                padding: get-vw(1px, map-get($site-widths-design, $breakpoint));
                margin-right: 8px;
                margin-bottom: 1px;
                .checkmark{
                    transition: all 0.5s;
                    height: 100%;
                    max-height: 100%;
                    width: 100%;
                    display: none;
                    svg{
                        vertical-align: top;
                    }
                }
            }
            &.checked{
                .checkbox{

                    border-color: map-get($colors, checkbox-mark);
                    background: map-get($colors, checkbox-mark);
                    .checkmark{
                        display: block;
                    }
                }
            }

            .label{
                text-align: left;
                font-size: 17px;
                line-height: 24px;
            }
        }

    }
}
$breakpoint: "mobile-s-up";

@include between-breakpoints($breakpoint, "desktop-s-up") {
    .sppb-addon-process{
        .process-row{
            display: block;

        }
        .process-list{
            padding: 0;
            width: 100%;
            max-width: 100%;
            .checkbox-cntr{
                display: none;
            }

        }
        .process-spacer{
            display: none;
        }
        .process-content{
            width: 100%;
            max-width: 100%;
            padding: 0;
        }


        .process-cntr{

            margin-bottom: 40px;
            display: block;

            &:nth-child(even){
                flex-direction: row;
                .content-cntr{
                    padding-left: 0;
                    padding-right: 0;
                }
                .img-cntr{
                    padding-left: 0;
                    padding-right: 20px;
                    .bg-shape-cntr {
                        .bg-shape{
                            right: 20px;
                            left: auto;
                            border-radius: 8px;
                            border-bottom-right-radius: 80px;
                        }
                    }
                    .icon-cntr{
                        right: auto;
                        left: 0;
                    }
                    .img{
                        right: -20px;
                        left: auto;
                        margin-left: auto;
                    }
                }
            }

            .content-cntr{
                width: 100%;
                padding-right: 0;
                min-width: 0;
            }
            .img-cntr{
                width: 100%;
                min-width: 0;
                padding-left: 0;
                min-height: 100px;
                margin-bottom: 48px;
                .bg-shape-cntr {
                    .bg-shape{
                        min-height: 100px;
                        height: 100%;
                        width: 83%;
                        left: 20px;
                        border-radius: 8px;
                        border-bottom-left-radius: 80px;
                    }
                }
                .icon-cntr{
                    height: get-vw(140px, map-get($site-widths-design, $breakpoint));
                    right: 0;
                    top: 10px;
                    width: get-vw(80px, map-get($site-widths-design, $breakpoint));
                }
                .img{
                    left: 0;
                }
            }

        }

        .checkbox-cntr{
            margin-bottom: 32px;
            .checkbox{
                height: 20px;
                width: 20px;
                margin-right: 8px;
                margin-bottom: 1px;
            }
            .label{
                font-size: 23px;
                line-height: 32px;
            }
        }
    }
}

$breakpoint: "tablet-s-up";

@include between-breakpoints($breakpoint, "desktop-s-up") {
    .sppb-addon-process{
        .process-cntr{
            @include flex-box('normal', row-reverse, center, flex-start, wrap);
            flex-direction: row-reverse;
            align-content: flex-start;
            margin-top:80px;
            margin-bottom: 40px;
            justify-items: center;
            &:nth-child(even){
                .img-cntr{
                    .bg-shape{
                        border-radius: 8px;
                        border-bottom-right-radius: 80px;
                    }
                }
                .content-cntr{
                    padding-right: 0px;
                    padding-left: 40px;

                }
            }
            .content-cntr{
                width: 50%;
                padding-right: 20px;
                min-width: 0;
                .content{
                    padding-right: 20px;
                }

            }

            .img-cntr{
                width: 50%;
                min-width: 0;
                padding-left: 20px;
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 0;

                min-height: get-vw(286px, map-get($site-widths-design, $breakpoint));
                margin-bottom: 48px;
                .bg-shape-cntr {
                    .bg-shape{
                        height: get-vw(286px, map-get($site-widths-design, $breakpoint));
                        width: 83%;
                        left: 20px;
                        border-radius: 8px;
                        border-bottom-left-radius: 80px;
                    }
                }
                .icon-cntr{
                    height: get-vw(140px, map-get($site-widths-design, $breakpoint));
                    right: 0;
                    top: 10px;
                    width: get-vw(80px, map-get($site-widths-design, $breakpoint));
                }
                .img{
                    position: absolute;
                    left: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 87%;
                    height: auto;
                }
            }
        }

        .content{
            font-size: 20px;
            line-height: 32px;
        }
        .checkbox-cntr{
            .checkbox{
                padding: get-vw(1px, map-get($site-widths-design, $breakpoint));
            }
            .label{
                font-size: 23px;
                line-height: 32px;
            }
        }
    }
}
$breakpoint: "tablet-s-up";
@include on-breakpoint($breakpoint) {



    .sppb-addon-process{
        .sub-heading{
            font-size: 54px;
            line-height: 60px;
            margin-bottom: 40px;
        }
        .sub-title{
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 16px;
        }
        .heading.process{
            font-size: 30px;
            line-height: 40px;
        }
        .img-cntr{
            .bg-shape-cntr {
                .bg-shape{
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 8px;
                    border-bottom-left-radius: 40px;
                    background: map-get($colors, bg-grey-light);
                }
            }
        }
    }
}

$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {


    .sppb-addon-process{

        .process-cntr{
            margin-bottom: get-vw(160px, map-get($site-widths-design, $breakpoint));
            &:last-child{
                margin-bottom: 0;
            }
        }
        .process-row{
            position: relative;
            .process-list{
                position: static;
                .process-list-cntr{
                    padding-top: get-vw(80px, map-get($site-widths-design, $breakpoint));
                    max-width: get-vw(340px, map-get($site-widths-design, $breakpoint));
                    &.fixed{

                        z-index: 20;
                        position: fixed;
                        top: get-vw(96px, map-get($site-widths-design, $breakpoint));
                        /*left: map-get($site-gutters, $breakpoint);*/

                    }
                    &.absolute{
                        /*max-width: 100%;
                        width: 100%;*/
                        position: absolute;
                        top: auto;
                        bottom: 20px;
                        left: 0;
                    }
                }
            }
            .process-content{
                padding-right: 0;
            }
        }

        .sub-heading{
            font-size: get-vw(54px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(60px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
        }
        .sub-title{
            font-size: get-vw(12px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(24px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(16px, map-get($site-widths-design, $breakpoint));
        }
        .heading.process{
            font-size: get-vw(30px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(40px, map-get($site-widths-design, $breakpoint));
        }
        .process-cntr{
            margin-bottom: get-vw(160px, map-get($site-widths-design, $breakpoint));
            .img-cntr{
                padding: 0;
                min-height: get-vw(520px, map-get($site-widths-design, $breakpoint));
                margin-bottom: get-vw(48px, map-get($site-widths-design, $breakpoint));

                .bg-shape-cntr {
                    .bg-shape{
                        height: get-vw(520px, map-get($site-widths-design, $breakpoint));
                        width: get-vw(460px, map-get($site-widths-design, $breakpoint));
                        border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
                        border-bottom-left-radius: get-vw(160px, map-get($site-widths-design, $breakpoint));
                    }
                }
                .icon-cntr{
                    height: get-vw(140px, map-get($site-widths-design, $breakpoint));
                    right: get-vw(40px, map-get($site-widths-design, $breakpoint));
                    top: get-vw(20px, map-get($site-widths-design, $breakpoint));
                    width: get-vw(120px, map-get($site-widths-design, $breakpoint));
                }
                .img{
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                    width: get-vw(446px, map-get($site-widths-design, $breakpoint));
                    height: auto;
                }
            }
            .content{
                font-size: get-vw(20px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
            }
        }

        .checkbox-cntr{
            margin-bottom: get-vw(32px, map-get($site-widths-design, $breakpoint));
            .checkbox{
                border: get-vw(2px, map-get($site-widths-design, $breakpoint)) solid map-get($colors, formborder);
                padding: get-vw(1px, map-get($site-widths-design, $breakpoint));
                height: get-vw(20px, map-get($site-widths-design, $breakpoint));
                width: get-vw(20px, map-get($site-widths-design, $breakpoint));
                margin-right: get-vw(8px, map-get($site-widths-design, $breakpoint));
                margin-bottom: get-vw(1px, map-get($site-widths-design, $breakpoint));
            }
            .label{
                font-size: get-vw(23px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
            }
        }
    }
}