.sppb-section {

    $breakpoint: "mobile-s-up";
    @include between-breakpoints($breakpoint, "tablet-s-up") {
        &.resp-columns- {
            &vacancies{
                .sppb-row {
                    > div{

                        margin-left: auto;
                        margin-right: auto;
                        width: #{(10/12)*100%};

                    }
                }
            }
            &img-shaped-with-icon {
                .sppb-row {
                    > div{
                        &:nth-child(2)
                        &:nth-child(4) {
                            width: 100%;
                        }
                        &:nth-child(3) {
                            display: none;
                        }
                    }
                }
            }
            &img-bgshape-and-icon {
                .sppb-row {
                    > div{
                        &:nth-child(2)
                        &:nth-child(4) {
                            width: 100%;
                        }
                        &:nth-child(3) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        &.resp-columns- {
            &img-shaped-with-icon {
                .sppb-row {

                }
            }
        }
    }
    $breakpoint: "mobile-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        &.resp-columns- {

            &cta-img-txt-btn{
                .sppb-row {
                    > div{
                        width: 100%;
                        max-width: 100%;
                        flex: none;
                    }

                    .sppb-addon-media {
                        margin: 0 auto 0 auto;

                    }
                    .sppb-button.right{
                        text-align: center;
                    }

                    .sppb-addon-text.block.center-vert {
                        height: auto;
                        .text{
                            height: auto;
                            display: block;
                            text-align: center;
                            margin-top: 18px;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }

    $breakpoint: "tablet-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        &.resp-columns- {
            &vacancies{
                .sppb-row {
                    > div{
                        &.sppb-col-md-6{
                            width: #{(5/12)*100%};
                            max-width: #{(5/12)*100%};
                        }
                        &.sppb-col-md-4{
                            width: #{(6/12)*100%};
                            max-width: #{(6/12)*100%};
                        }
                        &:nth-child(5) {
                            display: none;
                        }
                    }
                }
            }
            &usps-small {
                .sppb-row {
                    .sppb-col-md-8 {
                        width: 100%;
                        flex: none;
                        max-width: 100%;
                    }
                    .sppb-col-md-2{
                        display: none;
                    }
                }
            }
            &3-col-img{
                .sppb-row{

                    .sppb-col-md-1{
                        display: none;
                    }
                    .sppb-col-md-5{
                        width: #{(6/12)*100%};
                        max-width: #{(6/12)*100%};
                        flex: none;
                    }
                }
            }
            &cta-img-txt-btn{
                .sppb-row {
                    @include flex-box('normal', null, center, null, wrap);

                    > div{
                        width: #{(8/12)*100%};
                        max-width: #{(8/12)*100%};
                        flex: none;
                    }
                }
            }

            &img-shaped-with-icon {
                .sppb-row {
                    > div {
                        &:nth-child(2){
                            width: #{(6/12)*100%};
                        }
                        &:nth-child(3) {
                            width: #{(1/12)*100%};
                        }
                        &:nth-child(4){
                            width: #{(5/12)*100%};
                        }
                    }
                }
            }
            &img-bgshape-and-icon {
                .sppb-row {
                    > div {
                        &:nth-child(2){
                            width: #{(6/12)*100%};
                            max-width: #{(6/12)*100%};
                            flex: none;
                        }
                        &:nth-child(3) {
                            display: none;
                        }
                        &:nth-child(4){
                            width: #{(6/12)*100%};
                            max-width: #{(6/12)*100%};
                            flex: none;
                        }
                    }
                }
            }
            &product-presentation {
                .sppb-row {
                    > div {

                        &.sppb-col-md-1 {
                            display: none;
                        }
                        &.sppb-col-md-5{
                            width: 100%;
                            max-width: 100%;
                            flex: none;
                            padding: none;
                        }
                    }
                }
            }
            &product-presentation-rows {
                .sppb-row {
                    > div {

                        &.sppb-col-md-1 {
                            display: none;
                        }

                        &.sppb-col-md-4 {
                            width: 66%;
                            max-width: none;
                            flex: none;
                            margin: 0 auto 30px auto;
                            .text,
                            .sppb-text-center{
                                text-align: center;

                                p{
                                    text-align: center!important;
                                }
                            }

                        }
                        &.sppb-col-md-7{
                            width: 100%;
                            max-width: 100%;
                            flex: none;
                            padding: none;
                        }
                    }
                }
            }
        }
    }

    $breakpoint: "tablet-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        &.resp-columns- {
            &img-shaped-with-icon {

            }
        }
    }
}



$breakpoint: "desktop-s-up";

@include on-breakpoint($breakpoint) {

    &.resp-columns- {
        &cta-img-txt-btn{
            .sppb-row {

                .sppb-addon-media{
                    margin-bottom: 0;
                }
                .sppb-column-addons{
                    height: 100%;
                    .addon-cntr{
                        height: 100%;
                        @include flex-box('normal', null, null, center, wrap);
                        align-content: center;


                    }
                }
                .sppb-button,
                .image-cntr{
                    @include flex-box('normal', null, null, center, wrap);
                    align-content: center;

                }
            }
        }
    }
}
