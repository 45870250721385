

$breakpoint: "mobile-s-up";
@include on-breakpoint($breakpoint) {

    .repeatable-btn{
        @include flex-box(normal, row, center, center);
        flex-wrap: wrap;
        width: 100%;
        .sppb-button{
            margin-left: map-get($column-gutter, $breakpoint);
            margin-right: map-get($column-gutter, $breakpoint);
            margin-bottom: map-get($site-vert-grid, $breakpoint)*2;
           
        }
    }
}
$breakpoint: "tablet-s-up";
@include on-breakpoint($breakpoint) {

    .repeatable-btn{

        .sppb-button{
            margin-left: map-get($column-gutter, $breakpoint);
            margin-right: map-get($column-gutter, $breakpoint);
            margin-bottom: 0;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {

    .repeatable-btn{
        &.align-right {
            @include flex-justify-items(flex-end);
        }
        &.align-left {
            @include flex-justify-items(flex-start);
        }
        &.align-center {
            @include flex-justify-items(center);
        }
        .sppb-button{
            margin-left: map-get($column-gutter, $breakpoint);
            margin-right: map-get($column-gutter, $breakpoint);

        }
        
    }
}