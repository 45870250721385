.sppb-section.small{

    @include set-spacer(15);

    background: none!important;
    .sppb-container-inner{
        width: get-vw(1160px, 1440px);
        border-radius: 0;
        margin: 0 auto;
    }

    &.bg-type-orange{
        .sppb-container-inner{
            background: map-get($colors, bg-orange)!important;
        }
    }
}

.sppb-section.small{
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {

        .sppb-container-inner{

            width: get-vw(1160px, 1440px);
            border-top-right-radius: 75px;
            .sppb-row{
                padding: 0 30px;
            }
        }
    }
}
.sppb-section.small{
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-container-inner{
            width: get-vw(1160px, 1440px);
            border-top-right-radius: 175px;
            .sppb-row{
                padding: 0 60px;
            }
        }
    }
}

.sppb-section.small{
    $breakpoint: "tablet-s-up";
    @include between-breakpoints($breakpoint, "desktop-s-up") {
        .sppb-container-inner{
            width: get-vw(690px, map-get($site-widths-design, $breakpoint));
        }

    }
}

$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {
    #sp-page-builder .sp-pagebuilder-section-inner > .sppb-section.small > .sppb-container-inner > .sppb-row,
    #sp-page-builder .page-content > .sppb-section.small > .sppb-container-inner > .sppb-row {
        padding: 0 get-vw(60px, map-get($site-widths-design, $breakpoint));

    }
}
