.sppb-addon-media{
    @include set-spacer(3);
    text-align: center;
    .image{
        text-align: center;
    }
    .img{
        display: inline;
    }
    &.full-width{
        margin-bottom: 0;
    }
}