.sppb-addon-team-members{
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        .animation-cntr{
            display: none;
        }
        .loadmore-cntr{
            width: 100%;
            @include flex-box('normal', null, center, null, wrap);

            .btn{
                text-align: center;
                .txt{
                    text-align: center;
                }

            }
            .clicked{
                display: none;
            }
        }
        .notloaded{
            display: none;
        }
        .addon-cntr.filler{
            display: none;
        }
        .sppb-addon-members{

            $img-width: 100%;
            $img-height: auto;
            position: relative;
            width: $img-width;
            height: $img-height;
            border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            border-bottom-right-radius : get-vw(24px, map-get($site-widths-design, $breakpoint));
            overflow: hidden;
            margin-bottom: get-vw(32px, map-get($site-widths-design, $breakpoint));
            .img-cntr{
                height: $img-height;
                width: $img-width;

                position: relative;
                text-align: center;
                z-index: 9;
                .img{
                    height: $img-height;
                    width: $img-width;

                }
            }
            .content-cntr{
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 11;
                padding-left: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(16px, map-get($site-widths-design, $breakpoint));
                .txt{
                    color: map-get($colors, text-on-dark);
                    text-align: left;

                    &.title{
                        font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));
                    }
                    &.name{
                        font-size: get-vw(30px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(40px, map-get($site-widths-design, $breakpoint));
                    }

                }
            }

        }

    }

    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .notloaded{
            display: block;
        }
        .loadmore-cntr{
            display: none;
        }
        .sppb-team-content{
            @include flex-box('normal', null, space-between, null, wrap);

        }
        .addon-cntr.filler{
            display: block;
        }

        .sppb-addon-members{
            $img-height: auto;
            $img-width: get-vw(186px, map-get($site-widths-design, $breakpoint));
            position: relative;
            width: $img-width;
            height: $img-height;
            border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            border-bottom-right-radius : get-vw(24px, map-get($site-widths-design, $breakpoint));
            overflow: hidden;
            margin-bottom: get-vw(32px, map-get($site-widths-design, $breakpoint));
            .img-cntr{
                height: $img-height;
                width: $img-width;
                position: relative;
                text-align: center;
                z-index: 9;
                .img{
                    height: auto;
                    width: 100%;
                }
            }
            .content-cntr{
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 11;
                padding-left: get-vw(18px, map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(18px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(14px, map-get($site-widths-design, $breakpoint));
                .txt{
                    color: map-get($colors, text-on-dark);
                    text-align: left;
                    &.title{
                        font-size: get-vw(16px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(26px, map-get($site-widths-design, $breakpoint));
                    }
                    &.name{
                        font-size: get-vw(22px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
                    }
                }
            }

        }
        .addon-cntr:nth-child(4n+2){
            margin-top: get-vw(40px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-40px, map-get($site-widths-design, $breakpoint));
        }
        .addon-cntr:nth-child(4n+3){
            margin-top: get-vw(80px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-80px, map-get($site-widths-design, $breakpoint));
        }
        .addon-cntr:nth-child(4n+4){
            margin-top: get-vw(120px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-120px, map-get($site-widths-design, $breakpoint));
        }

    }

    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {

        .sppb-addon-members{
            $img-height: auto;
            $img-width: get-vw(260px, map-get($site-widths-design, $breakpoint));

            width: $img-width;
            height: $img-height;
            border-radius: get-vw(8px, map-get($site-widths-design, $breakpoint));
            border-bottom-right-radius : get-vw(24px, map-get($site-widths-design, $breakpoint));

            margin-bottom: get-vw(32px, map-get($site-widths-design, $breakpoint));
            .img-cntr{
                height: $img-height;
                width: $img-width;
                .img{
                    height: auto;
                    width: 100%;
                }
            }
            .content-cntr{

                padding-left: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(24px, map-get($site-widths-design, $breakpoint));
                padding-bottom: get-vw(16px, map-get($site-widths-design, $breakpoint));
                .txt{
                    &.title{
                        font-size: get-vw(17px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));
                    }
                    &.name{
                        font-size: get-vw(30px, map-get($site-widths-design, $breakpoint));
                        line-height: get-vw(40px, map-get($site-widths-design, $breakpoint));
                    }
                }
            }
        }
        .addon-cntr:nth-child(4n+2){
            margin-top: get-vw(40px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-40px, map-get($site-widths-design, $breakpoint));
        }
        .addon-cntr:nth-child(4n+3){
            margin-top: get-vw(80px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-80px, map-get($site-widths-design, $breakpoint));
        }
        .addon-cntr:nth-child(4n+4){
            margin-top: get-vw(120px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(-120px, map-get($site-widths-design, $breakpoint));
        }


    }
}
