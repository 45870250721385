$base-colors: (
    primary: #330072,
    primary-light: #5c338f,
    secondary: #FF6A13,
    secondary-dark: #f95c00,
    secondary-light: #ff8842,
    tertiary: #cccccc,
    quaternary: #FFFFFF,
    quaternary-dark: #c1aed2,
    quaternary-dark-alt: #c1aed2,
    quinary: #222d39,
    quinary-dark: #333333,
    senary: #f5f5f5,
    septenary: #333333,
    octonary: #666666,
    nonary: #f2f2f2,
    denary: #e3e4ff
);

$colors: (
    divider: map-get($base-colors, tertiary),
    placeholder: map-get($base-colors, tertiary),
    formborder: map-get($base-colors, tertiary),
    text-on-light: map-get($base-colors , quinary),
    text-link-on-light: map-get($base-colors, secondary),
    text-light-on-light: map-get($base-colors , octonary),
    text-big-on-light: map-get($base-colors , primary),
    text-on-dark: map-get($base-colors , quaternary),
    header-txt-on-light: map-get($base-colors, primary),
    header-dark-txt-on-light: map-get($base-colors, primary),
    header-txt-on-dark: map-get($base-colors, quaternary),
    top-bar-text-on-dark: map-get($base-colors, quaternary),
    top-bar-text-on-light: map-get($base-colors, primary),
    top-bar-bg-dark: map-get($base-colors, primary),
    block-bg: map-get($base-colors, primary),
    mobile-menu-bg: map-get($base-colors, primary),
    mobile-menu-btn-open: map-get($base-colors, secondary),
    menu-item-hover: map-get($base-colors, secondary),
    mobile-menu-btn-close: map-get($base-colors, quaternary),
    footer-tagline: map-get($base-colors, primary),
    footer-txt: map-get($base-colors, septenary),
    footer-header: map-get($base-colors, primary),
    footer-txt-light: map-get($base-colors, octonary),
    header-switcher-on-light: map-get($base-colors, octonary),
    footer-lang-switcher-dropdown-bg: map-get($base-colors, quaternary),
    footer-lang-switcher-border: map-get($base-colors, senary),
    bottom-txt-light: map-get($base-colors, octonary),
    bottom-divider: map-get($base-colors, senary),
    input-fields: map-get($base-colors, senary),
    block-name-on-light: map-get($base-colors, secondary),
    block-text-on-light: map-get($base-colors, quinary),
    block-text-on-light-dark: map-get($base-colors, quinary-dark),
    block-text-on-orange-dark: map-get($base-colors, quinary-dark-alt),
    block-light-text-on-light: map-get($base-colors, octonary),
    block-text-on-dark: map-get($base-colors, quaternary-dark),
    menu-text-on-dark: map-get($base-colors, quaternary),
    block-name-on-dark: map-get($base-colors, quaternary),
    block-header-on-light: map-get($base-colors, primary),
    block-header-on-dark: map-get($base-colors, quaternary),
    block-quote-on-light: map-get($base-colors, primary),
    light-bg: map-get($base-colors, quaternary),
    bg-overlay:  rgba(51, 0, 114, 0.84),
    el-on-light: rgb(58,58,58),
    icon-dark: map-get($base-colors, primary),
    icon-light: map-get($base-colors, quaternary),
    checkbox: map-get($base-colors, tertiary),
    checkbox-checked: map-get($base-colors, primary),
    checkbox-mark: map-get($base-colors, secondary-light),
    el-on-light-inactive: rgb(203,203,203),
    off-canvas-border: rgba(255,255,255,0.16),
    el-on-dark: rgb(255,255,255),
    el-on-dark-inactive: rgb(255,255,255),
    el-active: rgb(243,129,32),
    bg-dark: rgb(58,58,58),
    bg-dark-less: rgb(80,80,80),
    bg-grey-light: map-get($base-colors, senary),
    bg-grey-light-very: rgb(240,240,240),
    bg-orange: map-get($base-colors, secondary),
    btn-link-on-dark-bg: map-get($base-colors, quaternary),
    btn-link-on-dark-bg-hover: map-get($base-colors, tertiary),
    
    btn-darker-link-on-dark-bg: map-get($base-colors, nonary),

    menu-border: map-get($base-colors, nonary),
    btn-darker-link-on-dark-bg-hover: map-get($base-colors, tertiary),
    btn-darker-txt-link-on-dark-bg-hover: map-get($base-colors, primary-light),
    btn-link-on-light-bg: map-get($base-colors, primary),
    btn-link-on-light-bg-hover: map-get($base-colors, primary-light),
    btn-orange-link-on-light-bg: map-get($base-colors, secondary),
    btn-orange-link-on-light-bg-hover: map-get($base-colors, secondary-light),
    btn-link-on-dark-txt: map-get($base-colors, primary),
    btn-link-on-light-txt: map-get($base-colors, quaternary),
    btn-txt-color: map-get($base-colors, quaternary),
    link-btn-txt-color: map-get($base-colors, secondary),
    link-important-on-dark-bg: map-get($base-colors, secondary),
    
    video-icon-bg-orange: map-get($base-colors, secondary),
    video-icon-bg-orange-hover: map-get($base-colors, secondary-light),

    btn-cta-on-dark-bg: map-get($base-colors, quaternary),
    btn-cta-on-dark-bg-hover: map-get($base-colors, quaternary),
    btn-cta-on-light-bg: map-get($base-colors, secondary),
    btn-cta-on-light-bg-hover: map-get($base-colors, secondary-light),
    btn-cta-on-dark-txt: map-get($base-colors, primary),
    btn-cta-on-light-txt: map-get($base-colors, quaternary),

    siteborder: rgb(255,255,255),
    trans-overlay-dark: rgba(0, 0, 0, 0.25),
    trans-overlay-dark-active: rgba(0, 0, 0, 0.0),
    trans-overlay-dark-very: rgba(0, 0, 0, 0.75),
    trans-overlay-color: rgba(245,130,32, 0.87),

    cookie-overlay-bg: map-get($base-colors, quaternary),
    cookie-overlay-link: map-get($base-colors, secondary),
    notification-bar: map-get($base-colors, denary)
);
