.sppb-section.facts-text-only {
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        &.bg-type-burple {
            .sppb-addon-text {
                .text {
                    margin-bottom: 0;
                }
            }
        }
    }
}