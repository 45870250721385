.sppb-addon-google-maps {
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-addon-gmap-canvas {
            height: get-vw(260px, map-get($site-widths-design, $breakpoint));
        }
    }
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-addon-gmap-canvas {
            height: get-vw(260px, map-get($site-widths-design, $breakpoint));
        }
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
        .sppb-addon-gmap-canvas {
            height: get-vw(420px, map-get($site-widths-design, $breakpoint));
        }
    }
}