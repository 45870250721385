.sppb-addon-quote{
    text-align: left;
    &.case-preview{
        margin-bottom: 40px;
        .sppb-addon-quote-cntr{
            p{
                font-size: inherit;
                line-height: inherit;
            }

        }
    }
    .sppb-addon-title,
    .sppb-addon-quote-cntr{

        color: map-get($colors, block-quote-on-light);
        display: block;
        text-align: left;
        quotes: "“" "”" "‘" "’";
        &:before{
            content: open-quote;
        }
        &:after{
            content: close-quote;
        }
        p:first-child{
            display: inline;
        }
        p:last-child{
            display: inline;
        }

    }
    .author{
        color: map-get($colors, block-text-on-light);
        text-align: left;
        font-weight: bold;
    }
    .title{
        color: map-get($colors, block-text-on-light);
        text-align: left;
    }

    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        $extra-padding: 40px;
        .sppb-addon-quote-cntr{
            @include set-spacer(3, px);
            font-size: 21px;
            line-height: 28px;
        }
        .text,
        .author,
        .title {
            margin-right: $extra-padding;
        }
        .author{
            font-size: 17px;
            line-height: 24px;
        }
        .title{
            font-size: 17px;
            line-height: 24px;
        }
    }
    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {

        &.case-preview{
            .sppb-addon-quote-cntr{
                font-size: get-vw(21px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(24px, map-get($site-widths-design, $breakpoint));
            }
        }
        .sppb-addon-quote-cntr{
            @include set-spacer(3, px);
            font-size: get-vw(21px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(28px, map-get($site-widths-design, $breakpoint));
        }

        .text,
        .author,
        .title {
            margin-right: 0;
        }
        .author{
            font-size: 17px;
            line-height: 24px;
        }
        .title{
            font-size: 17px;
            line-height: 24px;
        }
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {

        &.case-preview{
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
            .sppb-addon-quote-cntr{
                font-size: get-vw(30px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(40px, map-get($site-widths-design, $breakpoint));
            }
        }
        .sppb-addon-quote-cntr{
            @include set-spacer(3);
            font-size: get-vw(30px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(40px, map-get($site-widths-design, $breakpoint));

        }
        .author{
            font-size: get-vw(20px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
        }
        .title{
            font-size: get-vw(20px, map-get($site-widths-design, $breakpoint));
            line-height: get-vw(32px, map-get($site-widths-design, $breakpoint));
        }
    }

}


.sppb-addon-block-header{
    .quote{
        display: block;
        text-align: left;
        quotes: "“" "”" "‘" "’";
        &:before{
            content: open-quote;
        }
        &:after{
            content: close-quote;
        }
        p:first-child{
            display: inline;
        }
        p:last-child{
            display: inline;
        }
    }
}