@mixin on-breakpoint($media, $type: null) {
    @if not map-has-key($site-breakpoints, $media) {
        @warn "#{$media} is not a known media type. Using desktop instead."; //$media: $desktop;
    }
    $adjust: 0;
    @if $type=="img" {
        $adjust: 1;
    }
    @media all and (min-width: map-get($site-breakpoints, $media) + $adjust) {
        @content;
    }
}

@mixin between-breakpoints($mediaMin, $mediaMax) {
    @media all and (min-width: map-get($site-breakpoints, $mediaMin)) and (max-width: map-get($site-breakpoints, $mediaMax)-1)  {
        @content;
    }
}

@mixin column-count($amount) {
    -webkit-column-count: $amount;
    column-count: $amount;
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > .content, .embedded-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

