.sppb-carousel-pro {
  &.full-width {
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
      left: -(map-get($site-gutters, $breakpoint));
      width: 100vw;
    }

    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
      left: -(map-get($site-gutters, $breakpoint));
      width: 100vw;
    }

    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
      left: -(map-get($site-gutters, $breakpoint));
      width: 100vw;
    }
  }
}