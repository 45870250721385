.sppb-section {
    .text{
        p{
            text-align: inherit;
            color: inherit;
        }
    }
    .sppb-addon-block-name {
        .text {
            color: map-get($colors, block-name-on-light);
        }
    }
    &.bg-type-image.overlay,
    &.bg-type-grey,
    &.bg-type-burple {
        .sppb-addon-block-name {
            .text {
                color: map-get($colors, block-name-on-light);
            }
        }
        .sppb-headertext{
            a,
            .link,
            .txt{
                color: map-get($colors, block-text-on-dark);
            }
        }
        .sppb-addon-text {
            .text {
                color: map-get($colors, block-text-on-dark);
            }
        }
        .sppb-addon-block-header {
            .header {
                color: map-get($colors, block-header-on-dark);
            }
        }
    }
    &.bg-type-orange {
        .sppb-addon-block-name {
            .text {
                color: map-get($colors, block-name-on-dark);
            }
        }
        .sppb-addon-block-header {
            .header {
                color: map-get($colors, block-header-on-dark);
            }
        }
        .sppb-addon-text {
            .text {
                color: map-get($colors, block-name-on-dark);
            }
        }
    }
    &.bg-type-grey,
    &.bg-type-white {
        .sppb-addon-block-name {
            .text {
                color: map-get($colors, block-name-on-light);
            }
        }
        .sppb-addon-block-header {
            .header {
                color: map-get($colors, block-header-on-light);
            }
        }
        .sppb-addon-text {
            .text {
                color: map-get($colors, text-on-light);
            }
        }
    }

    &.bg-type-image {
        .sppb-addon-block-name {
            .text {
                color: map-get($colors, block-name-on-dark);
            }
        }
        .sppb-addon-block-header {
            .header {
                color: map-get($colors, block-header-on-dark);
            }
        }
        .sppb-addon-text {
            .text {
                color: map-get($colors, block-text-on-dark);
            }
        }
    }
}