
//general settings for form builder
.sppb-addon-form-builder{

    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {

        .form-builder-label{
            font-size: 13px;
            color: map-get($colors, block-light-text-on-light);
        }
        .form-image-cntr{
            max-width: 50%;

            margin: 0 auto;
            &.right{
                display: none;
            }
            svg{
                width: 100%;
                height: auto;
                display: block;
            }
        }

        .sppb-form-group{
            margin-bottom: 16px;
            .sppb-form-control:-ms-input-placeholder{

                color: map-get($colors, placeholder)!important;
                font-size: 15px!important;
                line-height: 52px!important;
            }
            .sppb-form-control{
                border: none;
                color: map-get($colors, block-text-on-light-dark);
                font-size: 15px;
                border-radius: 4px;
                height: 52px;
                line-height: 52px;
                padding: 0 20px;
                background: map-get($colors, input-fields);
                //background: #666;
                &:focus{
                    background: map-get($colors, input-fields);
                    //background: #666;
                }

                &::placeholder{
                    color: map-get($colors, placeholder);
                    font-size: 15px;
                    line-height: 52px;


                }
                &.input-textarea{
                    height: auto;
                    line-height: 23px;
                }
                &.input-textarea{
                    height: auto;
                    padding-top: 23px;
                    line-height: 23px;
                    &::placeholder{
                        line-height: 23px;
                    }
                }

            }
            .form-builder-checkbox-item label::before, .form-builder-radio-item label::before, .sppb-addon-form-builder .sppb-form-check-label::before{
                border-color: map-get($colors, checkbox);
                box-shadow:inset 0px 0px 0px 2px #fff;
                height: 21px;
                width: 21px;
                top: 0;
                left: 0;
            }
            .form-builder-checkbox-item input:checked+label::before, .form-builder-radio-item input:checked+label::before, .sppb-addon-form-builder .sppb-form-check-input:checked+label::before{
                background: map-get($colors, checkbox-checked);
            }
            .form-builder-checkbox-item label::after, .sppb-addon-form-builder .sppb-form-check-label::after{
                /*color: map-get($colors, checkbox-mark);
                top: 0;
                left: 0;*/
                content: '';

            }
            .form-builder-checkbox-content .form-builder-label,
            .form-builder-radio-content .form-builder-label{
                height: 23px;
                line-height: 23px;
                display: block;
                margin-left: 30px;
            }

        }
        .sppb-form-builder-btn{
            @include flex-box(normal, null, center);
            @include flex-justify-items(center);
            &.sppb-text-right {
                @include flex-justify-items(center);
            }
            &.sppb-text-left{
                @include flex-justify-items(center);
            }
        }
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {

        .form-image-cntr{
            width: get-vw(398px,  map-get($site-widths-design, $breakpoint));
            height: get-vw(288px,  map-get($site-widths-design, $breakpoint));

           /* width: 398px;
            height: 288px;*/
            position: absolute;
            left: get-vw(-140px,  map-get($site-widths-design, $breakpoint));
            top: get-vw(24px,  map-get($site-widths-design, $breakpoint));
            z-index: 1;
            &.right{
                left: auto;
                display: block;
                right: get-vw(-140px,  map-get($site-widths-design, $breakpoint));
                top: get-vw(192px,  map-get($site-widths-design, $breakpoint));
            }
            .img,
            svg{

                width: 100%;
            }
        }

        &.image-cntr{
            z-index: 1;
            position: relative;
            background: none;
        }

        .form-builder-label{
            font-size: get-vw(13px,  map-get($site-widths-design, $breakpoint));
        }
        .sppb-form-group{
            .sppb-form-control{
                font-size: get-vw(15px,  map-get($site-widths-design, $breakpoint));
                border-radius: get-vw(4px,  map-get($site-widths-design, $breakpoint));
                height: get-vw(52px,  map-get($site-widths-design, $breakpoint));
                line-height: get-vw(52px,  map-get($site-widths-design, $breakpoint));
                padding-left: get-vw(20px,  map-get($site-widths-design, $breakpoint));
                padding-right: get-vw(20px,  map-get($site-widths-design, $breakpoint));
                &::placeholder{
                    font-size: get-vw(15px,  map-get($site-widths-design, $breakpoint));
                    line-height: get-vw(52px,  map-get($site-widths-design, $breakpoint));
                }
                &.input-textarea{
                    height: auto;
                    padding-top: get-vw(13px, map-get($site-widths-design, $breakpoint));

                    line-height: get-vw(23px, map-get($site-widths-design, $breakpoint));
                    &::placeholder{
                        line-height: get-vw(23px, map-get($site-widths-design, $breakpoint));
                    }
                }
            }

            .form-builder-checkbox-item label::before, .form-builder-radio-item label::before, .sppb-addon-form-builder .sppb-form-check-label::before{

                height: get-vw(21px, map-get($site-widths-design, $breakpoint));
                width: get-vw(21px, map-get($site-widths-design, $breakpoint));
            }
            .form-builder-checkbox-content .form-builder-label,
            .form-builder-radio-content .form-builder-label{
                height: get-vw(23px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(23px, map-get($site-widths-design, $breakpoint));
                display: block;
                margin-left: get-vw(30px, map-get($site-widths-design, $breakpoint));
            }
        }
        .sppb-form-builder-btn{
            @include flex-box(normal, null, center);
            @include flex-justify-items(center);
            margin-left: map-get($column-gutter, $breakpoint );
            &.sppb-text-right {
                @include flex-justify-items(flex-end);
            }
            &.sppb-text-left{
                @include flex-justify-items(flex-start);
            }
        }
    }


}

//specific for contact form
$breakpoint: "mobile-s-up";
@include between-breakpoints($breakpoint, "tablet-s-up") {

    #sp-main-body .sppb-addon-content .sppb-addon-form-builder-form  .sppb-form-group{
        padding: 0;
    }
}
.sppb-addon-contact-form-builder{
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        background: map-get($colors, light-bg);
        position: relative;
        z-index: 10;
        border-radius: 4px;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08);
        padding: get-vw(25.5px, map-get($site-widths-design, $breakpoint));

        .sppb-addon-form-builder-form > div{
            max-width: 100%;
        }


        .grecaptcha-badge{
            margin-bottom: 16px;
            max-width: 100%;
            overflow: hidden;
            box-shadow: none!important;
            /*margin-left: map-get($column-gutter, $breakpoint );*/
        }
        .form-builder-radio-item{
            display: block;
            margin-bottom: 8px;
        }
        .heading{
            font-size: 28px;
            color: map-get($colors, header-txt-on-light);
            text-align:center;
            margin-bottom: 40px;
        }
    }
    $breakpoint: "mobile-s-up";
    @include on-breakpoint($breakpoint) {
        background: map-get($colors, light-bg);
        position: relative;
        z-index: 10;
        border-radius: 4px;
        box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08);
        padding: get-vw(25.5px, map-get($site-widths-design, $breakpoint));

        .sppb-addon-form-builder-form > div{
            max-width: 100%;
        }

        .grecaptcha-badge{
            margin-bottom: 16px;
            max-width: 100%;
            overflow: hidden;
            box-shadow: none!important;
            /*margin-left: map-get($column-gutter, $breakpoint );*/
        }
        .form-builder-radio-item{
            display: block;
            margin-bottom: 8px;
        }
        .heading{
            font-size: 28px;
            color: map-get($colors, header-txt-on-light);
            text-align:center;
            margin-bottom: 40px;
        }
    }
    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {

        .grecaptcha-badge{
            margin-bottom: 16px;
            margin-left: map-get($column-gutter, $breakpoint );
        }

        .heading{
            font-size: get-vw(42px, map-get($site-widths-design, $breakpoint));
            margin-bottom: get-vw(40px, map-get($site-widths-design, $breakpoint));
        }
        border-radius: get-vw(4px, map-get($site-widths-design, $breakpoint));
        padding: get-vw(80px, map-get($site-widths-design, $breakpoint));

        /*padding-left: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));
        padding-right: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));
        padding-top: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));
        padding-bottom: get-vw(map-get($column-widths, $breakpoint), map-get($site-widths-design, $breakpoint));*/

        .sppb-form-builder-btn{
            margin-top: get-vw(16px, map-get($site-widths-design, $breakpoint));
        }
        .grecaptcha-badge{
            margin-bottom: 16px;
            margin-left: map-get($column-gutter, $breakpoint );
        }
    }
}
@include between-breakpoints($breakpoint, "desktop-s-up") {
    .sppb-addon-contact-form-builder{

        .form-builder-radio-item{
            @include set-spacer(2, px);
        }
    }

}
@include on-breakpoint($breakpoint) {
    .sppb-section.bg-type-burple.half-white{
        background: linear-gradient(to top, map-get($base-colors, quaternary) 80px, map-get($base-colors, primary) 1px) !important; //fix for 100px

    }
}
$breakpoint: "desktop-s-up";
@include on-breakpoint($breakpoint) {
    .sppb-section.bg-type-burple.half-white{
        background: linear-gradient(to top, map-get($base-colors, quaternary) 224px, map-get($base-colors, primary) 1px) !important; //fix for 100px

    }
}

// PM - Specific when form get's used in a smaller column
.sppb-col-md-1,
.sppb-col-md-2,
.sppb-col-md-3,
.sppb-col-md-4,
.sppb-col-md-5,
.sppb-col-md-6 {
    .sppb-addon-contact-form-builder{
        $breakpoint: "mobile-s-up";
        @include on-breakpoint($breakpoint) {
            .heading {
                font-size: 28px;
            }
        }

        $breakpoint: "tablet-s-up";
        @include on-breakpoint($breakpoint) {
        }

        $breakpoint: "desktop-s-up";
        @include on-breakpoint($breakpoint) {
            padding: get-vw(40px, map-get($site-widths-design, $breakpoint));
            .sppb-form-builder-btn{
                padding: 0 get-vw(20px, map-get($site-widths-design, $breakpoint));
            }
            .heading {
                font-size: get-vw(30px, map-get($site-widths-design, $breakpoint));
                line-height: get-vw(40px, map-get($site-widths-design, $breakpoint));
            }
        }
    }
}

/* fix for captcha */
//specific for contact form
$breakpoint: "mobile-s-up";
@include between-breakpoints($breakpoint, "tablet-s-up") {
    #sp-main-body .sppb-section{
        .sppb-form-builder-recaptcha {
            padding: 0;
            margin: 0;
        }
    }
}
#sp-main-body .sppb-section{
    .sppb-form-builder-recaptcha {

        @media(max-width: 410px) {
            padding: 0;
            transform: scale(0.8);
            transform-origin: 0 0;

        }
    }
}
#sp-main-body .sppb-section{
    .sppb-form-builder-recaptcha {

        @media(max-width: 330px) {
                padding: 0;
                transform: scale(0.76);
                transform-origin: 0 0;

        }
    }
}