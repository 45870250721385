
// TEMP
#sp-menu {
    width: 100%;
    height: auto;

    .cls-1{
        opacity: 1;
    }

    .sp-column {
        width: 100%;
    }
    .sp-megamenu-wrapper {
        @include flex-box('normal', null, start, center);
    }
    .sp-module {
        display: inline-block;

        .menu {
            vertical-align: top;
            margin: 0;
            li {
                height: 14px;
                padding: 0;
                margin: 0;
                vertical-align: text-top;
                line-height: 1;
                &.active {
                    a {
                        text-decoration: underline;
                    }
                }
            }
            li a {
                font-size: 12px;
                color: #222222;
                line-height: 1;
            }

        }
    }
    .sp-dropdown {
        background-color: white;
    }
}