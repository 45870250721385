.sppb-addon-image-shape-with-icon {
    position: relative;
    text-align: right;
    $breakpoint: "mobile-s-up";

    @include on-breakpoint($breakpoint) {
        .image-cntr {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            overflow: hidden;
            width: map-get($column-widths, $breakpoint)*10; // #{(10/12)*100%};
            margin-left: auto;
            margin-right: 0;
            margin-top: map-get($site-vert-grid, $breakpoint)*5;
        }

        .icon {
            position: absolute;
            top: -3.8vw;
            // $size: 56px;
            left: 0; //calc(#{(1/12)*100%} - #{get-vw($size, map-get($site-widths-design, $breakpoint))/2});
            width: map-get($column-widths, $breakpoint)*3; //#{(4/12)*100%}; //get-vw($size, map-get($site-widths-design, $breakpoint));
            height: map-get($column-widths, $breakpoint)*3;// #{(4/12)*100%}; // get-vw($size, map-get($site-widths-design, $breakpoint));
            .img,
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .img-thumb-cntr {
            position: absolute;
            left: 0;
            bottom: (map-get($site-vert-grid, $breakpoint) * 6);
            width: get-vw(180px, map-get($site-widths-design, $breakpoint));
            border-radius: map-get($site-vert-grid, $breakpoint);
            overflow: hidden;
            //height: get-vw(108px, map-get($site-widths-design, $breakpoint));

            .img-thumb {
                width: 100%;
            }
            

            .st0 {
                fill: map-get($colors, video-icon-bg-orange);
            }
            &:hover {
                .st0 {
                    fill: map-get($colors, video-icon-bg-orange-hover);
                }
            }
        }
        &.bottom-left {
            .image-cntr {
                border-bottom-left-radius: get-vw(map-get($site-vert-grid, $breakpoint)*8, map-get($site-widths-design, $breakpoint));
                border-top-left-radius: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint));
            }
        }
    }

    $breakpoint: "tablet-s-up";
    @include on-breakpoint($breakpoint) {
        .image-cntr { 
            width: 100%;
        }
        .icon {
            //top: calc(50% - #{get-vw(42px, map-get($site-widths-design, $breakpoint))/2});
            top: -(map-get($site-vert-grid, $breakpoint)*3);
            left: -#{38%*0.5};
            width: 38%; // get-vw(50px, map-get($site-widths-design, $breakpoint));
            height: 38%; //get-vw(50px, map-get($site-widths-design, $breakpoint));
           
        }

        &.bottom-left {
            .img-thumb-cntr {
                left: 0px;
            }
            .image-cntr {
                border-bottom-left-radius: map-get($site-vert-grid, $breakpoint)*8;
                border-top-left-radius: map-get($site-vert-grid, $breakpoint);
            }
        }
    }

    $breakpoint: "desktop-s-up";
    @include on-breakpoint($breakpoint) {
    
        .icon{
            //top: calc(50% - #{get-vw(42px, map-get($site-widths-design, $breakpoint))/2});
            // top: -(get-vw(map-get($site-vert-grid, $breakpoint)*3));
            top: -(get-vw(map-get($site-vert-grid, $breakpoint)*3, map-get($site-widths-design, $breakpoint)));
            $size: 176px;
            left: -(get-vw($size, map-get($site-widths-design, $breakpoint))/2);
            width: get-vw($size, map-get($site-widths-design, $breakpoint));
            height: get-vw($size, map-get($site-widths-design, $breakpoint));
        }
        &.bottom-left {
            .image-cntr {
                border-bottom-left-radius: get-vw(map-get($site-vert-grid, $breakpoint)*8, map-get($site-widths-design, $breakpoint));
                border-top-left-radius: get-vw(map-get($site-vert-grid, $breakpoint), map-get($site-widths-design, $breakpoint));
            }
        }
    }
}