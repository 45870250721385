.sppb-section {
    &.bg-type-burple {
        .sppb-addon-big-text {
            .header {
                color: map-get($colors, header-txt-on-dark);
            }
        }
    }
    &.bg-type-orange {
        .sppb-addon-big-text {
            .header {
                color: map-get($colors, header-txt-on-dark);
            }
        }
    }

    &.bg-type-white {
        .sppb-addon-big-text {
            .header {
                color: map-get($colors, header-txt-on-light);
            }
        }
    }

    &.bg-type-image {
        .sppb-addon-big-text {
            .header {
                color: map-get($colors, header-txt-on-dark);
            }
        }
    }
}