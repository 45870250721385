@mixin set-spacer($columns, $unit: vw , $margin-pos: bottom, $grid-row-height: 8px ) {
   @if $unit == vw {
      margin-#{$margin-pos}: get-vw(($columns * $grid-row-height), 1440px);
   } @else if $unit == px {
      margin-#{$margin-pos}: $columns * $grid-row-height;
   }
}

@mixin set-spacer-block($columns, $unit: vw , $grid-row-height: 8px ) {
   @if $unit == vw {
      height: get-vw(($columns * $grid-row-height), 1440px);
   } @else if $unit == px {
      height: $columns * $grid-row-height;
   }
}